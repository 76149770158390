var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column flex-md-row align-center py-0"},[_c('div',{staticClass:"headline font-weight-bold font--text ma-4"},[_vm._v(" History Billing ")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1","margin-right":"20px"},attrs:{"height":"45","depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v(" $vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('v-btn',{staticClass:"secondary fz-14 ma-4",attrs:{"width":"150","height":"45","depressed":""}},[_vm._v(" Download ")])],1)],1),_c('div',{staticStyle:{"height":"30px","width":"auto"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.billings,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.date).format("DD/MM/YYYY"))+" ")])]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.billed)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('label',{staticClass:"primary--text linkpointer",on:{"click":function($event){return _vm.openDetailPopup(item.id, item.date, item.billed)}}},[_vm._v(" Detail ")])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}])}),_c('Detailpopup',{attrs:{"projectid":_vm.projectid,"date":_vm.date,"billed":_vm.billed,"instance-details":_vm.instanceDetails,"storage-details":_vm.storageDetails},model:{value:(_vm.displayDetailPopup),callback:function ($$v) {_vm.displayDetailPopup=$$v},expression:"displayDetailPopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }