import { render, staticRenderFns } from "./cdn.vue?vue&type=template&id=42dd98b9&scoped=true"
import script from "./cdn.vue?vue&type=script&lang=js"
export * from "./cdn.vue?vue&type=script&lang=js"
import style0 from "./cdn.vue?vue&type=style&index=0&id=42dd98b9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42dd98b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCard,VCardText,VChip,VCol,VContainer,VDataTable,VIcon,VList,VListItem,VListItemTitle,VPagination,VProgressLinear,VRow,VSelect})
