var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Super Admin")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"maxlength":"50","hide-details":"","outlined":"","placeholder":"Search By Email"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewUser","depressed":"","height":"50","width":"150"},on:{"click":function () {
              _vm.opendialogadduser = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Admin")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","show-select":"","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":10,"search":_vm.search,"items":_vm.members,"server-items-length":_vm.pagination.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.users.fullname)+" ")]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.phone_number",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone_number)+" ")]}},{key:"item.statususer",fn:function(ref){
            var item = ref.item;
return [(item.verified_at!='')?_c('div',[_vm._v("Verified")]):_c('div',[_vm._v("Unverified")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"true-value":"active","false-value":"deactive","flat":"","readonly":"","input-value":item.status},on:{"click":function (){
            _vm.memberselectedvalue = item
            _vm.opendialogactivateuser = true
          }}})]}},{key:"item.joindate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                  on.click($event);
                  _vm.show($event, item);
                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                  rotate: item.open,
                },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":item.status=='active'},on:{"click":function () {
                  _vm.memberselected = item;
                  _vm.opendialogresendinviteuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Resend Invitation")])],1),_c('v-divider',{staticStyle:{"min-width":"160px"}}),_c('v-list-item',{on:{"click":function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogedituser = true;
                }}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogdetailuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                  _vm.memberselected = item;
                  _vm.opendialogdeleteuser = true;
                }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}]),model:{value:(_vm.membersselected),callback:function ($$v) {_vm.membersselected=$$v},expression:"membersselected"}})],1),_c('dialogDeleteUser',{on:{"confirm":_vm.deletememberselected},model:{value:(_vm.opendialogdeleteuser),callback:function ($$v) {_vm.opendialogdeleteuser=$$v},expression:"opendialogdeleteuser"}}),_c('dialogRecendInviteUser',{on:{"confirm":_vm.resendinvitememberselected},model:{value:(_vm.opendialogresendinviteuser),callback:function ($$v) {_vm.opendialogresendinviteuser=$$v},expression:"opendialogresendinviteuser"}}),(_vm.opendialogadduser)?_c('dialogAddUser',{on:{"added":function () {
        _vm.refreshmembers();
      }},model:{value:(_vm.opendialogadduser),callback:function ($$v) {_vm.opendialogadduser=$$v},expression:"opendialogadduser"}}):_vm._e(),(_vm.memberselectedvalue && _vm.opendialogedituser)?_c('dialogEditUser',{attrs:{"memberselectedvalue":_vm.memberselectedvalue},on:{"updated":function () {
        _vm.refreshmembers();
      }},model:{value:(_vm.opendialogedituser),callback:function ($$v) {_vm.opendialogedituser=$$v},expression:"opendialogedituser"}}):_vm._e(),(_vm.opendialogdetailuser && _vm.memberselectedvalue)?_c('dialogDetailUser',{attrs:{"user":_vm.memberselectedvalue},model:{value:(_vm.opendialogdetailuser),callback:function ($$v) {_vm.opendialogdetailuser=$$v},expression:"opendialogdetailuser"}}):_vm._e(),_c('dialogActivateUser',{attrs:{"userorganization":"user","onoff":_vm.memberselectedvalue.status == 'active'? 'off' : 'on'},on:{"confirm":function (){
     _vm.changestatus(_vm.memberselectedvalue).then(function (){
       _vm.opendialogactivateuser = false
       _vm.memberselectedvalue.status = ( _vm.memberselectedvalue.status == 'active') ? 'deactive' : 'active'
     })
   }},model:{value:(_vm.opendialogactivateuser),callback:function ($$v) {_vm.opendialogactivateuser=$$v},expression:"opendialogactivateuser"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }