var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-6"},[_c('v-col',[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.disable},on:{"click":function () {
            _vm.action = 'create'
            _vm.openDialogAdd=true
          }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("NEW LABEL")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listLabels,"item-key":"no","hide-default-footer":"","items-per-page":10,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.totalLabels.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.creation_time",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.creation_time)))])]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"white--text bs-tr",attrs:{"color":item.color}},[_c('v-icon',[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(item.name))],1)]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(" More "),_c('v-menu',{attrs:{"right":"","down":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu-down")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                        _vm.packageselected=item
                        _vm.action='update'
                        _vm.openDialogAdd=true

                      }}},[_vm._v(" Update "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"blue-grey","dark":""}},[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",attrs:{"disabled":_vm.disable},on:{"click":function () {
                        _vm.onDelete(item);
                        _vm.action='delete';
                      }}},[_vm._v(" Delete "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red","dark":""}},[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
                _vm.changePage(val);
              }}})]}}],null,true)})],1)],1),(_vm.openDialogAdd)?_c('dialogLabels',{attrs:{"item":_vm.packageselected,"type":_vm.action},on:{"clearItem":function($event){_vm.packageselected = $event}},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e(),_c('dialogConfirmDelete',{attrs:{"close":_vm.Close,"project-id":_vm.projectId,"action":_vm.action,"data-delete":_vm.dataDelete},on:{"update:close":function($event){_vm.Close=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }