var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('Header',{on:{"add":function($event){_vm.addOn = true},"onSearch":function($event){return _vm.getSales($event, 1, 10, [], [])}}}),_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":"","items":_vm.tblItems,"items-per-page":10,"server-items-length":_vm.pagination.count,"headers":_vm.tblHeaders,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization.name || "-")+" ")]}},{key:"item.is_verified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_verified === null ? "Waiting Verification" : item.is_verified ? "Verified" : "Not Verified")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","flat":"","disabled":_vm.read_only,"readonly":"","true-value":"active","false-value":"deactive"},on:{"click":function () {
            if (_vm.read_only){
              return
            }
            _vm.selectedSale = item;
            _vm.dialogSwitchOn = true;
          }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('DropdownBtn',{attrs:{"id":item.id},on:{"detail":function () {
            _vm.selectedSale = item;
            _vm.detailOn = true;
          },"editPopupOn":function () {
            if (_vm.read_only){
              return
            }
            _vm.selectedSale = item;
            _vm.editOn = true;
          },"delete":function () {
            if (_vm.read_only){
              return
            }
            _vm.selectedSale = item;
            _vm.deleteOn = true;
          }}})]}},{key:"footer",fn:function(ref){
          var props = ref.props;
return [(
          (_vm.pagination && _vm.pagination.count > 10) ||
            (_vm.pagination && _vm.pagination.count === 10)
        )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.pagination = Object.assign({}, _vm.pagination, val);
          }}}):_vm._e()]}}])}),(_vm.dialogSwitchOn && _vm.selectedSale)?_c('dialogActivateFlavor',{attrs:{"onoff":_vm.selectedSale.status === 'active' ? 'off' : 'on',"loading":_vm.loading},on:{"confirm":_vm.changeStatus},model:{value:(_vm.selectedSale),callback:function ($$v) {_vm.selectedSale=$$v},expression:"selectedSale"}}):_vm._e(),(_vm.addOn)?_c('Add',{on:{"update":_vm.updateData},model:{value:(_vm.addOn),callback:function ($$v) {_vm.addOn=$$v},expression:"addOn"}}):_vm._e(),(_vm.detailOn && _vm.selectedSale)?_c('Detail',{attrs:{"item":_vm.selectedSale},on:{"update":_vm.updateData,"changeStatus":function($event){if (_vm.read_only){
        _vm.dialogSwitchOn = false
        return
      }
      _vm.dialogSwitchOn = true}},model:{value:(_vm.detailOn),callback:function ($$v) {_vm.detailOn=$$v},expression:"detailOn"}}):_vm._e(),(_vm.editOn && _vm.selectedSale)?_c('Edit',{attrs:{"item":_vm.selectedSale},on:{"update":_vm.updateData},model:{value:(_vm.editOn),callback:function ($$v) {_vm.editOn=$$v},expression:"editOn"}}):_vm._e(),(_vm.deleteOn && _vm.selectedSale)?_c('Delete',{attrs:{"item":_vm.selectedSale},on:{"confirm":_vm.deleteItem},model:{value:(_vm.deleteOn),callback:function ($$v) {_vm.deleteOn=$$v},expression:"deleteOn"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }