var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Type Operation System")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewUser","depressed":"","height":"50","width":"150"},on:{"click":function () {
              // opendialogadduser = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Type")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","show-select":"","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":5,"search":_vm.search,"items":_vm.members},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                     // itemdefaultedited = item;
                  }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])])])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              // options = { ...options, ...val };
            }}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }