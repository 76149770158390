var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[(_vm.instances && _vm.instances.length)?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex mb-3",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v("Instances")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"secondary","dark":"","depressed":"","height":"44","width":"103"},on:{"click":function () {
              _vm.validateprivilages([
                'Network',
                'editor' ]).then(function () {
                _vm.$emit('addinstance')
              });
            }}},[_c('span',{staticClass:"fz-14 fw-600"},[_vm._v("Add")])])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"DataTableListInstance","hide-default-footer":true,"headers":_vm.headers,"items":_vm.instances,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex instance-name"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-circle")]),_c('div',[_c('router-link',{attrs:{"to":{ path: ("/project/lintasarta/" + (item.id) + "/") }}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]),_c('p',[_vm._v(" "+_vm._s(item.package_instance ? item.package_instance.vcpu : 'N/A')+" vCPU / "+_vm._s(item.package_instance ? item.package_instance.ram : 'N/A')+" GB RAM / "+_vm._s(item.root_disk_package ? item.root_disk_package.volume_size : item.package_instance ? item.package_instance.root_disk : '-')+" GB Root Disk ")])],1)],1)]}},{key:"item.ip",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"ipaddress"},[_vm._v("Internal"),_c('span',{staticClass:"tik"},[_vm._v(" : ")])]),_c('span',[_vm._v(_vm._s(item.ip_address_internal))]),_c('br'),_c('span',{staticClass:"ipaddress"},[_vm._v("Gateway"),_c('span',{staticClass:"tik"},[_vm._v(" : ")])]),_c('span',[_vm._v(_vm._s(item.vpc.ip_gateway))]),_c('br'),(item.floating_ip)?_c('span',{staticClass:"ipaddress"},[_vm._v("External "),_c('span',{staticClass:"tik"},[_vm._v(" : ")])]):_vm._e(),_vm._v(_vm._s(item.floating_ip ? item.floating_ip.ip_address : '')+" ")]}},{key:"item.createddate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}},{key:"item.region",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.region.toUpperCase())+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"linkpointer error--text",on:{"click":function () {
                _vm.validateprivilages([
                  'Network',
                  'editor' ]).then(function () {
                  _vm.$emit('removeInstance', item.id)
                });
              }}},[_vm._v(" Remove ")])]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}],null,false,3226567325)})],1)],1):_c('v-card',{staticClass:"rounded-lg pa-8",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"px-8 text-center"},[_c('p',{staticClass:"fz-16 font-weight-bold mb-0"},[_vm._v(" Add instance to Load Balancer ")])]),_c('v-card-text',{staticClass:"py-0 px-8 text-center",staticStyle:{"max-width":"687px","margin":"auto"}},[_c('p',{staticClass:"accent--text"},[_vm._v(" The Load Balancer will distribute traffic between specific Instance. Instance can be selected by name or by tag and must be located in the same datacenter as the Load Balancer. ")])]),_c('v-card-text',{staticClass:"text-center"},[_c('v-btn',{staticClass:"secondary",attrs:{"height":"55","width":"465","depressed":""},on:{"click":function () {
                _vm.validateprivilages([
                  'Network',
                  'editor' ]).then(function () {
                  _vm.$emit('addinstance');
                });
              }}},[_c('span',{staticClass:"fz-14 font-weight-bold"},[_vm._v("Choose Instance")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }