var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Network Pool")])])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"py-0 d-flex",attrs:{"md":"6"}},[_c('p',{staticClass:"mt-4 mr-8 font-weight-bold"},[_vm._v("Specify Network Pool")]),_c('v-switch',{staticClass:"pt-0 mb-1",attrs:{"inset":"","hide-details":""},model:{value:(_vm.value.checkSpecify),callback:function ($$v) {_vm.$set(_vm.value, "checkSpecify", $$v)},expression:"value.checkSpecify"}})],1)],1)],1),(_vm.value.checkSpecify)?_c('div',{staticClass:"d-flex"},[_c('v-row',{},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listNetworkPool,"items-per-page":10,"server-items-length":_vm.TotalRowslistNetworkPool,"loading":_vm.isLoading,"hide-default-footer":"","item-key":"name"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"disabled":!_vm.value.checkSpecify,"hide-details":""},model:{value:(_vm.radioTable),callback:function ($$v) {_vm.radioTable=$$v},expression:"radioTable"}},[(_vm.value.checkSpecify)?_c('v-radio',{key:_vm.listNetworkPool.indexOf(item),attrs:{"value":item.name},on:{"click":function () { 
                  _vm.value.poolSelected = {name: item.name, href: ("https://iaas.lintasarta.net/api/admin/extension/networkPool/" + (_vm.splitId(item.id))) 
                  }
                }}}):_c('v-radio')],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 'REALIZED' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status == 'REALIZED' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")])]}},{key:"item.ip_pool",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(((item.usedBackingsCount/item.totalBackingsCount)*100).toFixed(2))+"% "+_vm._s(("(Total:" + (item.totalBackingsCount) + ")"))+" ")]}},{key:"item.network",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.managingOwnerRef.name)+" ")]}}],null,true)}),(_vm.$v.rule.poolSelected.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("*Please choose the network pool")]):_vm._e()],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{},[_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Maximum Provisioned Network")]),_c('v-text-field',{staticClass:"mt-0",attrs:{"type":"number","outlined":"","dense":"","single-line":"","error-messages":_vm.$v.rule.max_prov.$errors.length ? _vm.$v.rule.max_prov.$errors[0].$message : ''},model:{value:(_vm.value.max_prov),callback:function ($$v) {_vm.$set(_vm.value, "max_prov", $$v)},expression:"value.max_prov"}})],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 7)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }