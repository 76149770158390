var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Instance ")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.headers1,"items":_vm.instances,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name.text))])]),_c('p',[_vm._v(_vm._s(item.name.spec))])],1)])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.size.vol))]),_c('p',[_vm._v(_vm._s(item.size.item))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('label',{staticClass:"text-primary",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.show($event, item)}}},[_vm._v("More "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},(_vm.instances.length > 5)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Enable backups ")]),_c('br'),_c('p',[_vm._v(" A backup is an automatic snapshot of your droplet. Backups are made once a week and retained for four weeks. Once enabled, backups add 20% to monthly Instance cost. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","placeholder":"Choose a Instance"}})],1)])],1)],1),_c('v-col',{attrs:{"md":"2 align-self-end"}},[_c('v-btn',{attrs:{"depressed":"","width":"150","height":"50","color":"secondary"}},[_vm._v("Enable Backups")])],1)],1),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }