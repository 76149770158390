var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instance Name")]),_c('v-text-field',{attrs:{"id":"InstanceName","error-messages":_vm.$v.instanceName.$errors.length ? _vm.$v.instanceName.$errors[0].$message : '',"return-object":"","placeholder":"Instance Name","outlined":""},model:{value:(_vm.instanceName),callback:function ($$v) {_vm.instanceName=$$v},expression:"instanceName"}})],1)],1)],1),_c('v-card-text',{staticClass:"pb-0 pt-0 ml-3"},[_c('v-row',[_c('v-radio-group',{attrs:{"row":"","error-messages":_vm.$v.selectTypeInstance.$errors.length ? _vm.$v.selectTypeInstance.$errors[0].$message : ''},on:{"blur":function($event){return _vm.$v.selectTypeInstance.$touch()}},model:{value:(_vm.selectTypeInstance),callback:function ($$v) {_vm.selectTypeInstance=$$v},expression:"selectTypeInstance"}},[_c('v-radio',{staticClass:"font-weight-bold d-flex align-center flex-row px-4 rounded-lg cursor-pointer",style:({
            border: ("2px solid " + (_vm.selectTypeInstance == 'operatingsystem' ? '#2C94D2' : '#BDBDBD')),
            border: ("2px solid " + (_vm.$v.selectTypeInstance.$errors.length ? '#eb5757' : '#BDBDBD')),
            width: '233px',
            height: "65px",
          }),attrs:{"label":"Operating System","value":"operatingsystem"}}),_c('v-radio',{staticClass:"font-weight-bold d-flex align-center flex-row px-4 rounded-lg cursor-pointer",style:({
            border: ("2px solid " + (_vm.selectTypeInstance == 'instanceimage' ? '#2C94D2' : '#BDBDBD')),
            border: ("2px solid " + (_vm.$v.selectTypeInstance.$errors.length ? '#eb5757' : '#BDBDBD')),
            width: '233px',
            height: "65px",
          }),attrs:{"label":"Instance Image","value":"instanceimage"}})],1)],1)],1),_c('v-card-text',{staticClass:"pb-0 pt-0"},[(_vm.selectTypeInstance == 'operatingsystem')?_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Operating System & Version")])]),(_vm.listOs.length == 0)?_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-col',{staticClass:"d-flex flex-wrap pt-0 pl-0 ml-5"},[_c('v-sheet',{staticClass:"mt-0 pl-0 ml-0",attrs:{"max-width":"100%"}},[_c('v-slide-group',{staticClass:"py-2 pt-0 ma-0",attrs:{"active-class":"success","show-arrows":""}},_vm._l((_vm.listOs),function(os){return _c('v-card',{key:os.value,staticClass:"rounded-lg select-instance pb-0 d-flex flex-column",staticStyle:{"margin-right":"30px","max-width":"170px","width":"100%","flex":"1"},style:({
                border: ("1px solid " + (os.value === _vm.osSelected ? '#2C94D2' : '#A5B3BF')),
                backgroundColor: os.value === _vm.osSelected ? '#2C94D205' : undefined,
                cursor: 'pointer',
              }),attrs:{"border-color":"primary","outlined":""},on:{"click":function () {
                  _vm.osSelected = os.value;
                }}},[_c('v-card-text',{staticClass:"py-3 d-flex flex-column",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"pa-0 ma-0 d-flex justify-center flex-column align-center",staticStyle:{"flex":"1"}},[(os.logo)?_c('v-img',{staticClass:"mb-3",staticStyle:{"max-height":"32px","max-width":"32px","background-image":"contain"},attrs:{"aspect-ratio":1 / 1,"src":os.logo,"alt":os.text,"width":"32","height":"32"}}):_vm._e(),_c('p',{staticClass:"font-weight-bold mb-0 text-center",staticStyle:{"font-size":"16px"},style:({
                      color: ("" + (os.value === _vm.osSelected ? '#1F60A8' : '#A5B3BF')),
                    })},[_vm._v(" "+_vm._s(os.text)+" ")]),(os.price > 0 && _vm.discountOs && _vm.discountOs > 0)?_c('div',[_c('p',{staticClass:"mb-0 ma-1 body-2 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(os.price == 0 ? "Non Paid - Free" : _vm.toIDRWithDotFormat(os.price))+" ")]),_c('p',{staticClass:"mb-0 ma-1",staticStyle:{"font-weight":"600"},style:({
                        color: ("" + (os.value === _vm.osSelected ? '#1F60A8' : '#A5B3BF')),
                      })},[_vm._v(" "+_vm._s(os.price == 0 ? "Non Paid - Free" : _vm.toIDRWithDotFormat(os.price - (os.price * _vm.discountOs) / 100))+" ")])]):_c('div',[_c('p',{staticClass:"mb-0 ma-1",staticStyle:{"font-weight":"600"},style:({
                        color: ("" + (os.value === _vm.osSelected ? '#1F60A8' : '#A5B3BF')),
                      })},[_vm._v(" "+_vm._s(os.price == 0 ? "Non Paid - Free" : _vm.toIDRWithDotFormat(os.price))+" ")])])],1)]),_c('v-divider',{style:({
                  background: ("" + (os.value === _vm.osSelected ? '#2C94D2' : '#A5B3BF')),
                })}),_c('v-card-text',{staticClass:"ma-0 pa-0",staticStyle:{"max-height":"44px"}},[(os.value === _vm.osSelected)?_c('v-select',{staticClass:"ma-0 px-0 py-0 select-version",staticStyle:{"margin-top":"-6px !important","color":"#1f60a8"},attrs:{"error-messages":_vm.$v.versionSelected.$errors.length ? _vm.$v.versionSelected.$errors[0].$message : '',"items":_vm.images
                      .filter(function (x) { return x.os_id === _vm.osSelected; })
                      .map(function (x) {
                        return {
                          text: ((x.version_number) + " " + (x.version_name)),
                          value: x.id,
                        };
                      }),"placeholder":_vm.$v.versionSelected.$errors.length ? '' : 'Select Version',"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('p',{staticClass:"font-weight-medium mb-0 my-0 pa-0"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true),model:{value:(_vm.versionSelected),callback:function ($$v) {_vm.versionSelected=$$v},expression:"versionSelected"}}):_c('v-select',{staticClass:"ma-0 px-0 py-0 select-version",staticStyle:{"margin-top":"-6px !important","color":"'#A5B3BF'"},attrs:{"error-messages":_vm.$v.versionSelected.$errors.length ? _vm.$v.versionSelected.$errors[0].$message : '',"items":_vm.images
                      .filter(function (x) { return x.os_id === _vm.osSelected; })
                      .map(function (x) {
                        return {
                          text: ((x.version_number) + " " + (x.version_name)),
                          value: x.id,
                        };
                      }),"placeholder":_vm.$v.versionSelected.$errors.length ? '' : 'Select Version',"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('p',{staticClass:"font-weight-medium mb-0 my-0 pa-0",style:({
                        color: ("" + (os.value === _vm.osSelected ? '#1F60A8' : '#BDBDBD')),
                      })},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true)})],1)],1)}),1)],1)],1)],1):_vm._e()],1),(_vm.selectTypeInstance == 'instanceimage')?_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Instance Image")]),_c('v-select',{attrs:{"items":_vm.customImages,"placeholder":"Choose Custom Image","outlined":"","error-messages":_vm.$v.selectedCustomImage.$errors.length ? 'Instance Image couldnt be empty.' : '',"item-value":"id","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" (version "+_vm._s(item.version)+") ")]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" (version "+_vm._s(item.version)+") ")]}}],null,false,3176612220),model:{value:(_vm.selectedCustomImage),callback:function ($$v) {_vm.selectedCustomImage=$$v},expression:"selectedCustomImage"}})],1)],1)],1):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Zone")]),_c('v-select',{attrs:{"id":"SelectZone","items":_vm.zoneByProject,"item-value":"zone","item-text":"name","return-object":"","placeholder":"Choose Zone","outlined":"","error-messages":_vm.$v.selectedZoneModel.$errors.length ? _vm.$v.selectedZoneModel.$errors[0].$message : ''},model:{value:(_vm.selectedZoneModel),callback:function ($$v) {_vm.selectedZoneModel=$$v},expression:"selectedZoneModel"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Instance Billing Type")])]),_c('v-select',{attrs:{"id":"billingType","placeholder":"Choose Instance Billing Type","items":_vm.listBillingType,"item-value":"value","item-text":"text","outlined":"","error-messages":_vm.$v.selectedBillingType.$errors.length ? _vm.$v.selectedBillingType.$errors[0].$message : ''},on:{"change":function($event){return _vm.getTrial($event)},"blur":function($event){return _vm.$v.selectedBillingType.$touch()}},model:{value:(_vm.selectedBillingType),callback:function ($$v) {_vm.selectedBillingType=$$v},expression:"selectedBillingType"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[(_vm.selectedBillingType == 'Trial')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6"}},[_c('p',[_c('b',[_vm._v("Trial Voucher")])]),_c('v-select',{attrs:{"id":"trialVoucher","placeholder":"Choose trial voucher to use","error-messages":_vm.$v.selectedTrialVoucher.$errors.length ? _vm.$v.selectedTrialVoucher.$errors[0].$message : '',"items":_vm.vouchers,"item-value":"id","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.code)+" ")]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.code)+" ")]}}],null,false,2375182940),model:{value:(_vm.selectedTrialVoucher),callback:function ($$v) {_vm.selectedTrialVoucher=$$v},expression:"selectedTrialVoucher"}})],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"white--text fz-16",attrs:{"color":"secondary","block":"","height":"45"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }