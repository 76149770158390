var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-7 rounded-0 rounded-b-lg",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm._header,"hide-default-footer":"","items":_vm.networks,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.totalRowsNetwork},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any NAT Gateway ")])])]},proxy:true},{key:"item.ip_public",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.ip_public ? item.ip_public : '-')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',{class:{
                  'success--text': item.status == 'CREATED',
                  'error--text' : item.status == 'FAILED',
                  'grey--text' : item.status == 'ON-PROGRESS' 
                  }},[_vm._v(_vm._s(item.status))]):_c('span',[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(item.status == 'CREATED')?_c('router-link',{attrs:{"to":{ path: ("/network/nat/" + (item.id)) }}},[_vm._v(" Configuration ")]):_c('span',{staticClass:"grey--text"},[_vm._v(" Configuration ")])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(_vm.networks.length > 5)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}}):_vm._e()]}}],null,true)}),(_vm.opendialogdeletenetwork == true)?_c('dialog-delete',{attrs:{"itemSelected":_vm.itemSelected,"header":_vm.headerName},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.opendialogdeletenetwork),callback:function ($$v) {_vm.opendialogdeletenetwork=$$v},expression:"opendialogdeletenetwork"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }