var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('div',{staticClass:"firewall-title"},[_vm._v("Network")]),_c('v-spacer'),_c('router-link',{attrs:{"to":"/networking/create-network"}},[(!_vm.read_only)?_c('v-btn',{staticClass:"white--text fz-16",attrs:{"depressed":"","color":"secondary","width":"150","height":"45"}},[_vm._v(" Create Network ")]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listNetwork,"item-key":"id","single-line":"","hide-default-footer":"","loading":_vm.isLoadingNetwork,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalNetwork},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/networking/" + (item.name) + "/" + (_vm.getId(item.id)) + "/networks")}},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                    _vm.selectedItem = item
                    _vm.openDialogDelete = true
                  }}},[_vm._v(" Delete ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status == 'REALIZED')?_c('div',[_c('v-row',[_c('div',[_c('span',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle-outline")])],1)]),_c('div',[_c('span',[_vm._v(" Normal")])])])],1):(item.status == 'CONFIGURING')?_c('div',[_c('v-row',[_c('div',[_c('span',[_c('v-progress-circular',{attrs:{"size":16,"color":"primary","indeterminate":""}})],1)]),_c('div',[_c('span',[_vm._v(" Busy")])])])],1):_c('div',[_c('v-row',[_c('div',[_c('span',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close-circle")])],1)]),_c('div',[_c('span',[_vm._v(" Critical")])])])],1)]}},{key:"item.orgVdc",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cloud-outline")]),_vm._v(" "+_vm._s(item.orgVdc.name))],1)]}},{key:"item.subnets",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.subnets.values),function(row,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(row.gateway)+"/"+_vm._s(row.prefixLength))])])})}},{key:"item.networkType",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.networkType == "ISOLATED" ? "Isolated" : "Routed"))])]}},{key:"item.connection",fn:function(ref){
                  var item = ref.item;
return [(item.connection)?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pan")]),_vm._v(" "+_vm._s(item.connection.routerRef.name))],1):_c('span',[_vm._v("-")])]}},{key:"item.totalIpCount",fn:function(ref){
                  var item = ref.item;
return [(item.totalIpCount == 0)?_c('div',[_vm._v("-")]):_c('div',[_c('v-progress-linear',{attrs:{"value":_vm.getPersen(item),"height":"10"}}),_c('span',[_vm._v(_vm._s(_vm.getPersen(item))+"%")])],1)]}},{key:"item.shared",fn:function(ref){
                  var item = ref.item;
return [_c('div',[(item.shared == true)?_c('span',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle-outline")])],1):_c('span',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close-circle")])],1)])]}},{key:"item.routeAdvertised",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.routeAdvertised == false ? "-" : "-"))])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = val;
                        _vm.changePage(val);
                      }}})]}}],null,true)})],1)],1),_c('dialogDekaPrime'),(_vm.openDialogDelete)?_c('DialogDelete',{attrs:{"itemSelected":_vm.selectedItem,"header":'Network'},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }