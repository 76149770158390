var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-5",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-2 pb-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Filter Activity","item-text":"text","item-value":"value","outlined":"","clearable":""},on:{"change":function($event){return _vm.changeActivity($event)}},model:{value:(_vm.filterActivity),callback:function ($$v) {_vm.filterActivity=$$v},expression:"filterActivity"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listActivity.dataActivity,"item-key":"id","single-line":"","loading":_vm.loadingActivity,"hide-default-footer":"","server-items-length":_vm.listActivity['total activity'],"options":_vm.filter},on:{"update:options":function($event){_vm.filter=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.fullname))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$FormatDate(item.created_at)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [(_vm.listActivity.dataActivity)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.filter = val;
                    _vm.changePage(val);
                  }}}):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }