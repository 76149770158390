import { render, staticRenderFns } from "./create-new-project-form.vue?vue&type=template&id=0ae033d6&scoped=true"
import script from "./create-new-project-form.vue?vue&type=script&lang=js"
export * from "./create-new-project-form.vue?vue&type=script&lang=js"
import style0 from "./create-new-project-form.vue?vue&type=style&index=0&id=0ae033d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae033d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VBtn,VCardText,VCol,VContainer,VForm,VRadio,VRadioGroup,VRow,VSelect,VTextField,VTextarea})
