var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-7 rounded-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v(" Manage Project Commitment Fee ")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewCommitment","depressed":"","height":"50"},on:{"click":function () {
              _vm.opendialogadd = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Add New Commitment")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"id":"FilterOrg","search-input":_vm.searchorganization,"items":_vm.organizations,"outlined":"","item-text":"name","item-value":"id","placeholder":"Organization","attach":"#FilterOrgAttach","clearable":"","hide-details":""},on:{"update:searchInput":function($event){_vm.searchorganization=$event},"update:search-input":function($event){_vm.searchorganization=$event},"focus":function () {
              _vm.fetchorganizations({ search: _vm.searchorganization });
            }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":"FilterOrgAttach"}})]},proxy:true}]),model:{value:(_vm.filterOrganization),callback:function ($$v) {_vm.filterOrganization=$$v},expression:"filterOrganization"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"maxlength":"50","hide-details":"","outlined":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"id":"Table","hide-default-footer":"","items":_vm.commitments,"items-per-page":10,"server-items-length":_vm.pagination.count,"headers":_vm.headers,"search":_vm.search,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization.name)+" ")]}},{key:"item.project",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.project.name)+" ")]}},{key:"item.fee",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.fee))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status === 'APPROVED')?_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(item.status.toLowerCase()))]):(item.status === 'DECLINED')?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(item.status.toLowerCase()))]):_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(item.status.toLowerCase()))])]}},{key:"item.duration",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.duration)+" Year")])]}},{key:"item.discount",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.discount)+"%")])]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.start_date).format("DD/MM/YYYY"))+" - "),_c('br'),_vm._v(_vm._s(_vm.moment(item.end_date).format("DD/MM/YYYY")))])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.status !== 'APPROVED' && item.status !== 'DECLINED')?_c('v-btn',{staticClass:"secondary",attrs:{"id":item.id},on:{"click":function () {
                  _vm.SELECT_COMMITMENT(item);
                  _vm.openDialogApprove = true;
                }}},[_vm._v(" Approve ")]):_c('span',[_vm._v("-")])]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                  _vm.options = Object.assign({}, _vm.options, val);
                }}})]}}])})],1)],1)],1),_c('dialog-add-commitment',{on:{"added":function () {
        _vm.fetchorganizations(_vm.options);
      }},model:{value:(_vm.opendialogadd),callback:function ($$v) {_vm.opendialogadd=$$v},expression:"opendialogadd"}}),_c('dialog-approve-commitment',{model:{value:(_vm.openDialogApprove),callback:function ($$v) {_vm.openDialogApprove=$$v},expression:"openDialogApprove"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }