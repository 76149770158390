var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7 rounded-lg",attrs:{"id":"container"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Edit NICs for "+_vm._s('"'+ _vm.data.Name + '"'))])])]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"primary--text fz-16 ml-4",staticStyle:{"cursor":"pointer"},on:{"click":_vm.addNew}},[_vm._v(" NEW ")]),(_vm.radio1)?_c('span',{staticClass:"primary--text fz-16 ml-4",staticStyle:{"cursor":"pointer"},on:{"click":_vm.deleteRow}},[_vm._v(" DELETE ")]):_c('span',{staticClass:"grey--text fz-16 ml-4"},[_vm._v(" DELETE ")])]),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headersNic,"items":_vm.nicsValues,"item-key":"id","single-line":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.radio",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-radio-group',{model:{value:(_vm.radio1),callback:function ($$v) {_vm.radio1=$$v},expression:"radio1"}},[_c('v-radio',{key:_vm.nicsValues.indexOf(item),attrs:{"value":item.id},on:{"click":function($event){return _vm.getRow(item, index)}}})],1)]}},{key:"item.NeedsCustomization",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-radio-group',{model:{value:(_vm.primaryRadio),callback:function ($$v) {_vm.primaryRadio=$$v},expression:"primaryRadio"}},[_c('v-radio',{key:item.id,attrs:{"value":item.id,"off-icon":'mdi-checkbox-blank-outline',"on-icon":'mdi-checkbox-marked'},on:{"click":function($event){return _vm.getRowPrimary(item)}}})],1)],1)]}},{key:"item.IsConnected",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-checkbox',{attrs:{"input-value":item.IsConnected},on:{"click":function($event){item.IsConnected = !item.IsConnected}}},[_vm._v(_vm._s(item.IsConnected))])],1)]}},{key:"item.NetworkAdapterType",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"200px"},attrs:{"disabled":!item.adding,"items":_vm.listNat},model:{value:(item.NetworkAdapterType),callback:function ($$v) {_vm.$set(item, "NetworkAdapterType", $$v)},expression:"item.NetworkAdapterType"}})]}},{key:"item.Network",fn:function(ref){
var item = ref.item;
return [_c('NetworkSelect',{on:{"networkVal":function (val) { 
            if(val == 'none') { 
              item.IPAddressAllocationMode = 'NONE'
              }else{
                item.IPAddressAllocationMode = 'DHCP'
              }
            }},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}},{key:"item.IPAddressAllocationMode",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"200px"},attrs:{"items":_vm.ipModes},on:{"change":function ($event) {
            if($event == 'NONE'){
              item.Network = 'none'
            }
          }},model:{value:(item.IPAddressAllocationMode),callback:function ($$v) {_vm.$set(item, "IPAddressAllocationMode", $$v)},expression:"item.IPAddressAllocationMode"}})]}},{key:"item.IPAddress",fn:function(ref){
          var item = ref.item;
return [_c('IpAddressField',{model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}}],null,true)})],1),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"accent",attrs:{"disabled":_vm.isLoadingBtn,"depressed":"","height":"35","block":""},on:{"click":function () {
              _vm.dialog = false
            }}},[_vm._v("Cancel")])],1),_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"secondary",attrs:{"loading":_vm.isLoadingBtn,"disabled":_vm.isLoadingBtn,"id":"Add","depressed":"","height":"35","block":""},on:{"click":_vm.update}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }