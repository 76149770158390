var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title ml-5"},[_vm._v("IP Set")])]),_c('v-row',{staticClass:"ml-2 mr-4"},[_c('v-spacer'),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"block":"","color":"secondary","outlined":"","dark":""},on:{"click":function () {
                  _vm.openDialogAdd = true
                  _vm.title = 'Add'
                }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("  New")],1)],1)],1),_c('v-container',{staticClass:"px-7 pt-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listIpset,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.rowsIpset,"item-key":"no","single-line":"","loading":_vm.isLoadingIpset,"hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-card',{staticClass:"scroll"},[_c('v-row',[_c('v-col',{staticClass:"pt-3 pr-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function () {
                          _vm.row = item
                          _vm.openDialogAdd = true
                          _vm.title = 'Edit'
                        }}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function () {
                            _vm.openDialogDelete = true
                            _vm.row = item
                          }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = val ;
            }}})]}}])})],1)],1)],1)],1),(_vm.openDialogAdd)?_c('dialogAddIpset',{attrs:{"title":_vm.title,"rowSelected":_vm.row},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e(),(_vm.openDialogDelete)?_c('dialogDeleteIpset',{attrs:{"rowSelected":_vm.row},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }