var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticStyle:{"margin-top":"65px"}},[_c('v-container',{style:({
      'background-image': 'url(' + require('@/assets/RegisterPage.png') + ')',
      'background-position': 'bottom right',
      'padding-bottom': '50px',
      'background-size': 'contain',
      height: '100%',
    }),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex pt-7 justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"background":"#ffffff","box-shadow":"0px 0px 20px rgba(0, 0, 0, 0.05)","border-radius":"20px"},attrs:{"width":"460"}},[_c('v-container',[_c('v-col',{staticClass:"justify-center"},[_c('h1',{staticStyle:{"text-align":"center","font-weight":"800","font-size":"28px","line-height":"36px","letter-spacing":"0.04em","font-family":"Mont","font-style":"normal"}},[_vm._v(" Create new password ")]),_c('v-form',{ref:"form",staticStyle:{"margin-top":"40px"},attrs:{"lazy-validation":""}},[_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"maxlength":"255","id":"password","flat":"","outlined":"","name":"input-10-1","placeholder":"New Password","error-messages":_vm.$v.newPassword.$errors.length
                      ? _vm.$v.newPassword.$errors[0].$message
                      : ''},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{staticStyle:{"margin-bottom":"22px"},attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"maxlength":"255","id":"password","flat":"","outlined":"","name":"input-10-1","placeholder":"Confirm New Password","error-messages":_vm.$v.confirmPassword.$errors.length
                      ? _vm.$v.confirmPassword.$errors[0].$message
                      : ''},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-btn',{staticClass:"mr-4 mt-7 text-capitalize font-weight-bold",attrs:{"id":"submit","block":"","height":"50","depressed":"","color":"secondary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.loading ? "Create Password..." : "Create Password")+" ")]),_c('br')],1)],1)],1)],1)],1)],1)],1),_c('dialogsuccess',{on:{"confirm":function () {
        _vm.redirect();
      }},model:{value:(_vm.opendialogsuccess),callback:function ($$v) {_vm.opendialogsuccess=$$v},expression:"opendialogsuccess"}}),_c('dialogfaillresetVue',{attrs:{"message":_vm.dialogfailresetMessage},on:{"close":function () {
          _vm.dialogfailreset = false;
        }},model:{value:(_vm.dialogfailreset),callback:function ($$v) {_vm.dialogfailreset=$$v},expression:"dialogfailreset"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }