var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Provider VDC")])])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listProviderVdc,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.TotalRowsProviderVdc,"loading":_vm.isLoading,"hide-default-footer":"","item-key":"href"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"hide-details":""},model:{value:(_vm.radioTable),callback:function ($$v) {_vm.radioTable=$$v},expression:"radioTable"}},[_c('v-radio',{key:_vm.listProviderVdc.indexOf(item),attrs:{"value":item.href},on:{"click":function($event){return _vm.getRow(item)}}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 'READY' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status == 'READY' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isEnabled ? 'Enabled' : 'Disabled')+" ")]}},{key:"item.cpu",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.cpuUsedMhz/item.cpuLimitMhz)*100).toFixed(2))+"% ")]}},{key:"item.memory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.memoryUsedMB/item.memoryLimitMB)*100).toFixed(2))+"% ")]}},{key:"item.storage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.storageUsedMB/item.storageLimitMB)*100).toFixed(2))+"% ")]}}],null,true)}),(_vm.$v.providerId.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("Please choose the provider")]):_vm._e()],1)],1)],1),_c('v-divider'),_c('br'),(_vm.listEksternalNetwork.length)?_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("External Networks")]),_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headersEksNetwork,"items":_vm.listEksternalNetwork,"items-per-page":10,"server-items-length":_vm.TotalRowsEksternalNetwork,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 'REALIZED' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status == 'REALIZED' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")]),_vm._v(" "+_vm._s(item.status == 'REALIZED' ? 'Normal' : item.status)+" ")]}},{key:"item.gateway",fn:function(ref){
var item = ref.item;
return _vm._l((item.subnets),function(subnet,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(subnet.gateway +'/'+ subnet.prefixLength)+" ")])})}},{key:"item.dedicatedOrg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dedicatedOrg ? item.dedicatedOrg : '-')+" ")]}},{key:"item.ip_pool",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(((item.usedIpCount/item.totalIpCount)*100)))+"% "+_vm._s(("(Total: " + (item.totalIpCount) + ")"))+" ")]}},{key:"item.backing_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" Distributed Port Group ")]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                      _vm.changePageEksNetwork(val)
                    }}})]}}],null,false,1875040376)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }