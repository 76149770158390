var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userdetail)?_c('div',[_c('v-card',{staticClass:"rounded-lg pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('v-icon',{staticClass:"mr-4",staticStyle:{"font-size":"55px"},attrs:{"color":"#F1F2F2"}},[_vm._v(" mdi-circle ")]),_c('div',{staticClass:"headline font-weight-bold"},[_vm._v(_vm._s(_vm.userdetail.name))]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"height":"50","width":"150","depressed":""},on:{"click":function () {
                  _vm.opendialogedituser = true;
                }}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Edit")])])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-7"},[_c('v-row',{staticStyle:{"font-size":"12px"}},[_c('v-col',{staticClass:"py-0"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Email:")]),_c('br'),_vm._v(" "+_vm._s(_vm.userdetail.email)+" ")])]),_c('v-col',{staticClass:"py-0 d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Organization:")]),_c('br'),_vm._v(_vm._s(_vm.userdetail.org.name))])]),_c('v-col',{staticClass:"py-0 d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Role:")]),_c('br'),_vm._v(_vm._s(_vm.userdetail.role.name))])]),_c('v-col',{staticClass:"py-0 d-flex justify-center"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Join Date:")]),_c('br'),_vm._v("14 Desember 2020")])]),_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex flex-col justify-center"},[_c('div',[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Status:")]),_c('br'),_vm._v("Active")])]),_c('div',{staticClass:"ml-7"},[_c('v-switch',{attrs:{"readonly":true,"inset":""},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)])])],1)],1)],1),_c('div',{staticStyle:{"height":"30px","width":"auto"}}),_c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Member List")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items":_vm.members},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                var props = ref.props;
                var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.project",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.project ? item.project.name : '')+" ")]}},{key:"item.role",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.role.name)+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"flat":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
									on.click($event);
									// show($event, item);
								}}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
									rotate: item.open,
								},attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
									// opendialogdelete = true;
								}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete ")])],1)],1)],1)]}}],null,false,1151383699)})],1),_c('dialogAddUser',{model:{value:(_vm.opendialogadduser),callback:function ($$v) {_vm.opendialogadduser=$$v},expression:"opendialogadduser"}}),(_vm.userdetail)?_c('dialogEditUser',{attrs:{"memberselectedvalue":_vm.userdetail},model:{value:(_vm.opendialogedituser),callback:function ($$v) {_vm.opendialogedituser=$$v},expression:"opendialogedituser"}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }