var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title ml-5"},[_vm._v("NAT")])]),_c('v-row',{staticClass:"ml-2 mr-4"},[_c('v-spacer'),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[(!_vm.read_only)?_c('v-btn',{attrs:{"block":"","color":"secondary","outlined":"","dark":""},on:{"click":function () {
                  _vm.openDialogAdd = true
                  _vm.title = 'Add'
                }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("  New")],1):_vm._e()],1)],1),_c('v-container',{staticClass:"px-7 pt-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listRuleNatNsxt,"item-key":"no","single-line":"","loading":_vm.loadingNat,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.name))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v("User defined")])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.enabled ? 'Enabled' : 'Disabled'))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.type))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.externalAddresses))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.applicationPortProfile ? item.applicationPortProfile.name : '-'))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.internalAddresses))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.dnatExternalPort ? item.dnatExternalPort : '-'))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.snatDestinationAddresses ? item.snatDestinationAddresses : '-'))])])],1),_c('td',[_c('v-card',{staticClass:"scroll"},[_c('span',[_vm._v(_vm._s(item.logging ? 'Enabled' : 'Disabled'))])])],1),_c('td',[_c('v-card',{staticClass:"scroll",staticStyle:{"margin-top":"-5px"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pr-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function () {
                                _vm.row = item
                                _vm.openDialogAdd = true
                                _vm.title = 'Edit'
                              }}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function () {
                                  _vm.openDialogDelete = true
                                  _vm.row = item
                                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1)],1)],1)])]}}],null,true)})],1)],1)],1)],1),(_vm.openDialogAdd)?_c('DialogAddNat',{attrs:{"title":_vm.title,"rowSelected":_vm.row},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e(),(_vm.openDialogDelete)?_c('DialogDeleteNat',{attrs:{"dialog-delete":_vm.openDialogDelete,"rowSelected":_vm.row},on:{"update:dialogDelete":function($event){_vm.openDialogDelete=$event},"update:dialog-delete":function($event){_vm.openDialogDelete=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }