var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"fz-18 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v(" IKE Endpoint Configuration ")]),_c('v-spacer')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Key Exchange version")]),_c('v-select',{attrs:{"append-icon":"mdi-chevron-down","items":_vm.item_key,"placeholder":"Choose Key Exchange Version","outlined":"","single-line":"","dense":"","error-messages":_vm.$v.key_exchange.$errors.length
            ? _vm.$v.key_exchange.$errors[0].$message
            : ''},model:{value:(_vm.key_exchange),callback:function ($$v) {_vm.key_exchange=$$v},expression:"key_exchange"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Internet Protocol")]),_c('v-select',{attrs:{"append-icon":"mdi-chevron-down","items":_vm.items_ip,"placeholder":"Choose Internet Protocol","outlined":"","single-line":"","dense":"","error-messages":_vm.$v.ip.$errors.length
            ? _vm.$v.ip.$errors[0].$message
            : ''},model:{value:(_vm.ip),callback:function ($$v) {_vm.ip=$$v},expression:"ip"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Interface")]),_c('v-select',{attrs:{"append-icon":"mdi-chevron-down","items":_vm.items_infes,"placeholder":"Choose Interface","outlined":"","single-line":"","dense":"","error-messages":_vm.$v.infes.$errors.length
            ? _vm.$v.infes.$errors[0].$message
            : ''},model:{value:(_vm.infes),callback:function ($$v) {_vm.infes=$$v},expression:"infes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Remote Gateway")]),_c('v-text-field',{attrs:{"placeholder":"","single-line":"","outlined":"","dense":"","error-messages":_vm.$v.remote_gateway.$errors.length
            ? _vm.$v.remote_gateway.$errors[0].$message
            : ''},model:{value:(_vm.remote_gateway),callback:function ($$v) {_vm.remote_gateway=$$v},expression:"remote_gateway"}})],1)],1),_c('br'),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }