var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Allocated IPs")])])],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',{attrs:{"md":"2","sm":"12"}},[_c('p',{staticClass:"font-weight-bold mt-3"},[_vm._v("Primary IP")])]),_c('v-col',{staticClass:"d-flex",attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Insert IP","single-line":"","outlined":"","dense":"","error-messages":_vm.$v.rule.primaryIp.$errors.length ? _vm.$v.rule.primaryIp.$errors[0].$message : ''},on:{"change":function () {
                _vm.$v.rule.primaryIp.$touch();
              }},model:{value:(_vm.value.primaryIp),callback:function ($$v) {_vm.$set(_vm.value, "primaryIp", $$v)},expression:"value.primaryIp"}}),_c('v-tooltip',{attrs:{"right":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 pb-2",attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('p',{staticClass:"white--text my-1"},[_vm._v(" All Edge Gateways require at least one IP address allocated to it. "),_c('br'),_vm._v(" This IP address can be used for edge services configuration. "),_c('br'),_vm._v(" Not specifying a Primary IP, will result in using the first available IP from the Provider Router. ")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scrollTab",attrs:{"headers":_vm.headers,"items":_vm.value.tier.subnets,"items-per-page":100,"server-items-length":_vm.totalSubnets,"hide-default-footer":"","item-key":"gateway","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ip_block",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.gateway + '/' + item.prefixLength)+" ")]}},{key:"item.providerRouter",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.value.tier.name)+" ")]}},{key:"item.ip_usage",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(((item.usedIpCount/item.totalIpCount)*100)))+"% "+_vm._s(("(Total: " + (item.totalIpCount) + ")"))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td'),_c('td',{staticClass:"d-flex flex-column align-start",staticStyle:{"height":"100%","border-bottom":"0px"}},[_c('p',{staticClass:"mb-1 mt-3"},[_vm._v("Available IPs")]),_vm._l((_vm.listAvailableIp),function(availSub,key){return _c('div',{key:key},[(item.gateway == availSub.gateway)?_c('div',_vm._l((availSub.ipRanges.values),function(ipRange,i){return _c('p',{key:i,staticClass:"black--text mb-0"},[_vm._v(" "+_vm._s(ipRange.startAddress + ' - ' + ipRange.endAddress)+" ")])}),0):_vm._e()])})],2),_c('td',{attrs:{"colspan":""}},[_c('ip-ranges-input',{attrs:{"listAvailableIp":_vm.listAvailableIp,"item":item},model:{value:(item.inputIpRanges),callback:function ($$v) {_vm.$set(item, "inputIpRanges", $$v)},expression:"item.inputIpRanges"}})],1)]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }