var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('div',{staticClass:"firewall-title mt-2"},[_vm._v("Organizations VDC")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search mr-2",staticStyle:{"max-width":"200px"},attrs:{"maxlength":"50","placeholder":"Search","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.searchOrg),callback:function ($$v) {_vm.searchOrg=$$v},expression:"searchOrg"}}),_c('v-btn',{staticClass:"secondary mt-1",staticStyle:{"height":"45px","width":"150px","font-size":"12px"},attrs:{"link":"","depressed":"","to":"/manage-orgvdc/create"},on:{"click":function () {
                
              }}},[_vm._v(" New ")])],1)],1),_c('br'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersTable,"options":_vm.options,"items":_vm.listOrgVdc,"items-per-page":10,"server-items-length":_vm.TotalRowsOrgVdc,"loading":_vm.isLoading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.detail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status == 'READY' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status == 'READY' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")])]}},{key:"item.isEnabled",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.isEnabled ? 'Enabled' : 'Disabled')+" ")]}},{key:"item.networkProviderType",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.filterType(item.networkProviderType))+" ")]}},{key:"item.cpu",fn:function(ref){
              var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(Math.ceil((item.cpuUsedMhz/item.cpuLimitMhz)*100)),"color":"secondary","height":"20","stream":""}},[_c('strong',[_vm._v(_vm._s((Math.ceil((item.cpuUsedMhz/item.cpuLimitMhz)*100)))+"%")])])]}},{key:"item.memory",fn:function(ref){
              var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(Math.ceil((item.memoryUsedMB/item.memoryLimitMB)*100)),"color":"secondary","height":"20","stream":""}},[_c('strong',[_vm._v(_vm._s((Math.ceil((item.memoryUsedMB/item.memoryLimitMB)*100)))+"%")])])]}},{key:"item.storage",fn:function(ref){
              var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(Math.ceil((item.storageUsedMB/item.storageLimitMB)*100)),"color":"secondary","height":"20","stream":""}},[_c('strong',[_vm._v(_vm._s((Math.ceil((item.storageUsedMB/item.storageLimitMB)*100)))+"%")])])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.status == 'ON-PROGRESS')?_c('label',{staticClass:"grey--text"},[_vm._v(" More "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                          on.click($event);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                          rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(item.status == 'CREATED')?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                        
                      }}},[_c('v-list-item-title',{},[_vm._v("Detail")])],1):_c('v-list-item',[_c('v-list-item-title',{staticClass:"mr-4",staticStyle:{"color":"rgb(0 0 0 / 38%)"}},[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                                                
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.options = val;
                }}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }