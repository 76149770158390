var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Volume Type")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewType","depressed":"","height":"50","width":"150"},on:{"click":function () {
              _vm.openDialogAddType = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Type")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","items-per-page":5,"headers":_vm.headers,"items":_vm.types},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
              _vm.selectedType = item;
              _vm.openDialogTypeEdit = true;
            }}},[_c('span',{staticClass:"primary--text mr-6"},[_vm._v("Edit")])]),_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
              _vm.selectedType = item;
              _vm.openDialogTypeDelete = true;
            }}},[_c('span',{staticClass:"error--text"},[_vm._v("Delete")])])]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}])})],1),(_vm.openDialogAddType)?_c('dialogAddType',{on:{"succeed":_vm.refreshTypes},model:{value:(_vm.openDialogAddType),callback:function ($$v) {_vm.openDialogAddType=$$v},expression:"openDialogAddType"}}):_vm._e(),(_vm.selectedType && _vm.openDialogTypeEdit)?_c('dialogEditType',{attrs:{"item":_vm.selectedType},on:{"succeed":_vm.refreshTypes},model:{value:(_vm.openDialogTypeEdit),callback:function ($$v) {_vm.openDialogTypeEdit=$$v},expression:"openDialogTypeEdit"}}):_vm._e(),(_vm.selectedType && _vm.openDialogTypeDelete)?_c('dialogDeleteType',{attrs:{"item":_vm.selectedType,"loading":_vm.loading,"title":"Delete Volume"},on:{"close":function($event){_vm.openDialogTypeDelete = false},"confirm":_vm.deleteType},model:{value:(_vm.openDialogTypeDelete),callback:function ($$v) {_vm.openDialogTypeDelete=$$v},expression:"openDialogTypeDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }