<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card ref="form" class="rounded-lg" flat>
          <v-container fluid class="pa-7" v-if="detailPop">
            <v-row>
              <v-col cols="7">
                <div class="psk-title d-flex align-items-center">Edit Dekapop</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Product Name</p>
                <v-text-field
                  v-model="productName"
                  placeholder="Insert Product Name"
                  single-line
                  outlined
                  :error-messages="$v.productName.$errors.length ? $v.productName.$errors[0].$message : ''"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Username</p>
                <v-text-field v-model="username" outlined readonly></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Product Id</p>
                <v-text-field
                  v-model="productId"
                  placeholder="Insert Product Id"
                  single-line
                  outlined
                  :error-messages="$v.productId.$errors.length ? $v.productId.$errors[0].$message : ''"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Service Instance</p>
                <v-text-field
                  v-model="serviceInstance"
                  placeholder="Service Instance"
                  single-line
                  outlined
                  :error-messages="$v.serviceInstance.$errors.length ? $v.serviceInstance.$errors[0].$message : ''"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Type of License</p>
                <v-text-field
                  v-model="licenseType"
                  placeholder="Insert Type of License"
                  single-line
                  outlined
                  :error-messages="$v.licenseType.$errors.length ? $v.licenseType.$errors[0].$message : ''"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">No Contract</p>
                <v-text-field
                  v-model="noContract"
                  placeholder="Insert No Contract"
                  single-line
                  outlined
                  :error-messages="$v.noContract.$errors.length ? $v.noContract.$errors[0].$message : ''"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-card-text class="pt-0 pl-0 pr-0">
                  <p for="start-date"><b>License Created</b></p>
                  <v-menu
                    ref="menu1"
                    v-model="toggleStartDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="licenseCreated"
                        id="formated_start_date"
                        placeholder="DD/MM/YYYY"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="$v.licenseCreated.$errors.length ? $v.licenseCreated.$errors[0].$message : ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="licenseCreated" no-title @input="toggleStartDatePicker = false" color="primary"></v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="6">
                <v-card-text class="pt-0 pl-0 pr-0">
                  <p for="end-date"><b>License Expired</b></p>
                  <v-menu
                    ref="menu2"
                    v-model="toggleEndDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="licenseExpired"
                        id="formated_end_date"
                        placeholder="DD/MM/YYYY"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="$v.licenseExpired.$errors.length ? $v.licenseExpired.$errors[0].$message : ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="licenseExpired" no-title @input="toggleEndDatePicker = false" color="primary"></v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <p class="font-weight-bold">Description</p>
                <v-textarea v-model="description" placeholder="Insert Description" single-line outlined></v-textarea>
              </v-col>
            </v-row>

            <v-row class="my-7">
              <v-col cols="12" md="6">
                <v-btn :disabled="isLoadingBtn" block class="accent fz-14" @click="$router.go(-1)" height="50">
                  <span>Cancel</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn :loading="isLoadingBtn" :disabled="isLoadingBtn" block class="secondary fz-14" depressed @click="create" height="50">
                  <span>Edit Deka Pop</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useNamespacedActions, useNamespacedGetters } from "vuex-composition-helpers";
import { POP } from "./namespace";
import { onMounted, ref, computed } from "@vue/composition-api";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";

export default {
  setup(props, context) {
    const { getDetailPop, editPop } = useNamespacedActions(POP, ["getDetailPop", "editPop"]);
    const { isLoadingBtn, detailPop, userPop } = useNamespacedGetters(POP, ["isLoadingBtn", "detailPop", "userPop"]);
    const currentProj = JSON.parse(localStorage.getItem("currentProj"));

    onMounted(() => {
      getDetailPop(context.root.$route.params.id);
    });

    const username = computed(() => {
      return detailPop.value?.user_name;
    });
    const productName = computed({
      get: () => detailPop.value.product_name,
      set: (val) => {
        detailPop.value.product_name = val;
      },
    });

    const description = computed({
      get: () => detailPop.value.description,
      set: (val) => {
        detailPop.value.description = val;
      },
    });
    const licenseType = computed({
      get: () => detailPop.value.license_type,
      set: (val) => {
        detailPop.value.license_type = val;
      },
    });
    const noContract = computed({
      get: () => detailPop.value.no_contract,
      set: (val) => {
        detailPop.value.no_contract = val;
      },
    });
    const licenseCreated = computed({
      get: () => formatToDate(detailPop.value.license_created),
      set: (val) => {
        detailPop.value.license_created = val;
      },
    });
    const licenseExpired = computed({
      get: () => formatToDate(detailPop.value.license_expired),
      set: (val) => {
        detailPop.value.license_expired = val;
      },
    });
    const productId = computed({
      get: () => detailPop.value.product_id,
      set: (val) => {
        detailPop.value.product_id = val;
      },
    });
    const serviceInstance = computed({
      get: () => detailPop.value.service_instance,
      set: (val) => {
        detailPop.value.service_instance = val;
      },
    });

    const toggleStartDatePicker = ref(false);
    const toggleEndDatePicker = ref(false);
    const currentDate = ref(moment(new Date()).format("YYYY-MM-DD"));
    const formatToDate = (date) => moment(date).format("YYYY-MM-DD");

    const $v = useVuelidate(
      {
        productName: { required },
        licenseType: { required },
        noContract: { required },
        licenseCreated: { required },
        licenseExpired: { required },
        productId: { required },
        serviceInstance: { required },
      },
      {
        productName,
        licenseType,
        noContract,
        licenseCreated,
        licenseExpired,
        productId,
        serviceInstance,
      }
    );
    const create = async () => {
      $v.value.$touch();
      if ($v.value.$errors.length) {
        return;
      }
      let payload = {
        id: detailPop.value.id,
        project_id: currentProj.id,
        organization_id: currentProj.organization_id,
        user_id: JSON.parse(localStorage.getItem("userId")),
        product_name: productName.value,
        license_type: licenseType.value,
        no_contract: noContract.value,
        licence_created: licenseCreated.value,
        license_expired: licenseExpired.value,
        description: description.value,
        product_id: productId.value,
        service_instance: serviceInstance.value,
      };
      // console.log(payload);
      await editPop(payload);
    };

    return {
      $v,
      create,
      productName,
      licenseType,
      noContract,
      formatToDate,
      currentDate,
      licenseCreated,
      licenseExpired,
      computed,
      toggleStartDatePicker,
      toggleEndDatePicker,
      isLoadingBtn,
      detailPop,
      username,
      userPop,
      description,
      productId,
      serviceInstance,
    };
  },
};
</script>

<style lang="scss" scoped>
.psk-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}
</style>
