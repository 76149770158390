var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-4",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Snapshot ")])])],1),_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab1),callback:function ($$v) {_vm.tab1=$$v},expression:"tab1"}},[_c('v-tab',[_vm._v("Instance")]),_c('v-tab',[_vm._v("Storage")])],1)],1)],1),_c('v-divider')],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab1),callback:function ($$v) {_vm.tab1=$$v},expression:"tab1"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.instances,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name.text))])]),_c('p',[_vm._v(_vm._s(item.name.spec))])],1)])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.size.vol))]),_c('p',[_vm._v(_vm._s(item.size.item))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('label',{staticClass:"text-primary",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.show($event, item)}}},[_vm._v("More "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},(_vm.instances.length > 5)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1),_c('v-tab-item',[_c('div',{staticClass:"d-flex"},[_c('v-col',{staticClass:"d-flex flex-column text-center ma-auto",staticStyle:{"padding-top":"77px","padding-bottom":"76px"},attrs:{"md":"6"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v(" Looks like you don’t have any volume snapshots. ")]),_c('p',{staticStyle:{"color":"#A5B3BF"}},[_vm._v(" Snapshots allow you to manually copy and store an image of your volume. Take one using the form up top or from the “More” dropdown menu on any volume. ")])])],1)])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Take a Snapshot ")]),_c('p',[_vm._v(" Power-down Instances before taking a snapshot to ensure data consistency. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":""}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":""}})],1)])],1)],1),_c('v-col',{attrs:{"md":"2 align-self-end"}},[_c('v-btn',{attrs:{"depressed":"","width":"150","height":"50","color":"secondary"}},[_vm._v("Take Snapshot ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }