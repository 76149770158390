<template>
  <div>
    <v-card class="rounded-lg pa-3" flat>
      <v-row class="pl-4">
        <v-col cols="9">
          <div class="firewall-title mt-2">Deka Prime on Premise Report Usage</div>
        </v-col>
      </v-row>
      <v-row class="d-flex ml-1">
        <v-col cols="12">
          <v-card outlined class="rounded-lg fullheight pa-5">
            <v-card-text>
              <v-row>
                <v-col
                  ><v-row>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">User Name</p>
                      <span class="font-weight-bold font--text">{{ detailPop.user_name }}</span>
                    </v-col>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">Product Name</p>
                      <span class="font-weight-bold font--text">{{ detailPop.product_name }}</span>
                    </v-col>
                  </v-row></v-col
                >
                <v-col
                  ><v-row>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">License Type</p>
                      <span class="font-weight-bold font--text">{{ detailPop.license_type }}</span>
                    </v-col>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">No Contract</p>
                      <span class="font-weight-bold font--text">{{ detailPop.no_contract }}</span>
                    </v-col>
                  </v-row></v-col
                >
              </v-row>

              <v-row>
                <v-col
                  ><v-row>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">Productid</p>
                      <span class="font-weight-bold font--text">{{ detailPop.product_id || "-" }}</span>
                    </v-col>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">Service Instance</p>
                      <span class="font-weight-bold font--text">{{ detailPop.service_instance || "-" }}</span>
                    </v-col>
                  </v-row></v-col
                >
                <v-col
                  ><v-row>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">Validity License</p>
                      <span class="font-weight-bold font--text">{{ detailPop.validity_license }}</span>
                    </v-col>
                    <v-col sm="12" md="6" class="pt-0"
                      ><p class="mb-1 font-weight-regular">Description</p>
                      <span class="font-weight-bold font--text">{{ detailPop.description }}</span>
                    </v-col>
                  </v-row></v-col
                >
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="mt-5">
              <v-row class="d-flex"
                ><v-col cols="9">
                  <div class="headline font-weight-bold font--text">Usage List</div>
                </v-col>
                <v-col md="1"></v-col>
                <v-col md="2" v-if="role.toLowerCase() == 'superadmin'">
                  <router-link :to="`/dekapop/create/usage/${$route.params.id}`" v-if="!read_only"
                    ><v-btn block color="secondary" dark height="45" width="150" class="fz-14">Create Usage</v-btn></router-link
                  ></v-col
                ></v-row
              >
              <v-row>
                <v-col md="12">
                  <v-data-table
                    class="mb-5"
                    :items="listUsage"
                    :headers="headers"
                    item-key="id"
                    show-expand
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    hide-default-footer
                    :server-items-length="totalRowsUsage"
                  >
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-card class="bs-no pa-4">
                          <v-col>
                            <v-row>
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Usage Hour</p>
                                    <span class="font-weight-bold font--text">{{ item.usage_hour }}</span>
                                  </v-col>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Total Core</p>
                                    <span class="font-weight-bold font--text">{{ item.total_cores }}</span>
                                  </v-col>
                                </v-row></v-col
                              >
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Commitment Core</p>
                                    <span class="font-weight-bold font--text">{{ item.commitment_core }}</span>
                                  </v-col>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">On Demand Core</p>
                                    <span class="font-weight-bold font--text">{{ item.on_demand_core }}</span>
                                  </v-col>
                                </v-row></v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Tarif Commitment Core</p>
                                    <span class="font-weight-bold font--text">{{ toIDRWithDotFormat(item.tariff_commitment_core) }}</span>
                                  </v-col>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Tarif On Demand Core</p>
                                    <span class="font-weight-bold font--text">{{ toIDRWithDotFormat(item.tariff_on_demand_core) }}</span>
                                  </v-col>
                                </v-row></v-col
                              >
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">USD Rate</p>
                                    <span class="font-weight-bold font--text">{{ toIDRWithDotFormat(item.usd_to_idr_rate) }}</span>
                                  </v-col>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Total Tarif</p>
                                    <span class="font-weight-bold font--text">{{ toIDRWithDotFormat(item.total_amount) }}</span>
                                  </v-col>
                                </v-row></v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Status Payment</p>
                                    <span class="font-weight-bold font--text">{{ item.status_payment ? "Paid" : "Unpaid" }}</span>
                                  </v-col>
                                  <v-col sm="12" md="6" class="pt-0"
                                    ><p class="mb-1 font-weight-regular">Month / Year</p>
                                    <span class="font-weight-bold font--text">{{ item.month }} / {{ item.year }}</span>
                                  </v-col>
                                </v-row></v-col
                              >
                              <v-col
                                ><v-row>
                                  <v-col sm="12" md="6" class="pt-0"> </v-col>
                                  <v-col sm="12" md="6" class="pt-0"> </v-col> </v-row
                              ></v-col>
                            </v-row>
                          </v-col>
                        </v-card>
                      </td>
                    </template>

                    <template #[`item.usage_created`]="{ item }">
                      <span>{{ $FormatDate(item.usage_created) }} - {{ $FormatDate(item.usage_expired) }}</span>
                    </template>

                    <template #[`item.action`]="{ item }">
                      <popupquote v-model="popo" :offsetTop="20" :coordinate="coordinate" :documentid="'popup' + item.id" offset-y allow-overflow>
                        <template v-slot:activator="{ on, attrs }">
                          <label
                            v-bind="attrs"
                            class="primary--text linkpointer"
                            style="position: relative; white-space: nowrap"
                            @click="
                              ($event) => {
                                on.click($event);
                                show($event, item);
                              }
                            "
                            >More
                            <v-icon
                              :class="{
                                rotate: item.id == activemenuinstance && popo,
                              }"
                              :id="'popup' + item.id"
                              color="primary"
                              >mdi-chevron-down
                            </v-icon>
                          </label>
                        </template>
                        <v-list class="font-weight-bold">
                          <v-list-item v-if="!read_only">
                            <v-list-item-title
                              ><span class="primary--text"><router-link :to="`/dekapop/edit/usage/${item.id}`">Edit</router-link></span></v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item v-if="!read_only">
                            <v-list-item-title
                              ><span
                                class="red--text cursor-pointer"
                                @click="
                                  () => {
                                    dataItem = item;
                                    openDialog = true;
                                  }
                                "
                                >Delete</span
                              ></v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </popupquote>
                    </template>

                    <template v-slot:footer="{ props }">
                      <custom-footer-datatable
                        @input="
                          (val) => {
                            changePage(val);
                          }
                        "
                        :props="props"
                      />
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <DeleteUsage :dialog-closed.sync="openDialog" :data-item="dataItem" v-if="openDialog"></DeleteUsage>
  </div>
</template>

<script>
import { useNamespacedActions, useNamespacedGetters } from "vuex-composition-helpers";
import { POP } from "./namespace";
import { onMounted, ref, reactive } from "@vue/composition-api";
import { toIDRWithDotFormat } from "@/lib/formatter";
import DeleteUsage from "./delete-usage.vue";
import { AccessControl } from "@/lib/middleware";

export default {
  components: {
    DeleteUsage,
  },
  setup(props, context) {
    const { getDetailPop, getListUsage } = useNamespacedActions(POP, ["getDetailPop", "getListUsage"]);
    const { detailPop, listUsage, totalRowsUsage } = useNamespacedGetters(POP, ["detailPop", "listUsage", "totalRowsUsage"]);
    const access_control = new AccessControl(context.root.$store);
    const read_only = ref(access_control.is_read_mode("Deka POP"));
    onMounted(async () => {
      await getDetailPop(context.root.$route.params.id);
      const params = new URLSearchParams();
      params.append("dekapopId", context.root.$route.params.id);
      params.append("limit", "10");
      params.append("page", "1");
      params.append("sort", "created_at desc");
      await getListUsage(params);
      if (role.value.toLowerCase() == "superadmin") headers.value.push({ text: "Action", value: "action", sortable: false });
    });

    const headers = ref([
      { text: "License Name", value: "license_name", sortable: false },
      { text: "", value: "data-table-expand" },
      { text: "License Status", value: "license_status", sortable: false },
      { text: "License Key", value: "license_key", sortable: false },
      { text: "Validity License", value: "usage_created", sortable: false },
      { text: "Description", value: "description", sortable: false },
    ]);

    const changePage = async (val) => {
      const params = new URLSearchParams();
      params.append("dekapopId", context.root.$route.params.id);
      params.append("limit", val.itemsPerPage);
      params.append("page", val.page);
      params.append("sort", "created_at desc");
      await getListUsage(params);
    };
    const dataItem = ref("");
    const openDialog = ref(false);
    const expanded = ref([]);
    const singleExpand = ref(true);
    const role = ref(localStorage.getItem("role"));
    const popo = ref(false);
    const coordinate = reactive({ x: 0, y: 0 });
    const showMenu1 = ref(false);
    const activemenuinstance = ref({});
    const show = (e, instance) => {
      e.preventDefault();
      activemenuinstance.value = instance.id;
      showMenu1.value = false;
      coordinate.x = e.clientX - 20;
      coordinate.y = e.clientY + 10;
      context.root.$nextTick(() => {
        showMenu1.value = true;
      });
    };

    return {
      detailPop,
      headers,
      listUsage,
      changePage,
      totalRowsUsage,
      toIDRWithDotFormat,
      popo,
      coordinate,
      show,
      showMenu1,
      activemenuinstance,
      role,
      singleExpand,
      expanded,
      dataItem,
      openDialog,
      read_only,
    };
  },
};
</script>

<style lang="scss" scoped>
.psk-title {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 140%;
  color: #556272;
}

.blue-border {
  border-color: #2c94d2;
}
.bs {
  box-shadow: none;
}
::v-deep .v-text-field__details {
  position: static;
}
.mdi-chevron-down.rotate::before {
  transform: rotate(-180deg);
  transition: 100ms linear all;
}
.mdi-chevron-down::before {
  transition: 100ms linear all;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #fbfbfb !important;
}
.bs-no {
  box-shadow: none !important;
}
</style>
