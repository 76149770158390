var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Load Balancer ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":10,"headers":_vm.headers,"items":_vm.loadBalancers,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/network/loadbalancer/' + item.id + '/settings'}},[_vm._v(" Edit ")]),_c('button',{staticClass:"error--text",on:{"click":function () {
                      _vm.selectedLoadBalancer = item;
                      _vm.openDialogDeleteLoadBalancer = true;
                    }}},[_vm._v(" Delete ")])]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [(
                    (_vm.pagination && _vm.pagination.count > 5) ||
                      (_vm.pagination && _vm.pagination.count === 5)
                  )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                      _vm.pagination = Object.assign({}, _vm.pagination, val);
                    }}}):_vm._e()]}}])})],1)],1)],1)],1)],1),(_vm.openDialogDeleteLoadBalancer && _vm.selectedLoadBalancer)?_c('dialogDeleteAutoScaling',{attrs:{"item":_vm.selectedLoadBalancer,"loading":_vm.loading},on:{"cancel":function($event){_vm.openDialogDeleteLoadBalancer = false},"submit":_vm.deleteLoadBalancer},model:{value:(_vm.openDialogDeleteLoadBalancer),callback:function ($$v) {_vm.openDialogDeleteLoadBalancer=$$v},expression:"openDialogDeleteLoadBalancer"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }