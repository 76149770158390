var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingVdc)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)])],1)],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 pt-0",attrs:{"fluid":""}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"secondary","dark":""},on:{"click":_vm.dialogEdit}},[_vm._v(" Edit ")])],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headersNic,"items":_vm.detailVm.NetworkConnectionSection.NetworkConnection ? _vm.detailVm.NetworkConnectionSection.NetworkConnection : [],"item-key":"id","single-line":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pnic",fn:function(ref){
var item = ref.item;
return [(_vm.detailVm.NetworkConnectionSection.NetworkConnection.indexOf(item) == _vm.detailVm.NetworkConnectionSection.PrimaryNetworkConnectionIndex)?_c('span',[_vm._v(_vm._s(_vm.detailVm.NetworkConnectionSection.PrimaryNetworkConnectionIndex == _vm.detailVm.NetworkConnectionSection.PrimaryNetworkConnectionIndex ? "Yes" : ""))]):_vm._e()]}},{key:"item.IsConnected",fn:function(ref){
var item = ref.item;
return [(item.IsConnected == true)?_c('div',{staticClass:"text-center"},[_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1)]):_c('div',{staticClass:"text-center"},[_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1)])]}},{key:"item.IPAddress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.IPAddress || "-"))])])]}},{key:"item.ExternalIPAddress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.ExternalIPAddress || "-"))])])]}}],null,true)})],1)],1)],1)],1),(_vm.openDialogEdit)?_c('DialogEdit',{attrs:{"data":_vm.dataVm},model:{value:(_vm.openDialogEdit),callback:function ($$v) {_vm.openDialogEdit=$$v},expression:"openDialogEdit"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }