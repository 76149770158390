var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listVm,"item-key":"id","single-line":"","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalListVm,"loading":_vm.isLoadingTable,"hide-default-footer":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex pr-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.Status == 'POWERED_OFF' ? 'error' : 'primary',"size":"30"}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Status == "POWERED_OFF" ? "Shutoff" : "Active"))])])],1),_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.rowList(item)}}},[_vm._v(_vm._s(item.Name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.GuestOS))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.Cpus)+" vCPU/ "+_vm._s(_vm.formatSize(item.MemoryMB))+"/ "+_vm._s(_vm.formatSize(item.TotalStorageAllocatedMb))+" ")])])],1)]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.DateCreated ? _vm.$FormatDate(item.DateCreated) : "-")+" ")]}},{key:"item.IpAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.IpAddress || "-")+" ")]}},{key:"item.NetworkName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.NetworkName || "-")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + _vm.getId(item.HREF),"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                  on.click($event);
                  _vm.show($event, item);
                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                  rotate: _vm.getId(item.HREF) == _vm.activemenuinstance && _vm.popo,
                },attrs:{"id":'popup' + _vm.getId(item.HREF),"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold",attrs:{"width":"145"}},[_c('v-list-item',{attrs:{"disabled":item.Status == 'POWERED_OFF'},on:{"click":function($event){return _vm.openWindow(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Access Console")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status == 'POWERED_ON'},on:{"click":function($event){return _vm.onPower(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Power On")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status == 'POWERED_OFF'},on:{"click":function($event){return _vm.offPower(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Power Off")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status == 'POWERED_ON'},on:{"click":function($event){return _vm.onForce(item)}}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Power On, Force Recustomization")])]}}],null,true)},[_c('span',[_vm._v("Power On, Force Recustomization")])])],1)],1)],1)],1)]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = val;
              _vm.changePage(val);
            }}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }