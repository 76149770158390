var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('v-row',{},[_c('v-col',{staticClass:"d-flex py-0"},[_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"my-3",attrs:{"headers":_vm.headerNG,"hide-default-footer":"","items":_vm.natGateways,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.totalRowsNG},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoadingNat)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[(_vm.interfaces.length)?_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any NAT ")]):_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px"}},[_vm._v(" You must be add a Floating IP ")])])]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.type == 'many' ? 'DNAT' : 'SNAT')+" ")])]}},{key:"item.translation_address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.translation_name)+" - "+_vm._s(item.translation_address))])]}},{key:"item.protocol",fn:function(ref){
var item = ref.item;
return [(item.type == 'many')?_c('span',[_vm._v(" "+_vm._s(_vm.uppercaseWord(item.protocol))+" / "+_vm._s(item.destination_port))]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',{staticClass:"black--text",class:{
            'success--text': item.status == 'CREATED',
            'error--text' : item.status == 'FAILED',
            'grey--text' : item.status == 'ON-PROGRESS' 
            }},[_vm._v(_vm._s(item.status))]):_c('span',[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.status != 'ON-PROGRESS')?_c('span',{staticClass:"pl-3 error--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
              _vm.selectedItem = item
              _vm.dialogDelete = true
              _vm.headerName = 'nat'
            }}},[_vm._v(" Delete ")]):_c('span',{staticClass:"grey--text"},[_vm._v(" Delete ")])]}},(_vm.natGateways.length)?{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) { return _vm.options = val; }}})]}}:null],null,true)})],1)],1),(_vm.dialogAdd == true)?_c('dialog-add-nat',{on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.dialogAdd),callback:function ($$v) {_vm.dialogAdd=$$v},expression:"dialogAdd"}}):_vm._e(),(_vm.dialogDelete == true)?_c('dialog-delete',{attrs:{"itemSelected":_vm.selectedItem,"header":_vm.headerName},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }