var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"DataTableListObjectStorage","hide-default-footer":"","headers":_vm.headers,"items":_vm.objectStorages,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-circle")]),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.objectstoragename))])]),_c('p',[_vm._v("https://test-object-storage-lintasarta.cloud.lintasarta.com")])],1)],1)]}},{key:"item.size",fn:function(ref){return [_c('p',[_vm._v("10 GB")]),_c('p',[_vm._v("5 items")])]}},{key:"item.createddate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.createddate)).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                on.click($event);
                _vm.show($event, item);
              }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                rotate: item.open,
              },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.validateprivilagesync(['Object Storage', 'editor']),"to":'/object-storage/' + _vm.objectStorageSelected.id + '/files',"append":""}},[_c('v-list-item-title',[_vm._v("Show Files")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.validateprivilagesync(['Object Storage', 'editor']),"to":'/object-storage/' + _vm.objectStorageSelected.id + '/settings',"append":""}},[_c('v-list-item-title',[_vm._v("Settings")])],1),_c('v-list-item',{staticStyle:{"border-bottom":"1px solid lightgrey"},attrs:{"disabled":!_vm.validateprivilagesync(['Object Storage', 'editor'])},on:{"click":function () {
                _vm.dialogMoveto = true;
              }}},[_c('v-list-item-title',[_vm._v("Move to...")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.validateprivilagesync(['Object Storage', 'editor'])},on:{"click":function () {
                _vm.dialogDelete = true;
              }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":"","props":props}})]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-7"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Deka Box ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"text-center",staticStyle:{"color":"#a5b3bf"},attrs:{"cols":"12","lg":"10","md":"12"}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Create unlimited deka box service that lets you store and serve large amounts of data. Each Space is a bucket for you to store and serve files. The free, built-in Spaces CDN minimizes page load times, improves performance, and reduces bandwidth and infrastructure costs. ")])])],1),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary mx-auto",attrs:{"depressed":"","height":"45","width":"300","to":"/object-storage/create"}},[_vm._v("Create Object Storage")]):_vm._e()],1)]},proxy:true}])}),_c('v-menu',{attrs:{"position-x":_vm.x - 10,"position-y":_vm.y,"absolute":"","allow-overflow":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}}),_c('dialogDeleteObjectStorage',{on:{"close":function () {
        _vm.dialogDelete = false;
      },"confirm":function () {
        _vm.deleteSelected();
      }},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}}),_c('dialogMovetoProject',{on:{"close":function () {
        _vm.dialogMoveto = false;
      }},model:{value:(_vm.dialogMoveto),callback:function ($$v) {_vm.dialogMoveto=$$v},expression:"dialogMoveto"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }