var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"max-width":"1000"}},[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("List of Voucher Code")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.voucherType === _vm.VoucherCreditType.ONE_TIME ? _vm.headersOneTime : _vm.headersRecurring,"items":_vm.voucherCreditDetails,"items-per-page":_vm.voucherCreditDetailOptions.itemPerPage,"server-items-length":_vm.voucherCreditDetailPagination.count,"options":_vm.voucherCreditDetailOptions},on:{"update:options":function($event){_vm.voucherCreditDetailOptions=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_name ? item.project_name : '-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? item.status : '-')+" ")]}},{key:"item.claimed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.claimed_at ? _vm.moment(item.claimed_at).format('D MMMM Y') : '-')+" ")]}},{key:"item.next_disbursement_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.next_disbursement_date ? _vm.moment(item.next_disbursement_date).format('D MMMM Y') : '-')+" ")]}},(_vm.voucherType === _vm.VoucherCreditType.RECURRING)?{key:"item.recurring_expired_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recurring_expired_date ? _vm.moment(item.recurring_expired_date).format('D MMMM Y') : '-')+" ")]}}:null,{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.claimed_at)?_c('v-btn',{attrs:{"color":"primary","width":"110","disabled":_vm.read_only},on:{"click":function () {
            _vm.selectedVoucher = item;
            _vm.openDialogSelectProject = true;
          }}},[_vm._v(" Claim To Project ")]):(_vm.voucherType === _vm.VoucherCreditType.RECURRING && item.status === _vm.VoucherCreditDetailStatus.AVAILABLE)?_c('v-btn',{attrs:{"color":"error","width":"110","disabled":_vm.read_only},on:{"click":function () {
              _vm.selectedVoucher = item;
              _vm.openDialogDisableVoucherCreditDetail = true;
            }}},[_vm._v(" Disable Voucher ")]):(_vm.voucherType === _vm.VoucherCreditType.RECURRING && item.status === _vm.VoucherCreditDetailStatus.UNAVAILABLE)?_c('v-btn',{attrs:{"color":"success","width":"110","disabled":_vm.read_only},on:{"click":function () {
              _vm.selectedVoucher = item;
              _vm.openDialogEnableVoucherCreditDetail = true;
            }}},[_vm._v(" Enable Voucher ")]):_vm._e(),(item.status !== _vm.VoucherCreditDetailStatus.CREATED)?_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":(item.status !== _vm.VoucherCreditDetailStatus.AVAILABLE && item.status !== _vm.VoucherCreditDetailStatus.UNAVAILABLE) || _vm.read_only,"color":"error","width":"110"},on:{"click":function () {
            _vm.selectedVoucher = item;
            _vm.openDialogRevokeVoucherCreditDetail = true;
          }}},[_vm._v(" Revoke ")]):_vm._e()]}},{key:"footer",fn:function(ref){
          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                _vm.voucherCreditDetailOptions = Object.assign({}, _vm.voucherCreditDetailOptions, val);
              }}})]}}],null,true)})],1),_c('v-card-text',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Close")])])],1)],1)],1)],1),(_vm.openDialogEnableVoucherCreditDetail)?_c('dialogEnableVoucherCreditDetail',{attrs:{"selectedVoucher":_vm.selectedVoucher},on:{"confirm":_vm.doEnableVoucherCreditDetail},model:{value:(_vm.openDialogEnableVoucherCreditDetail),callback:function ($$v) {_vm.openDialogEnableVoucherCreditDetail=$$v},expression:"openDialogEnableVoucherCreditDetail"}}):_vm._e(),(_vm.openDialogDisableVoucherCreditDetail)?_c('dialogDisableVoucherCreditDetail',{attrs:{"selectedVoucher":_vm.selectedVoucher},on:{"confirm":_vm.doDisableVoucherCreditDetail},model:{value:(_vm.openDialogDisableVoucherCreditDetail),callback:function ($$v) {_vm.openDialogDisableVoucherCreditDetail=$$v},expression:"openDialogDisableVoucherCreditDetail"}}):_vm._e(),(_vm.openDialogRevokeVoucherCreditDetail)?_c('dialogRevokeVoucherCreditDetail',{attrs:{"selectedVoucher":_vm.selectedVoucher},on:{"confirm":_vm.doRevokeVoucherCreditDetail},model:{value:(_vm.openDialogRevokeVoucherCreditDetail),callback:function ($$v) {_vm.openDialogRevokeVoucherCreditDetail=$$v},expression:"openDialogRevokeVoucherCreditDetail"}}):_vm._e(),(_vm.openDialogSelectProject)?_c('dialogSelectProject',{on:{"setProject":_vm.doClaimVoucherCredit},model:{value:(_vm.openDialogSelectProject),callback:function ($$v) {_vm.openDialogSelectProject=$$v},expression:"openDialogSelectProject"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }