var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg mt-3 pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-between pr-4"},[_c('v-card-text',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Config E-Materai ")]),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"create-e-materai","depressed":"","height":"50"},on:{"click":function($event){_vm.modal.add = true}}},[_vm._v(" Create New E-Materai ")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","hide-default-header":"","headers":_vm.headers,"items-per-page":10,"items":_vm.getStamps,"server-items-length":_vm.pagination.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.no_materai",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stamp_code)+" ")]}},{key:"item.expired_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expired_at)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"disabled":_vm.read_only,"true-value":1,"false-value":0,"readonly":"","inset":"","flat":"","hide-details":""},on:{"click":function($event){if(_vm.read_only){
              return
            }
            _vm.materai = item;
            _vm.modal.changeStatus = true;}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"error--text pr-3 delete ml-4",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function($event){if(_vm.read_only){
              return
            }
            _vm.materai = item;
            _vm.modal.delete = true;}}},[_vm._v("Delete")]),_c('span',{staticClass:"primary--text pr-3 delete ml-4",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function($event){if(_vm.read_only){
              return
            }
            _vm.materai = item;
            _vm.modal.edit = true;}}},[_vm._v("Edit")])]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}],null,true)})],1),(_vm.modal.add)?_c('dialog-create-materai',{on:{"close":function($event){_vm.modal.add = false},"success":function () {
        _vm.fetchStamps(_vm.options).then(function () {
          _vm.modal.add = false;
        });
      }},model:{value:(_vm.modal.add),callback:function ($$v) {_vm.$set(_vm.modal, "add", $$v)},expression:"modal.add"}}):_vm._e(),(_vm.modal.edit && _vm.materai)?_c('dialog-edit-materai',{attrs:{"item":_vm.materai},on:{"close":function($event){_vm.modal.edit = false},"success":function () {
        _vm.fetchStamps(_vm.options).then(function () {
          _vm.modal.edit = false;
        });
      }},model:{value:(_vm.modal.edit),callback:function ($$v) {_vm.$set(_vm.modal, "edit", $$v)},expression:"modal.edit"}}):_vm._e(),(_vm.modal.delete && _vm.materai)?_c('dialog-delete-materai',{attrs:{"item":_vm.materai},on:{"close":function($event){_vm.modal.delete = false},"success":function () {
        _vm.fetchStamps(_vm.options).then(function () {
          _vm.modal.delete = false;
        });
      }},model:{value:(_vm.modal.delete),callback:function ($$v) {_vm.$set(_vm.modal, "delete", $$v)},expression:"modal.delete"}}):_vm._e(),(_vm.modal.changeStatus && _vm.materai)?_c('dialog-change-status-materai',{attrs:{"item":_vm.materai},on:{"close":function($event){_vm.modal.changeStatus = false},"confirm":_vm.changeStatusMaterai},model:{value:(_vm.modal.changeStatus),callback:function ($$v) {_vm.$set(_vm.modal, "changeStatus", $$v)},expression:"modal.changeStatus"}}):_vm._e(),(_vm.modal.warningChangeStatus)?_c('dialog-warning-change-status-materai',{on:{"close":function($event){_vm.modal.warningChangeStatus = false}},model:{value:(_vm.modal.warningChangeStatus),callback:function ($$v) {_vm.$set(_vm.modal, "warningChangeStatus", $$v)},expression:"modal.warningChangeStatus"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }