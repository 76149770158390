var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 pt-0",attrs:{"fluid":""}},[_c('br'),_c('div',{staticClass:"bor-table mb-5"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',[_vm._v("VM Storage Policy")]),_c('v-col',[_vm._v(_vm._s(_vm.detailVm.StorageProfile.Name))])],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-9",attrs:{"headers":_vm.headers,"items":_vm.detailVm.VmSpecSection.DiskSection.DiskSettings,"item-key":"id","single-line":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Iops",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.Iops))])])]}},{key:"item.BusNumber",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.BusNumber))])])]}},{key:"item.UnitNumber",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.UnitNumber))])])]}},{key:"item.SizeMb",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.formatSize(item.SizeMb)))])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }