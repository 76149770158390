var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[(_vm.isLoading || _vm.getListPfsense.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.getListPfsense,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.getTotalRowsPfsense,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading ? "Loading.." : "No data available")+" ")]},proxy:true},{key:"item.ip_public_pfsense",fn:function(ref){
var item = ref.item;
return [(item.ip_public_pfsense)?_c('span',[_vm._v(_vm._s(item.ip_public_pfsense))]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text",class:{
                'success--text': item.status == 'CREATED',
                'error--text' : item.status == 'FAILED',
                'grey--text' : item.status == 'ON-PROGRESS' 
                }},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.status == 'ON-PROGRESS')?_c('label',[_vm._v(" More "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                          on.click($event);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                          rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(item.status == 'ON-PROGRESS' || item.status == 'FAILED')?_c('v-list-item',{staticClass:"mr-4"},[_c('v-list-item-title',{staticStyle:{"color":"rgba(0, 0, 0, 0.38)"}},[_vm._v("Configuration")])],1):_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toConfigure(item.id, item)}}},[_c('v-list-item-title',{staticClass:"mr-4 edit"},[_vm._v(" Configuration")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),(item.status == 'ON-PROGRESS')?_c('v-list-item',{staticClass:"grey--text"},[_c('v-list-item-title',[_vm._v("Delete")])],1):_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                        _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                          _vm.selectedPfsense = item
                          _vm.dialogDeletePfsense = true
                        });
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [(_vm.getListPfsense.length > 5)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.options = val;
                }}}):_vm._e()]}}],null,false,3412482194)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"outlined":"","flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any VPN ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}})])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/network/vpn/create"}},[_vm._v(" Create VPN ")])],1)],1)],1)],1)],1)],1),(_vm.dialogDeletePfsense == true)?_c('dialog-delete-pfsense',{attrs:{"pfsense":_vm.selectedPfsense,"textDelete":_vm.textDelete},on:{"deleteText":function($event){_vm.textDelete = $event},"delete":_vm.doDelete},model:{value:(_vm.dialogDeletePfsense),callback:function ($$v) {_vm.dialogDeletePfsense=$$v},expression:"dialogDeletePfsense"}}):_vm._e(),(_vm.opendialogdetail == true)?_c('dialogDetail',{attrs:{"dataValue":_vm.selectedItem,"headerName":_vm.headerName},model:{value:(_vm.opendialogdetail),callback:function ($$v) {_vm.opendialogdetail=$$v},expression:"opendialogdetail"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }