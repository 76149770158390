import { render, staticRenderFns } from "./activity.vue?vue&type=template&id=37b5dc1c&scoped=true"
import script from "./activity.vue?vue&type=script&lang=js"
export * from "./activity.vue?vue&type=script&lang=js"
import style0 from "./activity.vue?vue&type=style&index=0&id=37b5dc1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b5dc1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VCardText,VCol,VDataTable,VRow,VSelect})
