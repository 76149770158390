var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listLogs,"item-key":"no","hide-default-footer":"","items-per-page":10,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.totalLogs.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.op_time",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.op_time)))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
                _vm.changePage(val);
              }}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }