import { render, staticRenderFns } from "./worker.vue?vue&type=template&id=4b4bc806&scoped=true"
import script from "./worker.vue?vue&type=script&lang=js"
export * from "./worker.vue?vue&type=script&lang=js"
import style0 from "./worker.vue?vue&type=style&index=0&id=4b4bc806&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b4bc806",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VCol,VContainer,VDataTable,VProgressCircular,VRow})
