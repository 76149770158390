var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-7"},[_c('div',{staticClass:"table-title"},[_vm._v("Instance")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Instance")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("VCPUs")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("RAM")])])],1),_c('div',{staticClass:"table-title"},[_vm._v("Storage")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Storage")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Snapshots")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Volume Storage")])])],1),_c('div',{staticClass:"table-title"},[_vm._v("Network")]),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Floating IP")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Security Groups")])]),_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"2"}},[_c('div',{staticStyle:{"width":"80px"}},[_c('pie',{attrs:{"data":{
            datasets: [
              {
                data: [10, 90],
                backgroundColor: ['#2C94D2', '#C4C4C4'],
                borderWidth: 0,
              } ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['used', 'remaining'],
          },"options":{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
          }}})],1),_c('div',{staticClass:"usage-title pt-4"},[_vm._v("Networks")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }