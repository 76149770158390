var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('h6',{staticClass:"text-h6 font-weight-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.titleCase(_vm.labelServiceName))+" ")]),_c('v-data-table',{staticClass:"mt-8",attrs:{"hide-default-footer":"","headers":_vm.tblHeaders,"items-per-page":10,"items":_vm.item},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || "N/A")+" ")]}},{key:"item.specification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.specification)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.toUpperCase())+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.price.value))+" "),_c('span',[_vm._v("/ "+_vm._s(item.price.unit))])]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usage)+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount ? ((item.discount) + "%") : '-')+" ")]}},{key:"item.discount_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.discount_value))+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")]}},(_vm.project.postpaid_type !== 'fixed')?{key:"body.append",fn:function(){return [(_vm.item.length)?_c('tr',{staticStyle:{"border":"none"}},[_vm._l((_vm.tblHeaders.length - 2),function(number){return _c('td',{key:'total-' + number,staticClass:"border-bottom-none"})}),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" Total ")])]),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.total))+" ")])])],2):_vm._e()]},proxy:true}:null,(_vm.item.length)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}}),_c('v-btn',{staticClass:"secondary mt-3",attrs:{"depressed":"","height":"45","block":"","color":"accent"},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[_vm._v(" Close ")])]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }