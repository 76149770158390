var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v("Frontend")]),_c('v-row',{staticClass:"graph"},[_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#F2994A","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("HTTP Request per Second")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"httpchart",attrs:{"data":_vm.data,"options":_vm.options,"id":"httpchart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#2C94D2","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("Connection")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"connectionchart",attrs:{"data":_vm.data2,"options":_vm.options2,"id":"connectionchart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#219653","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("HTTP Responses")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"httpresponsechart",attrs:{"data":_vm.data3,"options":_vm.options3,"id":"httpresponsechart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#F2C94C","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("Traffic Received/Sent")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"traffictchart",attrs:{"data":_vm.data4,"options":_vm.options4,"id":"traffictchart","height":null}})],1)])],1),_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v("Instance")]),_c('v-row',{staticClass:"graph"},[_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#1F60A8","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("HTTP Total Session Duration")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"httpsessionchart",attrs:{"data":_vm.data5,"options":_vm.options5,"id":"httpsessionchart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#9B51E0","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("HTTP Average Response Time")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"averageresponsechart",attrs:{"data":_vm.data6,"options":_vm.options6,"id":"averageresponsechart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#EB5757","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("Queue Size")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"queuesizechart",attrs:{"data":_vm.data7,"options":_vm.options3,"id":"queuesizechart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#27AE60","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("HTTP Responses")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"instancehttpresponsechart",attrs:{"data":_vm.data8,"options":_vm.options8,"id":"instancehttpresponsechart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#F2994A","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("Downtime")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"downtimechart",attrs:{"data":_vm.data9,"options":_vm.options9,"id":"downtimechart","height":null}})],1)]),_c('v-col',{staticClass:"px-5",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3 d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#2C94D2","stroke-width":"4"}})])]),_c('p',{staticClass:"mb-0 fw-600"},[_vm._v("Health Checks")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.httprequest.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.httprequest.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.httprequest),callback:function ($$v) {_vm.httprequest=$$v},expression:"httprequest"}})],1)],1),_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-25px","margin-right":"-20px"}},[_c('custline',{ref:"healthcheckchart",attrs:{"data":_vm.data10,"options":_vm.options10,"id":"healthcheckchart","height":null}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }