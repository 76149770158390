var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"font-weight-bold fz-21 mt-4"},[_vm._v("Storage Detail")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.tblHeaders,"items":_vm.tblItems,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(item.name || "N/A"))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.price.value))+" / "+_vm._s(item.price.per)+" ")]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [(item.usage === '')?_c('div',[_vm._v("-")]):_c('div',[_vm._v(_vm._s(item.usage))])]}},{key:"item.discount_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_value ? _vm.toIDRWithDotFormat(item.discount_value) : '-')+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [(item.deleted_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.deleted_at).format("DD/MM/YYYY HH:mm:ss"))+" ")]):_c('div',[_vm._v("-")])]}},(!_vm.isPostpaidFixed)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_vm._l((_vm.tblHeaders.length - 1),function(number){return _c('td',{key:'storage-total-' + number,staticClass:"border-bottom-none"})}),_c('td',{staticClass:"border-bottom-none"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Total")])]),_c('div',{staticClass:"d-flex flex-row justify-start"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat( _vm.tblItems.length ? _vm.tblItems.reduce(function (total, item) { return total + item.billed; }, 0) : 0 ))+" ")])])],2)]},proxy:true}:null,(_vm.tblItems.length > 10)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }