var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title mt-2"},[_vm._v("Deka Harbor")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[(_vm.getListCLuster.length)?_c('v-btn',{staticClass:"secondary",staticStyle:{"height":"45px","width":"150px","font-size":"12px"},attrs:{"depressed":""},on:{"click":function () {
                    _vm.validateprivilages(['Deka Harbor', 'editor', true]).then(function () {
                      _vm.to_create()
                    });
                  }}},[_vm._v("Create Cluster")]):_vm._e()],1)],1),_c('br'),(_vm.getListCLuster.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersCluster,"options":_vm.options,"items":_vm.getListCLuster,"items-per-page":10,"server-items-length":_vm.getTRCluster,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.master",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.filterNameFlavor(item.flavor_master) + ' / ' + item.total_master +' node')+" ")])]}},{key:"item.worker",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s((item.total_worker+item.total_master) +' nodes')+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"black--text",class:{
                'success--text': item.status == 'CREATED',
                'error--text' : item.status == 'FAILED',
                'grey--text' : item.status == 'ON-PROGRESS' 
                }},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.status == 'ON-PROGRESS')?_c('label',{staticClass:"grey--text"},[_vm._v(" More "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                          on.click($event);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                          rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(item.status == 'CREATED')?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                      _vm.validateprivilages(['Deka Harbor', 'editor', true]).then(function () {
                        _vm.to_link(item.dashboard_url)
                      })
                    }}},[_c('v-list-item-title',{staticClass:"mr-4 edit"},[_vm._v("Kubernetes Dashboard")])],1):_c('v-list-item',[_c('v-list-item-title',{staticClass:"mr-4",staticStyle:{"color":"rgb(0 0 0 / 38%)"}},[_vm._v("Kubernetes Dashboard")])],1),(item.status == 'CREATED')?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                        _vm.toDetail(item)
                      }}},[_c('v-list-item-title',{},[_vm._v("Detail")])],1):_c('v-list-item',[_c('v-list-item-title',{staticClass:"mr-4",staticStyle:{"color":"rgb(0 0 0 / 38%)"}},[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                        _vm.validateprivilages(['Deka Harbor', 'editor', true]).then(function () {
                          _vm.selectedItem = item
                          _vm.openDialogDelete = true
                        });                         
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = val ;
            }}})]}}],null,false,3365048319)}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Deka Harbor Cluster ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}})])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":""},on:{"click":function () {
                          _vm.validateprivilages(['Deka Harbor', 'editor', true]).then(function () {
                            _vm.to_create()
                          });
                        }}},[_vm._v(" Create Cluster ")])],1)],1)],1)],1)],1)],1),(_vm.openDialogCreateWorker)?_c('create-worker',{attrs:{"selectedItem":_vm.itemselected,"header":_vm.headerDialog},model:{value:(_vm.openDialogCreateWorker),callback:function ($$v) {_vm.openDialogCreateWorker=$$v},expression:"openDialogCreateWorker"}}):_vm._e(),(_vm.openDialogDelete)?_c('dialog-delete',{attrs:{"itemSelected":_vm.selectedItem,"header":'Harbor'},on:{"refreshTable":_vm.refreshTable},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }