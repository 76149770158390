var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.projectID === 'all-project')?_c('Summary'):_c('v-row',[(_vm.project)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"project-name"},[_vm._v(" "+_vm._s(_vm.project.name || "")+" ")])]),_c('v-col',{staticClass:"d-flex mb-2 pt-0"},[_c('div',[_c('p',{staticClass:"font-weight-bold fz-21 mb-4 mr-4"},[_vm._v(" Cost for "+_vm._s(_vm.moment(_vm.selectedDate).format("DD MMMM YYYY"))+"* ")]),_c('p',[_vm._v(" *This is the current cost for your usage this billing period. A breakdown of your cost is available below. ")])]),_c('v-spacer'),(!_vm.isFullcycle)?_c('div',{staticClass:"summary-billed"},[_c('b',{staticClass:"font--text"},[_vm._v("Billed")]),_c('p',{staticClass:"total-billed"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.totalBilled))+" ")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"total primary--text"})])],1),_c('br'),_c('div',[_c('p',{staticClass:"font-weight-bold fz-21"},[_vm._v("Service Detail")]),_c('v-data-table',{staticClass:"datatable-services",attrs:{"headers":_vm.postpaidFixed ? _vm.serviceHeadersFixed: _vm.serviceHeaders,"items":_vm.services,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(" "+_vm._s(item.service_name ? ((item.service_type_name) + ": " + (item.service_name)) : ("" + (item.service_type_name)))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.toIDRWithDotFormat(item.price)) + " / " + (item.price_unit)))+" ")]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [(item.usage)?_c('div',[_c('span',[_vm._v(_vm._s(item.usage))])]):(_vm.paymentPostpaid)?_c('div',[(item.usage_days)?_c('span',[_vm._v(_vm._s(item.usage_days > 1 ? ((item.usage_days) + " days") : ((item.usage_days) + " day")))]):_c('span',[_vm._v("-")])]):_c('div',[(item.usage_hours)?_c('span',[_vm._v(_vm._s(item.usage_hours > 1 ? ((item.usage_hours) + " hours") : ((item.usage_hours) + " hour")))]):_c('span',[_vm._v("-")])])]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount ? ((item.discount) + "%") : '-')+" ")]}},{key:"item.discount_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_value ? _vm.toIDRWithDotFormat(item.discount_value) : '-')+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")]}},(!_vm.postpaidFixed)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_vm._l((_vm.serviceHeaders.length - 2),function(number){return _c('td',{key:'total-' + number,staticClass:"border-bottom-none"})}),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" Total ")])]),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.totalBilled))+" ")])])],2)]},proxy:true}:null],null,true)})],1)],1)],1)],1):_vm._e()],1),_c('billingdetail')],1)}
var staticRenderFns = []

export { render, staticRenderFns }