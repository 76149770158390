var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Instance")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":5,"headers":_vm.headers,"items":_vm.instances,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer d-flex",on:{"click":function ($event) {
                          on.click($event);
                          _vm.rotateArrow(item.id);
                        }}},'label',attrs,false),[_vm._v(" More "),_c('v-icon',{class:{
                          rotate:
                            item.id === _vm.rotateArrowId &&
                            attrs['aria-expanded'] === 'true',
                        },attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'/instance/' + item.id}},[_c('v-list-item-title',[_vm._v(" Detail ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"error--text",on:{"click":function () {
                            _vm.selectedInstance = item;
                            _vm.openDialogDeleteInstance = true;
                          }}},[_vm._v(" Delete ")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [(
                    (_vm.pagination && _vm.pagination.count > 5) ||
                      (_vm.pagination && _vm.pagination.count === 5)
                  )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                      _vm.pagination = Object.assign({}, _vm.pagination, val);
                    }}}):_vm._e()]}}])})],1)],1)],1)],1)],1),(_vm.openDialogDeleteInstance && _vm.selectedInstance)?_c('dialogDeleteAutoScaling',{attrs:{"item":_vm.selectedInstance,"loading":_vm.loading},on:{"cancel":function($event){_vm.openDialogDeleteInstance = false},"submit":_vm.deleteInstance},model:{value:(_vm.openDialogDeleteInstance),callback:function ($$v) {_vm.openDialogDeleteInstance=$$v},expression:"openDialogDeleteInstance"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }