var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-0 py-5",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Network")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 mb-30px",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.instanceDetail.ins_vpc_port},scopedSlots:_vm._u([{key:"item.internal",fn:function(ref){
var item = ref.item;
return [(item.internal && item.internal.length)?_c('span',_vm._l((item.internal),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span',[_vm._v("-")])]}},{key:"item.mac_address",fn:function(ref){
var item = ref.item;
return [(item.mac_address && item.mac_address.length)?_c('span',_vm._l((item.mac_address),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span',[_vm._v("-")])]}},{key:"item.external",fn:function(ref){
var item = ref.item;
return [(item.external && item.external.length)?_c('span',_vm._l((item.external),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span',[_vm._v("-")])]}},{key:"item.dns",fn:function(ref){
var item = ref.item;
return [(item.dns && item.dns.length)?_c('v-col',[_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function () {
                      _vm.openDialog = true;
                      _vm.dataDns = item.dns;
                    }}},[_vm._v(" See all DNS ")])]):_c('v-col',[_vm._v("-")])]}}])})],1)],1)],1)],1),(_vm.openFloatingIPDialog)?_c('dialogFloatingIP',{attrs:{"item":_vm.instanceDetail,"id-instance":_vm.idInstance},model:{value:(_vm.openFloatingIPDialog),callback:function ($$v) {_vm.openFloatingIPDialog=$$v},expression:"openFloatingIPDialog"}}):_vm._e(),_c('dialogDns',{attrs:{"dialog-open":_vm.openDialog,"data-dns":_vm.dataDns},on:{"update:dialogOpen":function($event){_vm.openDialog=$event},"update:dialog-open":function($event){_vm.openDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }