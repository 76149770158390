var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Zone")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewZone","depressed":"","height":"50","width":"150"},on:{"click":function () {
              _vm.selectedZone = null;
              _vm.openDialogAdd = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Zone")])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"table-zone",attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"items-per-page":_vm.zonePaginator.limit,"search":_vm.search,"items":_vm.zoneByRegion,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                      on.click($event);
                      _vm.show($event, item);
                    }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                      rotate: item.open,
                    },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                      _vm.selectedZone = item;
                      _vm.openDialogAdd = true;
                    }}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                      _vm.selectedZone = item;
                      _vm.openDialogDetail = true;
                    }}},[_c('v-list-item-title',[_vm._v("Detail")])],1):_vm._e(),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                      _vm.selectedZone = item;
                      _vm.openDialogDelete = true;
                    }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.options = Object.assign({}, _vm.options, val);
                }}})]}}])})],1)],1)],1),_c('dialogAdd',{attrs:{"selectedZone":_vm.selectedZone},on:{"create":function (payload) { return _vm.doCreate(payload); },"update":function (payload) { return _vm.doUpdate(payload); }},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}),(_vm.openDialogDetail)?_c('dialogDetail',{attrs:{"selectedZone":_vm.selectedZone},model:{value:(_vm.openDialogDetail),callback:function ($$v) {_vm.openDialogDetail=$$v},expression:"openDialogDetail"}}):_vm._e(),_c('dialogDelete',{attrs:{"selectedZone":_vm.selectedZone},on:{"delete":function (payload) { return _vm.doDelete(payload); }},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}),_c('dialogActivateZone',{attrs:{"zone":"zone","onoff":_vm.zoneselectedvalue.status == 'active' ? 'off' : 'on'},on:{"confirm":function () {
        _vm.changestatus(_vm.zoneselectedvalue).then(function () {
          _vm.opendialogactivatezone = false;
          _vm.zoneselectedvalue.status = _vm.zoneselectedvalue.status == 'active' ? 'deactive' : 'active';
        });
      }},model:{value:(_vm.opendialogactivatezone),callback:function ($$v) {_vm.opendialogactivatezone=$$v},expression:"opendialogactivatezone"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }