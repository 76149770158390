var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.billingDetail && _vm.billing)?_c('v-row',{staticClass:"mb-3"},[_c('WhiteboxAbove',{attrs:{"detail":_vm.billingDetail,"billing":_vm.billing}})],1):_vm._e(),(_vm.$route.params.id !== 'all-project')?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" History Billing ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.historyHeaders,"items":_vm.projectBilling && _vm.projectBilling.length ? _vm.projectBilling : [],"items-per-page":10},scopedSlots:_vm._u([{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(Math.round(item.billed)))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/billing/monthly-cost/" + _vm.projectID + "/history/" + (_vm.$moment(
                    item.date
                  ).format('YYYY-MM-DD')))}},[_vm._v(" Detail ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-8"},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v(" Looks like you don’t have any History Billing ")]),_c('p',{staticClass:"accent-text font-weight-light fz-12"},[_vm._v(" Build and release faster with scalable instance products in the cloud. Provides flexible server configurations sized for any application, industry leading price to performance, and predictable pricing that is the same across regions and usage volumes. ")])])]},proxy:true},(_vm.projectBilling.length)?{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)],1),(
        _vm.isDevModePrepaid &&
        _vm.billing &&
        // billing.project will not exist in summary
        (!_vm.billing.project ||
          // billing.project will exist in project
          (_vm.billing.project &&
            _vm.billing.project.payment_method !== 'postpaid' &&
            _vm.billing.project.postpaid_type !== 'usage'))
      )?_c('HistoryDebit',{attrs:{"billing":_vm.billing}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }