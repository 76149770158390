var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Destroy Firewall ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("This is irreversible. Your Firewall will be destroyed, and any Instance will be dissociated from them.")]),_c('p',[_vm._v("Once this happens, the Instance will allow any type of inbound and outbound traffic, unless you've configured a software firewall in them.")]),_c('p',[_vm._v("Do you want to permanently destroy this?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function () {
                _vm.dialog = false
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {
                _vm.Delete().then(function (){
                _vm.$emit('deleted')
                _vm.dialog = false
                })
              }}},[_vm._v(" Destroy ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }