var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-3"},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-0 rounded-t-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7 pb-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"page-title mt-2"},[_vm._v("Deka ROCK")]),_c('v-spacer'),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary fz-14",attrs:{"width":"150","height":"50","depressed":""},on:{"click":function () {
                      _vm.validateprivilages(['Dekarock', 'editor', true]).then(function () {
                        _vm.toCreate();
                      });
                    }}},[_vm._v(" Create Deka ROCK ")]):_vm._e()],1)],1)],1)],1),_c('v-card',{staticClass:"pa-7 rounded-b-lg pt-2",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items":_vm.openshift_list,"server-items-length":_vm.pagination.itemsLength},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var props = ref.props;
                    var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(item.status != 'Error')?_c('span',[_vm._v(_vm._s(item.status))]):_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}},{key:"item.version",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.version ? item.version : "-")+" ")]}},{key:"item.zone",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.zone ? item.zone : "-")+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                        on.click($event);
                        _vm.itemSelectedvalue = item;
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                        rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{on:{"click":function () {
                        _vm.toDetail(item);
                      }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),(!item.default && !_vm.read_only)?_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1):_vm._e(),(!item.default && !_vm.read_only)?_c('v-list-item',{attrs:{"disabled":(item.status != 'Created' && item.status != 'Error') || !item.actionable},on:{"click":function () {
                        _vm.validateprivilages(['Dekarock', 'editor', true]).then(function () {
                          _vm.dialog_delete = true;
                        });
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Destroy")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('v-row',[_c('v-col',[_c('v-row',[_c('v-card-title',[_c('span',{staticClass:"mt-1 fz-14"},[_vm._v("Powered by "),_c('b',[_vm._v(" OpenShift")])]),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/openshift.png"),"alt":"openshift","width":"30px"}})])],1)],1),_c('v-col',[(_vm.openshift_list.length)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.options = val;
                        _vm.changePage(val);
                      }}}):_vm._e()],1)],1)]}}])},[_vm._v(" = ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v("Destroy this Deka ROCK ?")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("All your data in this server will be deleted without backup. Are you sure?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {
                  _vm.doDeleteDekarock();
                }}},[_vm._v(" Yes ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }