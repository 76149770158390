var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"noborder"},[_c('td',{staticClass:"pl-0 py-0",attrs:{"colspan":"2"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"placeholder":_vm.$v.rule.server_name.$errors.length ? '' : 'Server Name',"append-icon":"mdi-chevron-down","items":_vm.instances,"item-value":function (val) {
          if (val.instanceName) {
            return val.instanceName 
          } else {
            return val.value
          }
        },"item-text":function (val) {
          if (val.instanceName) {
            return val.instanceName + ' - ' + val.ipv4
          } else {
            return val.text
          }
        },"return-object":"","outlined":"","clearable":"","single-line":"","error-messages":_vm.$v.rule.server_name.$errors.length?_vm.$v.rule.server_name.$errors[0].$message:''},on:{"change":function () {
          _vm.$emit('serverChanges', {name: _vm.rule.server_name, index: _vm.index})
          _vm.$v.rule.server_name.$touch()
        }},model:{value:(_vm.rule.server_name),callback:function ($$v) {_vm.$set(_vm.rule, "server_name", $$v)},expression:"rule.server_name"}})],1)],1),(_vm.rule.server_name == 'other')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$v.rule.server_name_custom.$errors.length ? '' : 'Server Name',"outlined":"","single-line":"","error-messages":_vm.$v.rule.server_name_custom.$errors.length?_vm.$v.rule.server_name_custom.$errors[0].$message:''},on:{"input":function (val) { return (_vm.rule.server_name_custom = val.toLowerCase()); },"change":function () {
              _vm.$emit('serverChanges', {customName: _vm.rule.server_name_custom, index: _vm.index})
              _vm.$v.rule.server_name_custom.$touch()
            }},model:{value:(_vm.rule.server_name_custom),callback:function ($$v) {_vm.$set(_vm.rule, "server_name_custom", $$v)},expression:"rule.server_name_custom"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$v.rule.server_address_custom.$errors.length ? '' : 'Public IP Address',"outlined":"","error-messages":_vm.$v.rule.server_address_custom.$errors.length?_vm.$v.rule.server_address_custom.$errors[0].$message:''},on:{"change":function () {
              _vm.$emit('serverChanges', {customAddress: _vm.rule.server_address_custom, index: _vm.index})
              _vm.$v.rule.server_address_custom.$touch()
            }},model:{value:(_vm.rule.server_address_custom),callback:function ($$v) {_vm.$set(_vm.rule, "server_address_custom", $$v)},expression:"rule.server_address_custom"}})],1)],1):_vm._e()],1),_c('td',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"maxlength":"50","placeholder":_vm.$v.rule.server_port.$errors.length ? '' : 'Port',"single-line":"","outlined":"","error-messages":_vm.$v.rule.server_port.$errors.length?_vm.$v.rule.server_port.$errors[0].$message:''},on:{"change":function () {
        _vm.$emit('serverChanges', {port: _vm.rule.server_port, index: _vm.index})
        _vm.$v.rule.server_port.$touch()
      }},model:{value:(_vm.rule.server_port),callback:function ($$v) {_vm.$set(_vm.rule, "server_port", $$v)},expression:"rule.server_port"}})],1),_c('td',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"maxlength":"50","placeholder":_vm.$v.rule.server_max_conn.$errors.length ? '' : 'Max Connection',"single-line":"","outlined":"","error-messages":_vm.$v.rule.server_max_conn.$errors.length?_vm.$v.rule.server_max_conn.$errors[0].$message:''},on:{"change":function () {
        _vm.$emit('serverChanges', {max_conn: _vm.rule.server_max_conn, index: _vm.index})
        _vm.$v.rule.server_max_conn.$touch()
      }},model:{value:(_vm.rule.server_max_conn),callback:function ($$v) {_vm.$set(_vm.rule, "server_max_conn", $$v)},expression:"rule.server_max_conn"}})],1),_c('td',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"maxlength":"50","placeholder":_vm.$v.rule.server_weight.$errors.length ? '' : 'Weight',"single-line":"","outlined":"","suffix":"%","error-messages":_vm.$v.rule.server_weight.$errors.length?_vm.$v.rule.server_weight.$errors[0].$message:''},on:{"change":function () {
        _vm.$emit('serverChanges', {weight: _vm.rule.server_weight, index: _vm.index})
        _vm.$v.rule.server_weight.$touch()
      }},model:{value:(_vm.rule.server_weight),callback:function ($$v) {_vm.$set(_vm.rule, "server_weight", $$v)},expression:"rule.server_weight"}})],1),_c('td',{staticClass:"pr-0 justify-start d-flex py-1"},[(_vm.lengthData>1)?_c('div',{staticClass:"text-right",staticStyle:{"width":"125px"}},[_c('v-btn',{attrs:{"height":"50","color":"error","block":"","depressed":"","dense":""},on:{"click":function (){_vm.$emit('delete')}}},[_vm._v("Delete")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }