var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-8",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"pb-5"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 fz-21 font-weight-bold"},[_vm._v("Manage Ticket ")]),_c('v-spacer'),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary ml-7",attrs:{"depressed":"","to":"/manage-ticket/create-ticket"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create Ticket")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"placeholder":"Select Project","outlined":"","items":_vm.allProjects,"return-object":"","clearable":""},model:{value:(_vm.projectselected),callback:function ($$v) {_vm.projectselected=$$v},expression:"projectselected"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"placeholder":"Request Type","outlined":"","items":_vm.requestType,"item-text":"name","item-value":"ID","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"placeholder":"Status","outlined":"","items":_vm.status,"item-text":"name","item-value":"ID","clearable":""},model:{value:(_vm.id_status),callback:function ($$v) {_vm.id_status=$$v},expression:"id_status"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.table.headers,"items":_vm.tickets,"items-per-page":5,"server-items-length":_vm.totalRowsTickets,"options":_vm.options,"hide-default-footer":"","item-key":"createddate"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function () {
                    _vm.$router.push(("/detail-ticket/" + (item.id)));
                  }}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status === 'Open')?_c('span',{staticClass:"cyan--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Resolved')?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Rejected')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Onhold')?_c('span',{},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'In Progress')?_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Closed')?_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'Assigned')?_c('span',{},[_vm._v(" "+_vm._s(item.status))]):_vm._e(),(item.status === 'Released Onhold')?_c('span',{},[_vm._v(" "+_vm._s(item.status))]):_vm._e(),(item.status === '')?_c('span',{},[_vm._v(" - ")]):_vm._e()]}},{key:"item.project",fn:function(ref){
                  var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).locale('en').format("DD/MM/YYYY hh:mm A"))+" ")])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}})]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }