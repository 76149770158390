var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 d-flex",attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Organization VDC")]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listOrgVdc,"items-per-page":10,"server-items-length":_vm.TotalRowsOrgVdc,"loading":_vm.isLoading,"hide-default-footer":"","item-key":"href"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"hide-details":""},model:{value:(_vm.radioTable),callback:function ($$v) {_vm.radioTable=$$v},expression:"radioTable"}},[_c('v-radio',{key:_vm.listOrgVdc.indexOf(item),attrs:{"value":item.href},on:{"click":function () { _vm.value.orgVdc = item }}})],1)]}},{key:"item.backingType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.filterType(item.networkProviderType))+" ")]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.options = val;
                }}})]}}],null,true)}),(_vm.$v.rule.orgVdc.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("Please choose the organization VDC")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }