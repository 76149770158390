var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg mt-4 mb-4",attrs:{"flat":""}},[[_c('v-tabs',{staticClass:"mb-6",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"fz-14 firewall-title",on:{"click":function($event){return _vm.vulnerabilities()}}},[_vm._v("Vulnerabilities")]),_c('v-tab',{staticClass:"fz-14 firewall-title",on:{"click":function($event){return _vm.buildHistory()}}},[_vm._v("Build History")])],1)],_c('v-tabs-items',{staticClass:"mb-6",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticClass:"mb-5"},[_c('v-btn',{attrs:{"outlined":"","color":"#1f60a8","width":"125","disabled":_vm.loadings || _vm.disable},on:{"click":function($event){return _vm.scaning()}}},[(_vm.loadings)?_c('span',[_vm._v("SCANNING ")]):_c('span',[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-shield-check-outline")]),_vm._v(" SCAN")],1),(_vm.loadings)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loadings,"size":'10px'}}):_vm._e()],1)],1),_c('v-card',{attrs:{"flat":""}},[(_vm.DataVulnerabilities)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersVul,"items":_vm.DataVulnerabilities,"item-key":"no","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.CVSS3",fn:function(ref){
var item = ref.item;
return [(item.vendor_attributes)?_c('div',[(item.vendor_attributes.CVSS.ghsa)?_c('p',[_vm._v("ghsa : "+_vm._s(item.vendor_attributes.CVSS.ghsa.V3Score))]):_vm._e(),(item.vendor_attributes.CVSS.nvd)?_c('p',[_vm._v("nvd : "+_vm._s(item.vendor_attributes.CVSS.nvd.V3Score))]):_vm._e(),(item.vendor_attributes.CVSS.redhat)?_c('p',[_vm._v("redhat : "+_vm._s(item.vendor_attributes.CVSS.redhat.V3Score))]):_vm._e()]):_vm._e()]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}})]}}],null,true)}):_vm._e()],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersBuild,"items":_vm.dataBuildHistory,"item-key":"no","hide-default-footer":"","items-per-page":10},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")])]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}})]}}],null,true)})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }