var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Add New User")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Full Name")]),_c('v-text-field',{attrs:{"maxlength":"50","placeholder":"Enter Name","flat":"","outlined":"","error-messages":_vm.createErrorMessage('membername')},model:{value:(_vm.membername),callback:function ($$v) {_vm.membername=$$v},expression:"membername"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Email")]),_c('v-text-field',{attrs:{"maxlength":"50","placeholder":"Enter Email","flat":"","outlined":"","error-messages":_vm.createErrorMessage('emailmember')},on:{"change":function () {
            _vm.$v.emailmember.$reset();
          },"keyup":_vm.onEnter},model:{value:(_vm.emailmember),callback:function ($$v) {_vm.emailmember=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"emailmember"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([
          /[1-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/ ]),expression:"[\n          /[1-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n          ' ',\n          /[0-9]/,\n          /[0-9]/,\n          /[0-9]/,\n        ]"}],staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","placeholder":"Phone Number","required":"","prefix":"+62","error-messages":_vm.$v.phone_number.$errors.length
            ? _vm.$v.phone_number.$errors[0].$message
            : ''},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Select Project")]),_c('v-select',{staticClass:"selectsproject",attrs:{"error-messages":_vm.createErrorMessage('projectsselected'),"items":_vm.selectprojects,"return-object":"","placeholder":"Select Project","flat":"","outlined":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lodash.truncate(item.text, { length: 50 }))+" ")]}}]),model:{value:(_vm.projectsselected),callback:function ($$v) {_vm.projectsselected=$$v},expression:"projectsselected"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Select Role")]),_c('v-select',{attrs:{"error-messages":_vm.createErrorMessage('roleselected'),"items":_vm.selectroles,"placeholder":"Select Role","flat":"","outlined":""},model:{value:(_vm.roleselected),callback:function ($$v) {_vm.roleselected=$$v},expression:"roleselected"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.dialog = false;
              }}},[_vm._v("Cancel")])],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"45","block":""},on:{"click":_vm.addMember}},[_vm._v("Add")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }