var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('v-data-table',{attrs:{"headers":_vm.headersNetwork,"hide-default-footer":"","items":_vm.networks,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.totalRowsNetwork},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Advanced Network ")])])]},proxy:true},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Enable SNAT true NAT Gateway. ")])])]}},{key:"item.instance_network_mgmt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.vyos.ip_private ? _vm.vyos.ip_private : '-')+" ")])]}},{key:"item.ip_public",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.ip_public ? item.ip_public : '-')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',{class:{
                  'success--text': item.status == 'CREATED',
                  'error--text' : item.status == 'FAILED',
                  'grey--text' : item.status == 'ON-PROGRESS' 
                  }},[_vm._v(_vm._s(item.status))]):_c('span',[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"180px"}},[_c('v-switch',{staticClass:"pb-5",attrs:{"hide-details":"","color":"primary","loading":_vm.isLoadingSwitch,"disabled":_vm.isLoadingSwitch,"value":_vm.valueSwitch(item),"inset":""},on:{"click":function($event){return _vm.doAction(item, item.router && !item.router.status ? false : true)}},model:{value:(_vm.switchGateway),callback:function ($$v) {_vm.switchGateway=$$v},expression:"switchGateway"}})],1)]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [(_vm.networks.length > 5)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                  }}}):_vm._e()]}}],null,true)})],1),(_vm.opendialogconfirm == true)?_c('dialog-confirm',{attrs:{"itemSelected":_vm.itemSelected,"header":_vm.headerName},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.opendialogconfirm),callback:function ($$v) {_vm.opendialogconfirm=$$v},expression:"opendialogconfirm"}}):_vm._e(),(_vm.opendialogdeletenetwork == true)?_c('dialog-delete',{attrs:{"itemSelected":_vm.itemSelected,"header":_vm.headerName},on:{"refreshTab":_vm.refreshTab},model:{value:(_vm.opendialogdeletenetwork),callback:function ($$v) {_vm.opendialogdeletenetwork=$$v},expression:"opendialogdeletenetwork"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }