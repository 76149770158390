var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-0 pb-0 pt-2 rounded-lg"},[_c('v-tabs',{attrs:{"slider-size":"4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('span',{staticClass:"ml-4",staticStyle:{"font-size":"16px"}},[_vm._v("Default ")])]),_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Add Ons ")])]),_c('v-tab',[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" Monthly ")])])],1)],1),_c('v-tabs-items',{staticStyle:{"border-top":"0.5px solid #e0e0e0"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex px-8 flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Default")])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headersPackage,"items-per-page":10,"items":_vm.getPackageDefault,"options":_vm.optionsDefault,"server-items-length":_vm.getTotalRowsDefault},on:{"update:options":function($event){_vm.optionsDefault=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Rp "+_vm._s(_vm.Indoformat.format(item.price)))])]}},{key:"item.package_type",fn:function(ref){
var item = ref.item;
return _vm._l((item.package_type),function(data){return _c('v-chip',{key:data.id,staticClass:"mt-1",attrs:{"outlined":"","color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(data.type.name))])])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pr-3 edit primary--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function () {
                                        if(_vm.read_only){
                                            return
                                        }
                                        _vm.packageselected=item;
                                        _vm.openDialogAdd=true;
                                        _vm.typePackage='default'
                                    }}},[_vm._v("Edit ")])]}}])})],1)],1)],1)],1),_c('v-tab-item',[_c('v-card-text',{staticClass:"d-flex px-8 flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Add Ons")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search",staticStyle:{"max-width":"233px"},attrs:{"maxlength":"50","placeholder":"Search","outlined":"","hide-details":""},model:{value:(_vm.searchOnetime),callback:function ($$v) {_vm.searchOnetime=$$v},expression:"searchOnetime"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary ml-6",attrs:{"depressed":"","height":"45","width":"200"},on:{"click":function($event){return _vm.dialogAdd('onetime')}}},[_c('span',{staticClass:"fz-14"},[_vm._v(" Create Add Ons Package ")])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headersPackage,"items-per-page":10,"items":_vm.getPackageOnetime,"options":_vm.optionsOnetime,"server-items-length":_vm.getTotalRowsOnetime},on:{"update:options":function($event){_vm.optionsOnetime=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v("Rp "+_vm._s(_vm.Indoformat.format(item.price)))])]}},{key:"item.package_type",fn:function(ref){
                                    var item = ref.item;
return _vm._l((item.package_type),function(data){return _c('v-chip',{key:data.id,staticClass:"mt-1 mr-1",attrs:{"outlined":"","color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(data.type.name))])])})}},{key:"item.action",fn:function(ref){
                                    var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                                                    on.click($event);
                                                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                                                    rotate: item.open,
                                                },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                                                    if(_vm.read_only){
                                                        return
                                                    }
                                                    _vm.packageselected=item;
                                                    _vm.openDialogAdd=true;
                                                    _vm.typePackage='onetime'
                                                }}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function () {
                                                    _vm.packageselected=item;
                                                    _vm.openDialogDetail=true;
                                                    _vm.typePackage='onetime'
                                                }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                                                    if(_vm.read_only){
                                                        return
                                                    }
                                                    _vm.packageselected = item.id;
                                                    _vm.opendialogdeletepackage = true;
                                                    _vm.typePackage='onetime'
                                                }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                                                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                                        _vm.optionsOnetime = Object.assign({}, _vm.optionsOnetime, val);
                                    }}})]}}])})],1)],1)],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Monthly")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search",staticStyle:{"max-width":"233px"},attrs:{"maxlength":"50","placeholder":"Search","outlined":"","hide-details":""},model:{value:(_vm.searchMonthly),callback:function ($$v) {_vm.searchMonthly=$$v},expression:"searchMonthly"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary ml-6",attrs:{"depressed":"","height":"45","width":"200"},on:{"click":function($event){return _vm.dialogAdd('monthly')}}},[_c('span',{staticClass:"fz-14"},[_vm._v(" Create Monthly Package ")])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headersPackage,"items-per-page":10,"items":_vm.getPackageMonthly,"options":_vm.options,"server-items-length":_vm.getTotalRowsMonthly},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
                                    var item = ref.item;
return [_c('span',[_vm._v("Rp "+_vm._s(_vm.Indoformat.format(item.price))+"/Month")])]}},{key:"item.package_type",fn:function(ref){
                                    var item = ref.item;
return _vm._l((item.package_type),function(data){return _c('v-chip',{key:data.id,staticClass:"ma-1",attrs:{"outlined":"","color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(data.type.name))])])})}},{key:"item.action",fn:function(ref){
                                    var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                                                on.click($event);
                                            }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                                                rotate: item.open,
                                            },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                                                if(_vm.read_only){
                                                    return
                                                }
                                                _vm.packageselected=item;
                                                _vm.openDialogAdd=true;
                                                _vm.typePackage='monthly'
                                            }}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function () {
                                                _vm.packageselected=item;
                                                _vm.openDialogDetail=true;
                                                _vm.typePackage='monthly'
                                            }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                                                if(_vm.read_only){
                                                    return
                                                }
                                                _vm.packageselected = item.id;
                                                _vm.opendialogdeletepackage = true;
                                                _vm.typePackage='monthly'
                                            }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                                            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                                    _vm.options = Object.assign({}, _vm.options, val);
                                }}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 pb-0 pt-2 rounded-lg"},[(_vm.openDialogAdd)?_c('dialogPackage',{attrs:{"item":_vm.packageselected,"type":_vm.typePackage},on:{"clearItem":function($event){_vm.packageselected = $event},"refreshTable":_vm.refreshTab},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e(),(_vm.openDialogDetail)?_c('dialogDetail',{attrs:{"item":_vm.packageselected,"type":_vm.typePackage},on:{"input":function($event){_vm.openDialogDetail = $event},"clearItem":function($event){_vm.packageselected = $event}},model:{value:(_vm.openDialogDetail),callback:function ($$v) {_vm.openDialogDetail=$$v},expression:"openDialogDetail"}}):_vm._e(),_c('dialogDeletePackage',{on:{"confirm":_vm.deletepackageselected},model:{value:(_vm.opendialogdeletepackage),callback:function ($$v) {_vm.opendialogdeletepackage=$$v},expression:"opendialogdeletepackage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }