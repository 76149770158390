var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-6"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end mr-4"},[_c('v-btn',{staticClass:"secondary",attrs:{"disabled":_vm.disable || _vm.disabled,"depressed":"","width":"150","height":"50"},on:{"click":function () {
              _vm.closed = true;
            }}},[_vm._v("Add User")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listusers,"item-key":"no","hide-default-footer":"","items-per-page":10,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.listusers.length},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.entity_type",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.entity_type == "u" ? "User" : "-")+" ")])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.entity_name!='admin')?_c('div',[_vm._v(" More "),_c('v-menu',{attrs:{"right":"","down":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu-down")])]}}],null,true)},[_c('v-list',[_c('v-subheader',{staticClass:"font-weight-bold",staticStyle:{"padding-left":"5px"}},[_vm._v("Set Role")]),_vm._l((_vm.listRole),function(row){return _c('v-list-item',{key:row.id,attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                        _vm.onUpdate(item, row.id);
                      }}},[_vm._v(" "+_vm._s(row.text)+" ")])],1)}),_c('v-divider'),( _vm.username==item.entity_name && _vm.role!='Superadmin')?_c('v-list-item',[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                        _vm.packageselected=item
                        _vm.ShowPass=true
                    }}},[_vm._v(" Show Password ")])],1):_vm._e(),( _vm.username==item.entity_name )?_c('v-list-item',[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                        _vm.packageselected=item
                        _vm.openDialogAdd=true
                    }}},[_vm._v(" Change Password ")])],1):_vm._e(),(_vm.role=='Superadmin')?_c('v-list-item',[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                        _vm.packageselected=item
                        _vm.ShowPass=true
                    }}},[_vm._v(" Show Password ")])],1):_vm._e(),(!item.IsCreator && _vm.role=='Superadmin' || _vm.role=='Owner')?_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" Delete "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red","dark":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e(),(!item.IsCreator && _vm.role!='Superadmin' && _vm.role!='Owner' && _vm.username==item.entity_name)?_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" Delete "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red","dark":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],2)],1)],1):_vm._e()]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
                // changePage(val);
              }}})]}}],null,true)})],1)],1),(_vm.closed)?_c('createUser',{model:{value:(_vm.closed),callback:function ($$v) {_vm.closed=$$v},expression:"closed"}}):_vm._e(),(_vm.ShowPass)?_c('confirmPass',{attrs:{"item":_vm.packageselected},on:{"clearItem":function($event){_vm.packageselected = $event}},model:{value:(_vm.ShowPass),callback:function ($$v) {_vm.ShowPass=$$v},expression:"ShowPass"}}):_vm._e(),(_vm.openDialogAdd)?_c('changePassword',{attrs:{"item":_vm.packageselected},on:{"clearItem":function($event){_vm.packageselected = $event}},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }