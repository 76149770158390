var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"fz-21 font-weight-bold mb-8"},[_vm._v("Outstanding")]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.tblHeaders,"items":_vm.tblItems,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.amount))+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.uppercaseFirstLetter(item.payment_method))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"}),_c('td',{staticClass:"border-bottom-none"},[_c('v-row',{staticClass:"primary"},[_c('v-col',[_c('b',{staticClass:"white--text"},[_vm._v("Total")])]),_c('v-col',[_c('b',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(_vm.total)))])])],1)],1)])]},proxy:true},{key:"footer",fn:function(ref){
var props = ref.props;
return [(
            (_vm.pagination && _vm.pagination.count > 10) ||
            (_vm.pagination && _vm.pagination.count === 10)
          )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.pagination = Object.assign({}, _vm.pagination, val);
            }}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }