var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7",attrs:{"fluid":""}},[_c('v-row'),_c('br'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"show-select":"","headers":_vm.headersWorker,"items":_vm.getListHarborInstance,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.getTRHarborInstance,"hide-default-footer":""},on:{"toggle-select-all":_vm.selectAll,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Worker ")])])]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('v-checkbox',{staticStyle:{"margin-top":"-5px"},attrs:{"disabled":item.instance_type != 'worker',"value":isSelected,"color":"primary","hide-details":""},on:{"change":function($event){return select($event)}}})]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"table-ip",staticStyle:{"border-bottom":"none"}},[(item.openstack_vm_uuid && item.ip_address_internal)?_c('tr',[_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_vm._v(" Internal ")]),_c('td',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item.ip_address_internal)+" ")])]):_vm._e()])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.instance_type == 'worker' && (item.instance_type == 'worker' && !_vm.selectedRows.includes(item)))?_c('span',{staticClass:"error--text delete",on:{"click":function () {
                _vm.validateprivilages(['Deka Harbor', 'editor', true]).then(function () {
                  _vm.selectedItem = item
                  _vm.headerDelete = 'Worker'
                  _vm.openDialogDelete = true
                })
              }}},[_vm._v("Delete")]):_c('span',{staticClass:"grey--text"},[_vm._v("Delete")])]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = val ;
            }}})]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),(_vm.openDialogDelete)?_c('dialog-delete',{attrs:{"header":_vm.headerDelete,"itemSelected":_vm.selectedItem},on:{"refreshTable":_vm.refreshTable,"clearItem":function($event){_vm.selectedRows = []}},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }