var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Edge Cluster")])])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Select Edge Cluster option for creating this edge gateway")]),_c('v-radio-group',{staticClass:"mb-6",attrs:{"hide-details":""},model:{value:(_vm.value.edgeType),callback:function ($$v) {_vm.$set(_vm.value, "edgeType", $$v)},expression:"value.edgeType"}},[_c('v-radio',{staticClass:"mb-0",attrs:{"label":"Use the edge cluster of the provider Tier-O Gateway","value":"notSpecific"}}),_c('span',{staticClass:"ml-9 mb-4 fz-12"},[_vm._v(" The edge cluster of selected Provider Tier-O Gateway will be used ")]),_c('v-radio',{staticClass:"mb-0",attrs:{"label":"Select specific edge cluster","value":"specific"}}),_c('span',{staticClass:"ml-9 fz-12"},[_vm._v(" Select from the list of the edge cluster available for the selected Organization VDC ")])],1),(_vm.value.edgeType == 'specific')?_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listEdgeCluster,"items-per-page":10,"server-items-length":_vm.listEdgeCluster.length,"hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"hide-details":""},model:{value:(_vm.radioTable),callback:function ($$v) {_vm.radioTable=$$v},expression:"radioTable"}},[_c('v-radio',{key:_vm.listEdgeCluster.indexOf(item),attrs:{"value":item.id},on:{"click":function () { _vm.value.edgeCluster = item }}})],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description ? item.description : '-')+" ")]}},{key:"item.nodeType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.replaceName(item.nodeType))+" ")])]}},{key:"item.deploymentType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.replaceName(item.deploymentType))+" ")])]}}],null,true)}):_vm._e(),(_vm.$v.rule.edgeCluster.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("*Please choose the edge cluster")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project))}},[_c('v-btn',{staticClass:"white--text fz-16 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-16 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-16",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }