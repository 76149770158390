var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"headline font-weight-bold font--text",staticStyle:{"padding-top":"15px"}},[_vm._v(" IPsec Connection ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","icon":"","color":"green"},on:{"click":_vm.refreshStatus}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1),_c('v-data-table',{staticClass:"elevation-0 py-2",attrs:{"loading":_vm.isLoadingConn,"headers":_vm.headers,"items":_vm.listStatusIpsec,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.totalRowsStatusIpsec,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['con-id']+'#'+item['uniqueid'])+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getListIpsec[0] && _vm.getListIpsec[0].id)+" ")]}},{key:"item.local",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("ID")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["local-id"]))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Host")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["local-host"]+':'+item["local-port"]))])])])]}},{key:"item.remote",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("ID")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["remote-id"]))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Host")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["remote-host"]+':'+item["remote-port"]))])])])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("IKEv2 Initiator")])]}},{key:"item.timers",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Rekey")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["rekey-time"]+'s')+" "+_vm._s(_vm.convertTime(item["rekey-time"])))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Reauth")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["reauth-time"]+'s')+" "+_vm._s(_vm.convertTime(item["reauth-time"])))])])])]}},{key:"item.algo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item['encr-alg']))]),_c('span',[_vm._v(" "+_vm._s(item['integ-alg'])+" ")]),_c('span',[_vm._v(" "+_vm._s(item['prf-alg'])+" ")]),_c('span',[_vm._v(" "+_vm._s(item['dh-group'])+" ")])]}},{key:"no-data",fn:function(){return [(_vm.isLoadingConn)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-7 text-center"},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like there is no connection ")])])]},proxy:true}])}),_c('br'),_c('v-divider',{staticStyle:{"min-width":"160px"}}),_c('br'),(_vm.listStatusIpsec.length)?_c('v-btn',{staticClass:"mb-2",attrs:{"outlined":_vm.btnRouteOpen,"color":"info"},on:{"click":function () { _vm.btnRouteOpen = !_vm.btnRouteOpen}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-"+_vm._s(!_vm.btnRouteOpen ? 'plus' : 'minus')+"-circle-outline")]),_vm._v(" "+_vm._s(!_vm.btnRouteOpen ? 'Show' : 'Hide')+" Child SA Entries ")],1):_vm._e(),(_vm.listStatusIpsec.length && _vm.btnRouteOpen)?_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Phase 2 ")]):_vm._e(),(_vm.listStatusIpsec.length && _vm.btnRouteOpen)?_c('v-data-table',{staticClass:"elevation-0 py-2",attrs:{"headers":_vm.headersChild,"items":_vm.childValue,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['name']+'#'+item['uniqueid'])+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('ipsec-p2-'+_vm.currentVpn.instance_name)+" ")]}},{key:"item.local",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.childValue),function(val,i){return _c('span',{key:i},_vm._l((val['local-ts']),function(va,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.splitStraight(va))+" "),_c('br')])}),0)})}},{key:"item.spi",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Local")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["spi-in"]))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Remote")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["spi-out"]))])])])]}},{key:"item.remote",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.childValue),function(val,i){return _c('span',{key:i},_vm._l((val['remote-ts']),function(va,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.splitStraight(va))+" "),_c('br')])}),0)})}},{key:"item.timers",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Rekey")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["rekey-time"]+'s')+" "+_vm._s(_vm.convertTime(item["rekey-time"])))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Life")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["life-time"]+'s')+" "+_vm._s(_vm.convertTime(item["life-time"])))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Install")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["install-time"]+'s')+" "+_vm._s(_vm.convertTime(item["install-time"])))])])])]}},{key:"item.algo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item['encr-alg']))]),_c('span',[_vm._v(" "+_vm._s(item['integ-alg'])+" ")]),_c('span',[_vm._v(" "+_vm._s(item['dh-group'])+" ")]),_c('span',[_vm._v("IPComp: None")])]}},{key:"item.stats",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"border-bottom":"none"}},[_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Bytes-In")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["bytes-in"])+" "+_vm._s('('+_vm.formatBytes(item["bytes-in"])+')'))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Packets-In")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["packets-in"]))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Bytes-Out")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["bytes-out"]))])]),_c('tr',{staticClass:"my-0 py-0",staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"}},[_c('td',{staticClass:"my-0 py-0"},[_vm._v("Packets-Out")]),_c('td',{staticClass:"my-0 py-0"},[_c('span',{staticClass:"mx-2"},[_vm._v(":")]),_vm._v(" "+_vm._s(item["packets-out"]))])])])]}}],null,false,2046774779)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }