var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Type Instance")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewType","depressed":"","height":"50","width":"150"},on:{"click":function () {
              _vm.opendialogaddtype = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Type")])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":10,"search":_vm.search,"items":_vm.flavorstype,"server-items-length":_vm.paginationtype.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"pr-3 edit",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function () {
                if(_vm.read_only){
                  return
                }
                _vm.typeselectedvalue=item;
                _vm.opendialogedittype=true;
              }}},[_vm._v("Edit ")]),_c('span',{staticClass:"error--text pr-3 delete ml-4",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function () {
                if(_vm.read_only){
                  return
                }
                _vm.typeselected = item;
                _vm.opendialogdeletetype = true;
              }}},[_vm._v("Delete ")])]}},{key:"footer",fn:function(ref){
              var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}]),model:{value:(_vm.typessselected),callback:function ($$v) {_vm.typessselected=$$v},expression:"typessselected"}})],1),_c('dialogDeleteType',{on:{"confirm":_vm.deletetypeselected},model:{value:(_vm.opendialogdeletetype),callback:function ($$v) {_vm.opendialogdeletetype=$$v},expression:"opendialogdeletetype"}}),(_vm.opendialogaddtype)?_c('dialogAddType',{on:{"added":function () {
        _vm.refreshType();
      }},model:{value:(_vm.opendialogaddtype),callback:function ($$v) {_vm.opendialogaddtype=$$v},expression:"opendialogaddtype"}}):_vm._e(),(_vm.typeselectedvalue && _vm.opendialogedittype)?_c('dialogEditType',{attrs:{"typeselectedvalue":_vm.typeselectedvalue},on:{"updated":function () {
        _vm.refreshType();
      }},model:{value:(_vm.opendialogedittype),callback:function ($$v) {_vm.opendialogedittype=$$v},expression:"opendialogedittype"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }