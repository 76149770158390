import { render, staticRenderFns } from "./InvoicePrepaidTbl.vue?vue&type=template&id=27d45e96"
import script from "./InvoicePrepaidTbl.vue?vue&type=script&lang=js"
export * from "./InvoicePrepaidTbl.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCardText,VDataTable})
