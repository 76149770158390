import { render, staticRenderFns } from "./resource.vue?vue&type=template&id=728dab8e&scoped=true"
import script from "./resource.vue?vue&type=script&lang=js"
export * from "./resource.vue?vue&type=script&lang=js"
import style0 from "./resource.vue?vue&type=style&index=0&id=728dab8e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728dab8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VContainer,VTab,VTabItem,VTabs,VTabsItems})
