var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title "},[_vm._v(_vm._s(_vm.$route.params.project))])])],1),_c('v-card',{staticClass:"rounded-lg mt-4 mb-4",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.detailRepositories,"item-key":"id","hide-default-footer":"","items-per-page":10,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.totalRepositories.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.digest",fn:function(ref){
var item = ref.item;
return [_c('router-link',{ref:"digest",attrs:{"value":("" + (item.digest)),"to":("/registry/" + (_vm.$route.params.project_name) + "/repositories/" + (_vm.$route.params.project) + "/" + (item.digest))}},[_vm._v(_vm._s(_vm.formatString(item.digest)))])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.tags!=null)?_c('div',[(item.tags.length==1)?_c('span',[_vm._v(_vm._s(item.tags[0].name))]):(item.tags.length>1)?_c('v-tooltip',{attrs:{"bottom":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"variant":"text"}},on),[_vm._v(_vm._s(item.tags[0].name)+", "+_vm._s(item.tags[1].name)+" ... ("+_vm._s(item.tags.length)+") ")])]}}],null,true)},[_c('span',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Tags")]),_c('th',{staticClass:"text-left"},[_vm._v("Signed by notary")]),_c('th',{staticClass:"text-left"},[_vm._v("Pull Time")]),_c('th',{staticClass:"text-left"},[_vm._v("Push Time")])])]),_c('tbody',[_vm._l((item.tags.slice(0, 3)),function(items,index){return _c('tr',[(items.immutable)?_c('td',[_vm._v(_vm._s(items.name)+" "),_c('v-chip',{staticStyle:{"float":"right"},attrs:{"x-small":"","color":"primary"}},[_vm._v("Immutable")])],1):_c('td',[_vm._v(_vm._s(items.name))]),_c('td',[(items.signed == false)?_c('div',{staticStyle:{"text-align":"center"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")])],1):_c('div',{staticStyle:{"text-align":"center"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")])],1)]),_c('td',[(item.pull_time!='0001-01-01T00:00:00.000Z')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.pull_time))+" ")]):_c('div',[_vm._v(" - ")])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm.formatDate(items.push_time)))])])])}),(item.tags.length>=6)?_c('tr',[_c('td',{attrs:{"colspan":4}},[_c('b',[_vm._v("...")])])]):_vm._e()],2)]},proxy:true}],null,true)})],1)]):_vm._e()],1):_c('div',[_vm._v("-")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$formatSize(item.size)))])]}},{key:"item.labels",fn:function(ref){
var item = ref.item;
return [( item.labels && item.labels.length==1)?_c('div',[_c('v-btn',{staticClass:"white--text bs-trx",attrs:{"color":item.labels[0].color}},[_c('v-icon',[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(item.labels[0].name))],1)],1):( item.labels && item.labels.length>1)?_c('div',[_c('div',{staticClass:"text-center d-flex ma-4 justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"color":item.labels[0].color}},on),[_c('v-icon',[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(item.labels[0].name)+" ... ")],1)]}}],null,true)},_vm._l((item.labels),function(items,index){return _c('span',[_c('v-btn',{staticClass:"white--text",attrs:{"color":items.color}},[_c('v-icon',[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(items.name)+" ")],1),_c('br')],1)}),0)],1)]):_c('div',[_vm._v("-")])]}},{key:"item.pull",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"bs-tr",attrs:{"title":("docker pull " + (_vm.infoSystem.external_url) + "/" + (_vm.$route.params.project_name) + "/" + (_vm.$route.params.project) + ":" + (item.digest))},on:{"click":function($event){return _vm.copy(("docker pull " + (_vm.infoSystem.external_url) + "/" + (_vm.$route.params.project_name) + "/" + (_vm.$route.params.project) + ":" + (item.digest)))}}},[_c('v-icon',{staticClass:"text-center"},[_vm._v("mdi-content-copy")])],1)],1)]}},{key:"item.vulnerabilities",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handler_vulnerabilities(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'],_vm.$route.params.project_name,_vm.$route.params.project))+" "),(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='Critical' && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success')?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-alpha-c-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" Vulnerabilities severity : "+_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity)+" ")])]),_c('v-tooltip',{attrs:{"left":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h3',_vm._g({},on),[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.total)+" Total - "),_c('small',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.fixable)+" Fixable")])])]}}],null,true)},[_c('div',{staticStyle:{"width":"300px"}},[_c('bar',{attrs:{"data":{                         
                       labels: [
                          'Critical : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          'High : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          'Medium : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          'Low : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          'None : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                        datasets:[{
                          data: [
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                          backgroundColor: [
                            'rgba(189, 39, 28, 0.2)',
                            'rgba(250, 68, 55, 0.2)',
                            'rgba(252, 181, 13, 0.2)',
                            'rgba(17, 118, 250, 0.2)',
                            'rgba(170, 171, 173, 0.2)'
                          ],
                          borderColor: [
                            'rgb(189, 39, 28)',
                            'rgb(250, 68, 55)',
                            'rgb(252, 181, 13)',
                            'rgb(17, 118, 250)',
                            'rgb(170, 171, 173)'
                          ],
                          borderWidth: 1
                        }]
                      },"options":{
                        legend: {
                          display: false,
                        },
                        title: {
                            display: true,
                            text: 'Vulnerabilities severity : Critical',
                            font:{weight: 'bold'}
                        },
                        tooltips: {
                          enabled: false,
                        },
                        scales: {
                          y: {
                            beginAtZero: true
                          }
                        }
                      }}})],1)])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='High' && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success')?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-alpha-h-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" Vulnerabilities severity : "+_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity)+" ")])]),_c('v-tooltip',{attrs:{"left":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('h3',_vm._g({},on),[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.total)+" Total - "),_c('small',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.fixable)+" Fixable")])])]}}],null,true)},[_c('div',{staticStyle:{"width":"300px"}},[_c('bar',{attrs:{"data":{
                         labels: [
                          'Critical : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          'High : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          'Medium : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          'Low : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          'None : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                        datasets:[{
                          data: [
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                          backgroundColor: [
                            'rgba(189, 39, 28, 0.2)',
                            'rgba(250, 68, 55, 0.2)',
                            'rgba(252, 181, 13, 0.2)',
                            'rgba(17, 118, 250, 0.2)',
                            'rgba(170, 171, 173, 0.2)'
                          ],
                          borderColor: [
                            'rgb(189, 39, 28)',
                            'rgb(250, 68, 55)',
                            'rgb(252, 181, 13)',
                            'rgb(17, 118, 250)',
                            'rgb(170, 171, 173)'
                          ],
                          borderWidth: 1
                        }]
                      },"options":{
                        legend: {
                          display: false,
                        },
                        title: {
                            display: true,
                            text: 'Vulnerabilities severity : High',
                            font:{weight: 'bold'}
                        },
                        tooltips: {
                          enabled: false,
                        },
                        scales: {
                          y: {
                            beginAtZero: true
                          }
                        }
                      }}})],1)])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='Medium' && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","location":"start"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',{attrs:{"color":"amber"}},[_vm._v("mdi-alpha-m-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" Vulnerabilities severity : "+_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity)+" ")])]),_c('v-tooltip',{attrs:{"left":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('h3',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.total)+" Total - "),_c('small',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.fixable)+" Fixable")])])]}}],null,true)},[_c('div',{staticStyle:{"width":"300px"}},[_c('bar',{attrs:{"data":{
                        
                        labels: [
                          'Critical : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          'High : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          'Medium : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          'Low : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          'None : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                        datasets:[{
                          data: [
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                          backgroundColor: [
                            'rgba(189, 39, 28, 0.2)',
                            'rgba(250, 68, 55, 0.2)',
                            'rgba(252, 181, 13, 0.2)',
                            'rgba(17, 118, 250, 0.2)',
                            'rgba(170, 171, 173, 0.2)'
                          ],
                          borderColor: [
                            'rgb(189, 39, 28)',
                            'rgb(250, 68, 55)',
                            'rgb(252, 181, 13)',
                            'rgb(17, 118, 250)',
                            'rgb(170, 171, 173)'
                          ],
                          borderWidth: 1
                        }]
                      },"options":{
                        legend: {
                          display: false,
                        },
                        title: {
                            display: true,
                            text: 'Vulnerabilities severity : Medium',
                            font:{weight: 'bold'}
                        },
                        tooltips: {
                          enabled: false,
                        },
                        scales: {
                          y: {
                            beginAtZero: true
                          }
                        }
                      }}})],1)])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='Low' && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","location":"start"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',{attrs:{"color":"light-blue"}},[_vm._v("mdi-alpha-l-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" Vulnerabilities severity : "+_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity)+" ")])]),_c('v-tooltip',{attrs:{"left":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('h3',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.total)+" Total - "),_c('small',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.fixable)+" Fixable")])])]}}],null,true)},[_c('div',{staticStyle:{"width":"300px"}},[_c('bar',{attrs:{"data":{
                        
                        labels: [
                          'Critical : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          'High : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          'Medium : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          'Low : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          'None : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                        datasets:[{
                          data: [
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                          backgroundColor: [
                            'rgba(189, 39, 28, 0.2)',
                            'rgba(250, 68, 55, 0.2)',
                            'rgba(252, 181, 13, 0.2)',
                            'rgba(17, 118, 250, 0.2)',
                            'rgba(170, 171, 173, 0.2)'
                          ],
                          borderColor: [
                            'rgb(189, 39, 28)',
                            'rgb(250, 68, 55)',
                            'rgb(252, 181, 13)',
                            'rgb(17, 118, 250)',
                            'rgb(170, 171, 173)'
                          ],
                          borderWidth: 1
                        }]
                      },"options":{
                        legend: {
                          display: false,
                        },
                        title: {
                            display: true,
                            text: 'Vulnerabilities severity : Low',
                            font:{weight: 'bold'}
                        },
                        tooltips: {
                          enabled: false,
                        },
                        scales: {
                          y: {
                            beginAtZero: true
                          }
                        }
                      }}})],1)])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='None' && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","location":"start"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',{attrs:{"color":"light-blue"}},[_vm._v("mdi-alpha-n-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" Vulnerabilities severity : "+_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity)+" ")])]),_c('v-tooltip',{attrs:{"left":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('h3',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.total)+" Total - "),_c('small',[_vm._v(_vm._s(item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.fixable)+" Fixable")])])]}}],null,true)},[_c('div',{staticStyle:{"width":"300px"}},[_c('bar',{attrs:{"data":{                         
                       labels: [
                          'Critical : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          'High : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          'Medium : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          'Low : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          'None : '+item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                        datasets:[{
                          data: [
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.critical,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.high,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.medium,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.low,
                          item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].summary.summary.none
                        ],
                          backgroundColor: [
                            'rgba(189, 39, 28, 0.2)',
                            'rgba(250, 68, 55, 0.2)',
                            'rgba(252, 181, 13, 0.2)',
                            'rgba(17, 118, 250, 0.2)',
                            'rgba(170, 171, 173, 0.2)'
                          ],
                          borderColor: [
                            'rgb(189, 39, 28)',
                            'rgb(250, 68, 55)',
                            'rgb(252, 181, 13)',
                            'rgb(17, 118, 250)',
                            'rgb(170, 171, 173)'
                          ],
                          borderWidth: 1
                        }]
                      },"options":{
                        legend: {
                          display: false,
                        },
                        title: {
                            display: true,
                            text: 'Vulnerabilities severity : None',
                            font:{weight: 'bold'}
                        },
                        tooltips: {
                          enabled: false,
                        },
                        scales: {
                          y: {
                            beginAtZero: true
                          }
                        }
                      }}})],1)])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Stopped')?_c('div',[_c('v-chip',{attrs:{"variant":"outlined"}},[_vm._v(" Scan Stopped ")])],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && (item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Running'||item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Pending'))?_c('div',[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Scanning "),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Success'&& item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].severity==='None')?_c('div',[_c('v-chip',{attrs:{"variant":"outlined"}},[_vm._v(" No vulnerability ")])],1):_c('div',[_c('v-chip',{attrs:{"variant":"outlined"}},[_vm._v(" Not Scanned ")])],1)]}},{key:"item.sign",fn:function(ref){
                      var item = ref.item;
return [(item.tags && item.tags[0].signed == false)?_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")])],1):(item.tags && item.tags[0].signed == true)?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")])],1):_c('div',[_vm._v(" - ")])]}},{key:"item.annotations",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.annotations || "-")+" ")])]}},{key:"item.push_time",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.push_time))+" ")])]}},{key:"item.pull_time",fn:function(ref){
                      var item = ref.item;
return [(item.pull_time!='0001-01-01T00:00:00.000Z')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.pull_time))+" ")]):_c('div')]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" More "),_c('v-menu',{attrs:{"right":"","down":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu-down")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.scan_artifact(item.digest)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-shield-check-outline")]),_vm._v(" Scan ")],1)],1),(item.scan_overview && item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'] && (item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Running'||item.scan_overview['application/vnd.security.vulnerability.report; version=1.1'].scan_status==='Pending'))?_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.stopscan_artifact(item.digest)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-alert-octagon")]),_vm._v(" Stop Scan ")],1)],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.copy(item.digest)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy Digest ")],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.setLabels(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Labels ")],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",staticStyle:{"color":"red"},on:{"click":function($event){return _vm.dialogDelete(item.digest)}}},[_c('v-icon',{attrs:{"left":"","small":"","color":"red"}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1)],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                    _vm.changePage(val);
                  }}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1),_c('dialogSetLabels',{attrs:{"close-dialog":_vm.closeDialog,"data-selected":_vm.dataSelected,"selecteds":_vm.selecteds,"parameter":_vm.parameter},on:{"update:closeDialog":function($event){_vm.closeDialog=$event},"update:close-dialog":function($event){_vm.closeDialog=$event}}}),_c('deleteDialog',{attrs:{"close":_vm.close,"data-delete":_vm.dataDelete},on:{"update:close":function($event){_vm.close=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }