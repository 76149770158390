var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Confirm delete this project ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("This action is irreversible. To confirm deletion, click the button below.")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function () {
                _vm.dialog = false
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {
                _vm.Delete().then(function (){
                _vm.$emit('close');
                }).catch(function (e){

                })
              }}},[_vm._v(" Delete Project ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }