var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0"},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',[_c('v-tab',{attrs:{"to":"/instance"}},[_vm._v("Instance")]),_c('v-tab',{attrs:{"to":"/instance/auto-scaling"}},[_vm._v("Auto Scaling")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Auto Scaling ")])]),_c('v-col',{staticClass:"d-flex justify-sm-end",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"45","width":"150","append":"","to":"/instance/auto-scaling/create"}},[_vm._v(" Create Auto Scaling ")])],1)],1),_c('v-row',[(_vm.autoScalings.length > 0)?_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.autoScalings,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer d-flex",on:{"click":function ($event) {
                        on.click($event);
                        _vm.rotateArrow(item.id);
                      }}},'label',attrs,false),[_vm._v(" More "),_c('v-icon',{class:{
                        rotate:
                          item.id === _vm.rotateArrowId &&
                          attrs['aria-expanded'] === 'true',
                      },attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'/instance/auto-scaling/' + item.id}},[_c('v-list-item-title',[_vm._v(" Detail ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"error--text",on:{"click":function () {
                          _vm.selectedAutoScaling = item;
                          _vm.openDialogDeleteAutoScaling = true;
                        }}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,false,134252928)}):_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Auto Scaling ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"12"}},[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Build and release faster with scalable instance products in the cloud. Provides flexible server configurations sized for any application, industry leading price to performance, and predictable pricing that is the same across regions and usage volumes. ")])])],1),_c('v-btn',{staticClass:"secondary",attrs:{"append":"","width":"300","height":"50","depressed":"","to":"/auto-scaling/create"}},[_vm._v(" Create Auto Scaling ")])],1)],1)],1)],1)],1)],1)],1),(_vm.openDialogDeleteAutoScaling && _vm.selectedAutoScaling)?_c('dialogDeleteAutoScaling',{attrs:{"item":_vm.selectedAutoScaling,"loading":_vm.loading},on:{"cancel":function($event){_vm.openDialogDeleteAutoScaling = false},"submit":_vm.deleteAutoScaling},model:{value:(_vm.openDialogDeleteAutoScaling),callback:function ($$v) {_vm.openDialogDeleteAutoScaling=$$v},expression:"openDialogDeleteAutoScaling"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }