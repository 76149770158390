var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg mt-3 pa-4",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"headline font-weight-bold font--text d-flex align-center"},[_vm._v("Manual Payment "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",staticStyle:{"max-width":"300px"},attrs:{"maxlength":"50","hide-details":"","outlined":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"create-manual-payment","depressed":"","height":"50"},on:{"click":function($event){_vm.modal.add = true}}},[_vm._v(" Create Manual Payment ")]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","headers":_vm.headers,"hide-default-header":"","hide-default-footer":"","search":_vm.search,"items-per-page":10,"items":_vm.getManualPayment,"server-items-length":_vm.pagination.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.organization_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-150"},[_vm._v(" "+_vm._s(item.organization_name)+" ")])]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-150"},[_vm._v(" "+_vm._s(item.project_name)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-100"},[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total)))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-100"},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-100"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-100"},[_vm._v(" "+_vm._s(item.payment_method)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"min-180"},[(item.status === 'rejected')?_c('div',{staticClass:"error--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}])},[_vm._v(" Rejected ")]):_vm._e(),(item.status === 'approved')?_c('div',{staticClass:"success--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}])},[_vm._v(" Approved ")]):_vm._e(),(item.status === 'pending')?_c('div',[_c('v-btn',{staticClass:"success mr-3",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),attrs:{"depressed":"","height":"36"},on:{"click":function($event){if(_vm.read_only){
                  return
                }
                _vm.payment = item;
                _vm.modal.approve = true;}}},[_vm._v(" Approve ")]),_c('v-btn',{staticClass:"error",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),attrs:{"depressed":"","height":"36"},on:{"click":function($event){if(_vm.read_only){
                  return
                }
                _vm.payment = item;
                _vm.modal.reject = true;}}},[_vm._v(" Reject ")])],1):_vm._e()])]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}],null,true)})],1),(_vm.modal.add)?_c('dialog-create-manual-payment',{on:{"close":function($event){_vm.modal.add = false}},model:{value:(_vm.modal.add),callback:function ($$v) {_vm.$set(_vm.modal, "add", $$v)},expression:"modal.add"}}):_vm._e(),(_vm.modal.reject && _vm.payment)?_c('dialog-reject-manual-payment',{attrs:{"item":_vm.payment},on:{"close":function($event){_vm.modal.reject = false}},model:{value:(_vm.modal.reject),callback:function ($$v) {_vm.$set(_vm.modal, "reject", $$v)},expression:"modal.reject"}}):_vm._e(),(_vm.modal.approve && _vm.payment)?_c('dialog-approve-manual-payment',{attrs:{"item":_vm.payment},on:{"close":function($event){_vm.modal.approve = false}},model:{value:(_vm.modal.approve),callback:function ($$v) {_vm.$set(_vm.modal, "approve", $$v)},expression:"modal.approve"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }