var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg elevation-0",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py04",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"headline mb-2 font-weight-bold font--text"},[_vm._v(" CDN ")]),_c('p',{staticStyle:{"color":"#a5b3bf"}},[_vm._v(" CDN helps to provide high availability and performance by distributing the service spatially relative to end user. ")])]),(_vm.listCDN.length > 0 && !_vm.read_only)?_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","append":"","width":"150","height":"45"},on:{"click":_vm.toPageCreate}},[_vm._v(" Create CDN ")])],1):_vm._e()],1),(_vm.listCDN.length > 0)?_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.listCDN,"items-per-page":_vm.itemSelected},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.isLoading? "Loading..": "No data available")+" ")]},proxy:true},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.getColorStatus(item.deployed)}},[_vm._v("mdi-circle")]),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.domain))])])],1)],1)]}},{key:"item.billing_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billing_type)+" ")]}},{key:"item.deployed",fn:function(ref){
var item = ref.item;
return [(item.is_deleting || item.is_deploying || item.is_updating)?_c('v-progress-linear',{attrs:{"color":"blue","height":"25","striped":"","rounded":"","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.getProgressMessage(item))+" ")]):_c('div',[_c('v-chip',{attrs:{"color":item.deployed ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.deployed ? "Deployed" : "Undeployed"))]),(item.compileErrorMessage)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"red","text-color":"white"}},[_vm._v("Compilation Error")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":21,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                                                        on.click($event);
                                                    }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                                                    rotate: item.open,
                                                },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{attrs:{"to":('/cdn/' + item.id)}},[_c('v-list-item-title',[_vm._v("Detail")])],1),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                                                _vm.validateprivilages(['CDN', 'editor']).then(function async () {
                                                    _vm.removeCDN(item)
                                                });
                                            }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1)]}}],null,false,3446359420)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don't have any CDN ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Create CDN for allows the quick transfer of assets needed for loading internet content including HTML pages, javascript files, stylesheets, images, and videos. ")])])],1),(!_vm.read_only)?_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":""},on:{"click":_vm.toPageCreate}},[_vm._v(" Create CDN ")])],1)],1):_vm._e()],1)],1)],1)],1),(_vm.totalData > 10)?_c('div',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"rounded-lg flex-grow-0 selectFooter lis",attrs:{"hide-details":"","dense":"","items":_vm.itemsPage,"item-text":"text","item-value":"value","outlined":"","disabled":_vm.totalData <= 10},on:{"change":function($event){return _vm.onChange($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                            var item = ref.item;
return [_vm._v(" 1 - "+_vm._s(item.text)+" of "+_vm._s(_vm.totalData))]}}],null,false,2852718888),model:{value:(_vm.itemSelected),callback:function ($$v) {_vm.itemSelected=$$v},expression:"itemSelected"}})],1),_c('v-col',{attrs:{"cols":_vm.pagination === 1 ? 2 : 3}},[_c('div',[_c('v-pagination',{staticClass:"page fz",attrs:{"prev-icon":"Prev","next-icon":"Next","length":_vm.pagination,"total-visible":5,"circle":""},on:{"input":function($event){return _vm.onPageChange()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1):_vm._e()],1)],1)],1)],1),_c('dialogDeleteCDN',{attrs:{"selectedCdn":_vm.selectedCdn},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }