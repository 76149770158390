var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Edit Default Snapshot ")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Total")]),_c('v-text-field',{attrs:{"id":"total","maxlength":"3","type":"number","placeholder":"0","flat":"","outlined":"","error-messages":_vm.totalError,"filled":"","disabled":""},on:{"keypress":function($event){return _vm.isNumber($event)},"blur":_vm.onChangeHandler},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v("GB")])]},proxy:true}]),model:{value:(_vm.total),callback:function ($$v) {_vm.total=_vm._n($$v)},expression:"total"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.pricepermonthfocus === false },attrs:{"id":"pricePerMonth","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.pricePerMonthError},on:{"focus":function($event){_vm.pricepermonthfocus = true},"blur":function (e) {
            _vm.pricepermonthfocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(" "+_vm._s(_vm.pricepermonthfocus ? "" : _vm.toThousandsWithDotFormat(_vm.pricePerMonth))+" ")])]},proxy:true}]),model:{value:(_vm.pricePerMonth),callback:function ($$v) {_vm.pricePerMonth=_vm._n($$v)},expression:"pricePerMonth"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Price per Hours")]),_c('v-text-field',{class:{ hideinput: _vm.priceperhoursfocus === false },attrs:{"id":"pricePerHours","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.pricePerHoursError},on:{"focus":function($event){_vm.priceperhoursfocus = true},"blur":function (e) {
            _vm.priceperhoursfocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(" "+_vm._s(_vm.priceperhoursfocus ? "" : _vm.toThousandsWithDotFormat(_vm.pricePerHours))+" ")])]},proxy:true}]),model:{value:(_vm.pricePerHours),callback:function ($$v) {_vm.pricePerHours=_vm._n($$v)},expression:"pricePerHours"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":function () {
                _vm.$emit('close');
              }}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Cancel")])],1)],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":_vm.submit}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }