var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Manage User")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"20px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewUser","depressed":"","height":"50","width":"150"},on:{"click":function () {
          _vm.opendialogadduser = true;
        }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Add New User")])]):_vm._e(),_c('div',{staticStyle:{"width":"20px","height":"auto"}}),_c('v-btn',{staticClass:"secondary--text",staticStyle:{"border":"3px solid #2c94d2","background-color":"white"},attrs:{"depressed":"","height":"50","width":"150"},on:{"click":_vm.Download}},[_c('span',{staticClass:"fz-14"},[_vm._v("Download")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"id":"FilterOrg","search-input":_vm.searchorganization,"items":_vm.organizations,"outlined":"","item-text":"name","item-value":"id","placeholder":"Filter Organization","attach":"#FilterOrgAttach","clearable":"","hide-details":""},on:{"update:searchInput":function($event){_vm.searchorganization=$event},"update:search-input":function($event){_vm.searchorganization=$event},"focus":function () {
              _vm.fetchorganizations({ search: _vm.searchorganization });
            }},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":"FilterOrgAttach"}})]},proxy:true}]),model:{value:(_vm.filterorganization),callback:function ($$v) {_vm.filterorganization=$$v},expression:"filterorganization"}})],1),_c('v-col',[_c('v-select',{attrs:{"hide-details":"","clearable":"","items":[
            { name: 'owner', id: 1 },
            { name: 'member', id: 2 },
            { name: 'creator', id: 0 } ],"item-text":"name","item-value":"id","outlined":"","placeholder":"Filter Role"},model:{value:(_vm.filterrole),callback:function ($$v) {_vm.filterrole=$$v},expression:"filterrole"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"search",attrs:{"maxlength":"50","clearable":"","placeholder":"Search","outlined":""},on:{"click:clear":function () {
              _vm.search = '';
            }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","show-select":"","hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items-per-page":10,"search":_vm.search,"items":_vm.members,"server-items-length":_vm.pagination.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.organization",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.organization_name)+" ")])]}},{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.role_name)+" ")])]}},{key:"item.is_identity_verified",fn:function(ref){
            var item = ref.item;
return [(item.is_identity_verified === true)?_c('div',{staticClass:"npwp-verified",on:{"click":function () { 
            if (_vm.read_only) {
              return
            }
            _vm.memberselectedvalue = item; 
            _vm.memberselected = item; 
            _vm.opendialognpwpktp = true; 
          }}},[_c('u',[_vm._v(" Verified ")])]):_vm._e(),(item.is_identity_verified === false)?_c('div',{staticClass:"npwp-notverified",on:{"click":function () { 
            if (_vm.read_only) {
              return
            }
            _vm.memberselectedvalue = item; 
            _vm.memberselected = item; 
            _vm.opendialognpwpktp = true; 
          }}},[_c('u',[_vm._v(" Unverified ")])]):_vm._e(),(item.is_identity_verified === null)?_c('div',{staticClass:"npwp-waiting-confirmation",on:{"click":function () { 
            if (_vm.read_only) {
              return
            }
            _vm.memberselectedvalue = item; 
            _vm.memberselected = item; 
            _vm.opendialognpwpktp = true; 
          }}},[_c('u',[_vm._v(" Waiting ")])]):_vm._e()]}},{key:"item.joindate",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('v-switch',{attrs:{"true-value":"active","false-value":"deactive","flat":"","readonly":"","disabled":_vm.read_only,"input-value":item.status},on:{"click":function (){
                  if (_vm.read_only){
                    return
                  }
                  _vm.memberselectedvalue = item
                  _vm.opendialogactivateuser = true
                  }}})],1)]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                  on.click($event);
                  _vm.getmemberbyid(item.id);
                  if (_vm.member !== null) {
                    _vm.show($event, _vm.member);
                  }
                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                  rotate: item.open,
                },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":item.verified_at !== '' ? true : false},on:{"click":function () {
                  _vm.memberselected = item;
                  _vm.opendialogresendinviteuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Resend Invitation")])],1):_vm._e(),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":item.role_name == 'creator' ? false : true},on:{"click":function () {
                  _vm.memberselectedvalue = item;
                  _vm.opendialogtransferuser = true;
                }}},[_c('v-list-item-title',[_vm._v("Transfer Creator Role ")])],1):_vm._e(),_c('v-divider',{staticStyle:{"min-width":"160px"}}),(!_vm.read_only)?_c('v-list-item',{on:{"click":function () {
                  if (_vm.member !== null) {
                    _vm.memberselectedvalue = _vm.member;
                    _vm.opendialogedituser = true;
                  }
                }}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function () {
                  if (_vm.member !== null) {
                    _vm.memberselectedvalue = _vm.member;
                    _vm.opendialogdetailuser = true;
                  }
                }}},[_c('v-list-item-title',[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),(!_vm.read_only)?_c('v-list-item',{attrs:{"disabled":item.is_creator === true ? true : false},on:{"click":function () {
                  _vm.memberselected = item;
                  _vm.opendialogdeleteuser = true;
                }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = Object.assign({}, _vm.options, val);
            }}})]}}],null,true),model:{value:(_vm.membersselected),callback:function ($$v) {_vm.membersselected=$$v},expression:"membersselected"}})],1),(_vm.memberselectedvalue && _vm.opendialognpwpktp)?_c('dialogNpwpKtp',{attrs:{"memberselectedvalue":_vm.memberselectedvalue},on:{"confirm":_vm.verifynpwpmemberselected,"reject":function($event){return _vm.rejectnpwpmemberselected($event)}},model:{value:(_vm.opendialognpwpktp),callback:function ($$v) {_vm.opendialognpwpktp=$$v},expression:"opendialognpwpktp"}}):_vm._e(),_c('dialogDeleteUser',{on:{"confirm":_vm.deletememberselected},model:{value:(_vm.opendialogdeleteuser),callback:function ($$v) {_vm.opendialogdeleteuser=$$v},expression:"opendialogdeleteuser"}}),_c('dialogRecendInviteUser',{on:{"confirm":function () {
        _vm.resendinvitememberselected().then(function () {
          _vm.opendialogsuccess = true;
          _vm.dialogemsgsuccess =
            'Email has been sent, Please wait if mail has not appeared';
          _vm.dialogetitlesuccess = 'Email has been sent!';
          _vm.refreshmembers();
        });
      }},model:{value:(_vm.opendialogresendinviteuser),callback:function ($$v) {_vm.opendialogresendinviteuser=$$v},expression:"opendialogresendinviteuser"}}),(_vm.opendialogadduser)?_c('dialogAddUser',{on:{"added":function () {
        _vm.refreshmembers();
      }},model:{value:(_vm.opendialogadduser),callback:function ($$v) {_vm.opendialogadduser=$$v},expression:"opendialogadduser"}}):_vm._e(),(_vm.memberselectedvalue && _vm.opendialogedituser)?_c('dialogEditUser',{attrs:{"memberselectedvalue":_vm.memberselectedvalue},on:{"updated":function () {
        _vm.refreshmembers();
      }},model:{value:(_vm.opendialogedituser),callback:function ($$v) {_vm.opendialogedituser=$$v},expression:"opendialogedituser"}}):_vm._e(),(_vm.memberselectedvalue && _vm.opendialogtransferuser)?_c('dialogTransferRoleUser',{attrs:{"memberselectedvalue":_vm.memberselectedvalue},on:{"transfer":function () {
        _vm.refreshmembers();
      }},model:{value:(_vm.opendialogtransferuser),callback:function ($$v) {_vm.opendialogtransferuser=$$v},expression:"opendialogtransferuser"}}):_vm._e(),(_vm.opendialogprojectlist && _vm.projects.length)?_c('dialogProjectList',{attrs:{"projects":_vm.projects},model:{value:(_vm.opendialogprojectlist),callback:function ($$v) {_vm.opendialogprojectlist=$$v},expression:"opendialogprojectlist"}}):_vm._e(),_c('dialogSuccess',{attrs:{"message":_vm.dialogemsgsuccess,"title":_vm.dialogetitlesuccess},model:{value:(_vm.opendialogsuccess),callback:function ($$v) {_vm.opendialogsuccess=$$v},expression:"opendialogsuccess"}}),(_vm.opendialogdetailuser && _vm.memberselectedvalue)?_c('dialogDetailUser',{attrs:{"user":_vm.memberselectedvalue},model:{value:(_vm.opendialogdetailuser),callback:function ($$v) {_vm.opendialogdetailuser=$$v},expression:"opendialogdetailuser"}}):_vm._e(),_c('dialogActivateUser',{attrs:{"userorganization":"user","onoff":_vm.memberselectedvalue.status == 'active' ? 'off' : 'on'},on:{"confirm":_vm.confirmToggleUser},model:{value:(_vm.opendialogactivateuser),callback:function ($$v) {_vm.opendialogactivateuser=$$v},expression:"opendialogactivateuser"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }