var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('div',{staticClass:"firewall-title mt-2"},[_vm._v("Edge Gateway")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary mt-1",attrs:{"link":"","width":"150","height":"45","depressed":"","to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project) + "/create-edge")},on:{"click":function () {
                
              }}},[_vm._v(" Create Edge Gateway ")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersTable,"items":_vm.listEdgeGateway,"items-per-page":10,"server-items-length":_vm.TotalRowsEdgeGateway,"loading":_vm.isLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.detail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.distributedRoutingEnabled",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.distributedRoutingEnabled ? 'Enabled' : 'Disabled')+" ")]}},{key:"item.gatewayStatus",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.isBusy)?_c('span',[_c('v-progress-circular',{attrs:{"size":18,"color":"primary","indeterminate":""}})],1):_c('v-icon',{attrs:{"color":item.gatewayStatus == 'READY' ? 'green' : 'error'}},[_vm._v(" "+_vm._s(item.gatewayStatus == 'READY' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')+" ")]),_c('span',{staticClass:"mt-1 ml-1"},[_vm._v(" "+_vm._s(item.gatewayStatus == 'READY' ? 'Normal' : item.gatewayStatus)+" ")])],1)]}},{key:"item.orgVdcName",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',[_vm._v(" mdi-cloud-outline ")]),_vm._v(" "+_vm._s(item.orgVdcName)+" ")]}},{key:"item.edgeGatewayType",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.filterType(item.edgeGatewayType))+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.status == 'ON-PROGRESS')?_c('label',{staticClass:"grey--text"},[_vm._v(" More "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                          on.click($event);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                          rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[(item.status == 'CREATED')?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
                        
                      }}},[_c('v-list-item-title',{},[_vm._v("Detail")])],1):_c('v-list-item',[_c('v-list-item-title',{staticClass:"mr-4",staticStyle:{"color":"rgb(0 0 0 / 38%)"}},[_vm._v("Detail")])],1),_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1),_c('v-list-item',{on:{"click":function () {
                                                
                      }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Delete")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.changePage(val);
                }}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }