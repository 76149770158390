var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('br'),_c('v-data-table',{staticClass:"elevation-0 mt-9",attrs:{"headers":_vm.headers,"items":_vm.listTemplatesVm,"item-key":"HREF","single-line":"","hide-default-footer":"","server-items-length":_vm.totalTemplatesVm,"loading":_vm.isLoadingTable,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.ContainerName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getName(item.ContainerName)))])]}},{key:"item.Cpus",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_vm._v("CPU")]),_c('v-col',[_vm._v(_vm._s(item.Cpus))])],1),_c('v-row',[_c('v-col',[_vm._v("Memory")]),_c('v-col',[_vm._v(_vm._s(_vm.formatSize(item.MemoryMB)))])],1)]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                    _vm.changePage(val);
                  }}})]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }