var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-5 mt-3",attrs:{"flat":""}},[_c('div',{staticClass:"headline font-weight-bold font--text mb-4"},[_vm._v(" CORS CONFIGURATIONS - "+_vm._s(_vm.bucketname)+" ")]),_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-7"},[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"md":"8"}},[_c('h3',[_vm._v("CORS CONFIGURATIONS")]),_c('br'),_c('p',{staticClass:"mb-0"},[_vm._v(" Rules are processed in order from top to bottom until there is match - drag and drop rules to reorder ")])]),_c('v-col',{staticClass:"text-right align-self-center"},[(!_vm.read_only)?_c('v-btn',{attrs:{"depressed":"","disabled":_vm.isLoading,"width":"150","height":"45","color":"secondary"},on:{"click":function () {
                        _vm.validateprivilages(['Object Storage', 'editor']).then(
                          function () {
                            _vm.openDialogCors = true;
                          }
                        );
                      }}},[_vm._v("Add")]):_vm._e()],1)],1),(_vm.CORSRules.length)?_c('div',{class:{ animate: _vm.isLoading }},[_c('v-simple-table',{staticClass:"elevation-0",class:{ hidden: _vm.isLoading }},[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Allowed Header ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Allowed Method ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Bucket Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Allowed Origin ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Action ")])])]),_c('draggable',{attrs:{"tag":"tbody"},on:{"change":_vm.orderChanges},model:{value:(_vm.CORSRules),callback:function ($$v) {_vm.CORSRules=$$v},expression:"CORSRules"}},_vm._l((_vm.CORSRules),function(rule,i){return _c('tr',{key:i,attrs:{"set":(rule.order = i)}},[_c('td',[_vm._v(_vm._s(rule.AllowedHeader))]),_c('td',_vm._l((rule.AllowedMethod),function(method,i){return _c('span',{key:method},[_vm._v(_vm._s(i == rule.AllowedMethod.length - 1 ? method : (method + ", ")))])}),0),_c('td',[_vm._v(_vm._s(_vm.bucketname))]),_c('td',[_vm._v(_vm._s(rule.AllowedOrigin))]),_c('td',[_c('a',{staticClass:"error--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function($event){if(_vm.read_only){
                              return
                            }
                            _vm.deleteCORSRule(rule.order)}}},[_vm._v("Delete")])])])}),0)]],2)],1):_c('div',[_c('v-simple-table',[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Headers ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Methods ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Bucket ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Origin ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Action ")])])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4","align":"center"}},[_vm._v(" Your bucket CORS rule is empty ")])])])]],2)],1),_c('v-col',{staticClass:"text-right align-self-center"},[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.isLoading || _vm.disableSaveChanges,"width":"150","height":"45","color":"secondary"},on:{"click":function () {
                      _vm.validateprivilages(['Object Storage', 'editor']).then(
                        function () {
                          _vm.saveCORSRules();
                        }
                      );
                    }}},[_vm._v("Apply Changes")])],1),_c('v-divider')],1)],1),_c('dialogAdvanCorsOption',{attrs:{"objectStorageID":_vm.objectsrtorageid,"bucketName":_vm.bucketname},on:{"addRule":function ($event) { return _vm.addCORSRule($event); },"close":function ($event) {
        _vm.openDialogCors = $event;
      }},model:{value:(_vm.openDialogCors),callback:function ($$v) {_vm.openDialogCors=$$v},expression:"openDialogCors"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }