<template>
  <div>
    <v-col>
      <v-card flat class="d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto">
        <v-card-text>
          <p style="font-size: 16px; text-transform: capitalize" class="font-weight-bold ma-4">Coming Soon</p>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
