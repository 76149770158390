var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"px-8"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.tableHeaders,"items-per-page":5,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.price_per_month))+"/Month")]),_c('p',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.price_per_hours))+"/Hour")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},on:{"click":function () {
              if(_vm.read_only){
                return
              }
              _vm.selectedSnapshot = item;
              _vm.openDialogEditSnapshot = true;
            }}},[_c('span',{staticClass:"primary--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}])},[_vm._v("Edit")])])])]}}])}),_c('v-divider'),(_vm.selectedSnapshot && _vm.openDialogEditSnapshot)?_c('dialogEditSnapshot',{attrs:{"item":_vm.selectedSnapshot},on:{"close":function($event){_vm.openDialogEditSnapshot = false},"updateDefaultSnapshot":function($event){return _vm.updateDefaultSnapshot($event)}},model:{value:(_vm.openDialogEditSnapshot),callback:function ($$v) {_vm.openDialogEditSnapshot=$$v},expression:"openDialogEditSnapshot"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }