var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.getListSlb.length)?_c('v-card',{staticClass:"pa-7 rounded-0 rounded-b-lg",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Load Balancers ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Load Balancers let you distribute traffic between multiple Instance and are a good way to horizontally scale your app. They’re fully managed by Lintasarta—no set up or configuration required. ")])])],1),_c('v-row',[(!_vm.read_only)?_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":"/network/slb/create"}},[_vm._v(" Create Load Balancer ")])],1):_vm._e()],1)],1)],1)],1)],1)],1):_c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items-per-page":10,"items":_vm.getListSlb,"options":_vm.options,"server-items-length":_vm.getTotalRowsSlb},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.getLoading ? "Loading.." : "No data available")+" ")]},proxy:true},{key:"item.ha_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ha_count == 1 ? "No" : "Yes")+" ")]}},{key:"item.tier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tier.toUpperCase() === 'SLB' ? 'Server Load Balancer' : 'Global Load Balancer')+" ")]}},{key:"item.floating_ip_slb",fn:function(ref){
var item = ref.item;
return [(item.floating_ip_slb)?_c('div',[_c('span',[_vm._v(_vm._s(item.floating_ip_slb))]),_c('br')]):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text",class:{
              'success--text': item.status == 'CREATED',
              'error--text' : item.status == 'FAILED',
              'grey--text' : item.status == 'ON-PROGRESS' 
              }},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',[(item.status == 'ON-PROGRESS' || item.status == 'FAILED')?_c('span',{staticClass:"mr-4 grey--text"},[_vm._v("Configuration")]):_c('span',{staticClass:"mr-4 primary--text edit",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toConfigure(item.id, item)}}},[_vm._v("Configuration")]),(item.status == 'ON-PROGRESS' || item.origin == 'dkh')?_c('span',{staticClass:"grey--text"},[_vm._v("Delete")]):_c('span',{staticClass:"error--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                  _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                    _vm.opendialogdeletedload = true
                    _vm.selectedLB = item
                  });
                }}},[_vm._v("Delete")])])]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.options = val ;
            }}})]}}])})],1)],1)],1),(_vm.opendialogdeletedload == true)?_c('dialogDeleteSlb',{attrs:{"loadBalancer":_vm.selectedLB,"textDelete":_vm.textDelete},on:{"close":function($event){_vm.opendialogdeletedload = false},"deleteText":function($event){_vm.textDelete = $event},"delete":_vm.doDelete,"refreshTable":_vm.refreshTab},model:{value:(_vm.opendialogdeletedload),callback:function ($$v) {_vm.opendialogdeletedload=$$v},expression:"opendialogdeletedload"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }