var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"white","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-2 font-weight-bold fz-21"},[_vm._v("Daily Cost Estimated")]),_c('p',[_vm._v(_vm._s(_vm.project.name))])])],1),_c('v-data-table',{staticClass:"mt-2",attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items-per-page":10,"items":_vm.list},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"item.date",fn:function(){return [_vm._v(" "+_vm._s(_vm.$moment(_vm.date).format("DD/MM/YYYY"))+" ")]},proxy:true},{key:"item.service_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stylizeServiceName(item.service_type))+" ")]}},{key:"item.charged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.charged ? "Yes" : "No")+" ")]}},{key:"item.total_price",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total_price)))])]}},{key:"item.total_discount_value",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total_discount_value)))])]}},{key:"item.total_discount",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total_discount)))])]}},{key:"item.total_billed",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total_billed)))])]}},{key:"item.service_billings",fn:function(ref){
var item = ref.item;
return [(item.service_billings)?_c('v-btn',{attrs:{"color":"white primary--text","elevation":"0"},on:{"click":function () {
              _vm.actionPopupOn = true;
              _vm.service_billings = item.service_billings;
              _vm.service_type = item.service_type;
            }}},[_vm._v(" Detail ")]):_vm._e()]}},(_vm.project.postpaid_type !== 'fixed')?{key:"body.append",fn:function(){return [(_vm.list.length)?_c('tr',{staticStyle:{"border":"none"}},[_vm._l((_vm.tblHeaders.length - 3),function(number){return _c('td',{key:'total-' + number,staticClass:"border-bottom-none"})}),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" Total ")])]),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.total))+" ")])])],2):_vm._e()]},proxy:true}:null,(_vm.list.length)?{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1),(_vm.service_billings)?_c('ActionPopup',{attrs:{"item":_vm.service_billings,"service-name":_vm.service_type},model:{value:(_vm.actionPopupOn),callback:function ($$v) {_vm.actionPopupOn=$$v},expression:"actionPopupOn"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }