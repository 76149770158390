var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-5 mt-3",attrs:{"flat":""}},[_c('v-container',[_c('div',{staticClass:"headline font-weight-bold font--text mb-4"},[_vm._v(" Custom Deka AST Limit ")]),(_vm.isLoading)?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticStyle:{"margin":"auto"},attrs:{"size":200,"color":"primary","indeterminate":"","min":-1}})],1)],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"dl",attrs:{"cols":"3"}},[_vm._v(" Deka AST Limit "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1)]}}])},[_c('span',[_vm._v(" Disable or enable Deka AST Limit ")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"true-value":1,"false-value":0},on:{"change":_vm.handleSwitchChange},model:{value:(_vm.switchValue),callback:function ($$v) {_vm.switchValue=$$v},expression:"switchValue"}})],1)],1),(_vm.switchValue === 1)?_c('v-row',[_c('v-col',{staticClass:"dl",attrs:{"cols":"6"}},[_vm._v(" Project Limit "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1)]}}],null,false,2376427707)},[_c('span',[_vm._v(" Limit For Project ")])])],1),_c('v-col',{staticClass:"dl",attrs:{"cols":"6"}},[_vm._v(" Site Limit "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1)]}}],null,false,2376427707)},[_c('span',[_vm._v(" Limit For Site ")])])],1)],1):_vm._e(),(_vm.switchValue === 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"flat":"","outlined":"","type":"number","error-messages":_vm.$v.projectValue.$errors.length
                    ? _vm.$v.projectValue.$errors[0].$message
                    : ''},model:{value:(_vm.projectValue),callback:function ($$v) {_vm.projectValue=$$v},expression:"projectValue"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"flat":"","outlined":"","type":"number","error-messages":_vm.$v.siteValue.$errors.length
                    ? _vm.$v.siteValue.$errors[0].$message
                    : ''},model:{value:(_vm.siteValue),callback:function ($$v) {_vm.siteValue=$$v},expression:"siteValue"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"grey",attrs:{"id":"CreateNewRoleNav","height":"50","block":""},on:{"click":_vm.redirectBack}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewRoleNav","block":"","height":"50"},on:{"click":_vm.handleUpdateLimit}},[_vm._v("Submit")])],1)],1)],1)],1),(_vm.opendeleteproject)?_c('dialogDeleteI3gis',{attrs:{"selectedproject":_vm.selectedProj},on:{"close":function () {
        _vm.opendeleteproject = false;
      },"deleteproject":function () {
        _vm.opendialogareyousure = true;
      }},model:{value:(_vm.opendeleteproject),callback:function ($$v) {_vm.opendeleteproject=$$v},expression:"opendeleteproject"}}):_vm._e(),(_vm.opendialogareyousure)?_c('dialogConfirmation',{attrs:{"project":_vm.selectedProj},on:{"deleted":function () {},"error":function (response) {
        _vm.opendeleteproject = false;
        _vm.dialogerrormsg = response.data.data;
        _vm.opendialogerror = true;
      }},model:{value:(_vm.opendialogareyousure),callback:function ($$v) {_vm.opendialogareyousure=$$v},expression:"opendialogareyousure"}}):_vm._e(),_c('dialogErrorDelete',{attrs:{"message":_vm.dialogerrormsg},model:{value:(_vm.opendialogerror),callback:function ($$v) {_vm.opendialogerror=$$v},expression:"opendialogerror"}}),_c('router-link',{staticStyle:{"display":"none"},attrs:{"id":"buttonCancel","height":"58","tag":"button","depressed":"","block":"","to":("/organization-detail/" + (_vm.currOrg.id))}},[_vm._v(" Cancel ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }