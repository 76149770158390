var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Service List ")])])],1),_c('br'),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"400px"}},[_vm._v("Service")]),_c('th',{attrs:{"width":"400px"}},[_vm._v("Status")]),_c('th',[_vm._v("Actions")])])]),_c('tbody',{staticStyle:{"font-weight":"600"}},[(_vm.getListIpsec.length)?_c('tr',[_c('td',[_vm._v(" IPSec ")]),_c('td',[_vm._v("✅")]),_c('td',[_c('v-btn',{attrs:{"fab":"","icon":"","color":"green"},on:{"click":function () {
                      _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                        _vm.restartIPsec()
                      });
                    }}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"fab":"","icon":"","color":"red"},on:{"click":function () {
                      _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                        _vm.stopServiceIpsec()
                      });
                    }}},[_c('v-icon',[_vm._v("mdi-stop-circle-outline")])],1)],1)]):_vm._e(),(_vm.getListOpenVpn.length)?_c('tr',[_c('td',[_vm._v(" OpenVPN ")]),_c('td',[_vm._v("✅")]),_c('td',[_c('v-btn',{attrs:{"fab":"","icon":"","color":"green"},on:{"click":function () {
                      _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                        _vm.restartOpenvpn()
                      });
                    }}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"fab":"","icon":"","color":"red"},on:{"click":function () {
                      _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                        _vm.stopServiceOpenvpn()
                      });
                    }}},[_c('v-icon',[_vm._v("mdi-stop-circle-outline")])],1)],1)]):_vm._e(),(!_vm.getListIpsec.length && !_vm.getListOpenVpn.length)?_c('tr',[_c('td',{staticStyle:{"width":"100%","padding":"2rem 1.25rem !important","text-align":"center"},attrs:{"colspan":"3"}},[_vm._v(" Looks like you don’t have any OpenVPN and IPsec ")])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }