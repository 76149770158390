var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7",attrs:{"fluid":""}},[(!_vm.loading && _vm.detail)?_c('Header',{attrs:{"detail":_vm.detail}}):_vm._e(),(_vm.loading)?_c('p',{staticClass:"text-center"},[_c('beat-loader',{staticClass:"mr-2",attrs:{"color":"gray","size":"10px"}})],1):[_c('v-data-table',{attrs:{"headers":_vm.isPostpaidFixed ? _vm.serviceHeadersFixed : _vm.serviceHeaders,"items":_vm.services,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"linkpointer"},[_vm._v(_vm._s(((item.service_type_name) + ":" + (item.service_name))))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.price))+" / "+_vm._s(item.price_unit)+" ")]}},{key:"item.discount_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_value ? _vm.toIDRWithDotFormat(item.discount_value) : '-')+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")]}},(!_vm.isPostpaidFixed)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"border":"none"}},[_vm._l((_vm.serviceHeaders.length - 2),function(number){return _c('td',{key:'total-' + number,staticClass:"border-bottom-none"})}),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" Total ")])]),_c('td',{staticClass:"border-bottom-none"},[_c('div',{staticClass:"d-flex flex-row justify-end font-weight-bold"},[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(_vm.services.reduce(function (total, item) { return total + item.billed; }, 0)))+" ")])])],2)]},proxy:true}:null],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }