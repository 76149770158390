var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"my-4"},[_vm._v("Manual Payment")])]),_c('v-card-text',[_c('p',{attrs:{"for":"organizaiton"}},[_c('b',[_vm._v("Select Organization")])]),_c('v-autocomplete',{attrs:{"autocomplete":"off","id":"organization","items":_vm.organizations,"item-value":"id","item-text":"name","placeholder":"Select Organization","error-messages":_vm.$v.organization_id.$errors.length
              ? _vm.$v.organization_id.$errors[0].$message
              : '',"outlined":""},model:{value:(_vm.organization_id),callback:function ($$v) {_vm.organization_id=$$v},expression:"organization_id"}})],1),_c('v-card-text',{staticClass:"pt-0"},[_c('p',{attrs:{"for":"project"}},[_c('b',[_vm._v("Select Project")])]),_c('v-autocomplete',{attrs:{"autocomplete":"off","no-filter":"","id":"project","items":_vm.projects,"item-value":"id","item-text":"name","placeholder":"Select Project","error-messages":_vm.$v.project_id.$errors.length
              ? _vm.$v.project_id.$errors[0].$message
              : '',"outlined":""},on:{"input":function($event){return _vm.$v.project_id.$touch()},"blur":function($event){return _vm.$v.project_id.$touch()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.type_description)+" ")])],1),(
                data.item.outstanding !== null &&
                data.item.outstanding !== undefined
              )?_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v("Outstanding:")]),_vm._v(" IDR"+_vm._s(_vm.toThousandsWithDotFormat(data.item.outstanding))+" ")],1):_vm._e()]}}]),model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}})],1),_c('v-card-text',{staticClass:"pt-0"},[_c('p',[_c('b',[_vm._v("Outstanding Information")])]),(_vm.project_id && _vm.selectedProject)?_c('v-card',{attrs:{"id":"selected-invoice-card","outlined":""}},[_c('v-card-text',{staticClass:"py-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"font-weight-light mb-0"},[_vm._v("Billed")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"1.1rem"}},[_c('b',[_vm._v("IDR"+_vm._s(_vm.toThousandsWithDotFormat(_vm.selectedProject.billed)))])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"font-weight-light mb-0"},[_vm._v("Manually Paid")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"1.1rem"}},[_c('b',[_vm._v("IDR"+_vm._s(_vm.toThousandsWithDotFormat(_vm.selectedProject.manually_paid)))])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"font-weight-light mb-0"},[_vm._v("Total Outstanding")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"1.1rem"}},[_c('b',[_vm._v("IDR"+_vm._s(_vm.toThousandsWithDotFormat(_vm.selectedProject.outstanding)))])])])],1)],1)],1):_c('p',{staticClass:"grey--text"},[_vm._v("Select a project first.")])],1),_c('v-card-text',{staticClass:"pt-0"},[_c('p',{attrs:{"for":"total-amount"}},[_c('b',[_vm._v("Total Amount")])]),_c('v-text-field',{class:{ hideinput: _vm.totalamountfocus === false },attrs:{"id":"total-amount","type":"number","placeholder":"0","flat":"","outlined":"","error-messages":_vm.$v.total_amount.$errors.length
              ? _vm.$v.total_amount.$errors[0].$message
              : ''},on:{"focus":function($event){_vm.totalamountfocus = true},"blur":function($event){_vm.totalamountfocus = false}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR ")]),_vm._v(" "+_vm._s(_vm.totalamountfocus ? "" : _vm.toThousandsWithDotFormat(_vm.total_amount))+" ")])]},proxy:true}]),model:{value:(_vm.total_amount),callback:function ($$v) {_vm.total_amount=$$v},expression:"total_amount"}})],1),_c('v-card-text',{staticClass:"pt-0"},[_c('p',{attrs:{"for":"expired-date"}},[_c('b',[_vm._v("Date")])]),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formated_created_date,"id":"formated_created_date","placeholder":"DD/MM/YYYY","error-messages":_vm.$v.created_date.$errors.length
                  ? _vm.$v.created_date.$errors[0].$message
                  : '',"persistent-hint":"","outlined":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toggleDatePicker),callback:function ($$v) {_vm.toggleDatePicker=$$v},expression:"toggleDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary","max":_vm.currentDate},on:{"input":function($event){_vm.toggleDatePicker = false}},model:{value:(_vm.created_date),callback:function ($$v) {_vm.created_date=$$v},expression:"created_date"}})],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('p',{attrs:{"for":"description"}},[_c('b',[_vm._v("Description")])]),_c('v-textarea',{attrs:{"id":"description","outlined":"","name":"input-7-4","placeholder":"Description","error-messages":_vm.$v.description.$errors.length
              ? _vm.$v.description.$errors[0].$message
              : ''},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"accent","block":""},on:{"click":function($event){return _vm.$emit('close', $event)}}},[_c('b',[_vm._v("Cancel")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"id":"submitMaterai","depressed":"","height":"50","color":"secondary","block":"","type":"submit"}},[(_vm.isLoading)?_c('beat-loader',{staticClass:"mr-2",staticStyle:{"transform":"translateY(3px)"},attrs:{"loading":_vm.isLoading,"color":'white',"size":'10px'}}):_vm._e(),_c('b',[_vm._v("Add")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }