var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Create Floating IP Flavor ")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Organization")]),_c('v-select',{attrs:{"placeholder":"Select Organization","items":_vm.organizations,"outlined":"","single-line":"","append-icon":"mdi-chevron-down","error-messages":_vm.organizationError},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Total")]),_c('v-text-field',{attrs:{"id":"total","maxlength":"3","type":"number","placeholder":"1","flat":"","outlined":"","error-messages":_vm.totalError,"disabled":"","filled":""},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.total),callback:function ($$v) {_vm.total=_vm._n($$v)},expression:"total"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext ma-0"},[_vm._v("Start Date")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"hideinput2",attrs:{"id":"startDate","prepend-icon":"mdi-calendar","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"font--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.dateInput = true}}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.startDate).format("DD/MM/YYYY"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateInput),callback:function ($$v) {_vm.dateInput=$$v},expression:"dateInput"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateInput = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.startDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Reserved Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.reservedPriceFocus === false },attrs:{"id":"reservedPrice","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.reservedPriceError},on:{"focus":function($event){_vm.reservedPriceFocus = true},"blur":function (e) {
            _vm.reservedPriceFocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(" "+_vm._s(_vm.reservedPriceFocus ? "" : _vm.toThousandWithDotFormat(_vm.reservedPrice))+" ")])]},proxy:true}]),model:{value:(_vm.reservedPrice),callback:function ($$v) {_vm.reservedPrice=_vm._n($$v)},expression:"reservedPrice"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Non-Reserved Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.nonReservedPriceFocus === false },attrs:{"id":"nonReservedPrice","maxlength":"10","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.nonReservedPriceError},on:{"focus":function($event){_vm.nonReservedPriceFocus = true},"blur":function (e) {
            _vm.nonReservedPriceFocus = false;
            _vm.onChangeHandler(e);
          },"keypress":function($event){return _vm.isNumber($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(" "+_vm._s(_vm.nonReservedPriceFocus ? "" : _vm.toThousandWithDotFormat(_vm.nonReservedPrice))+" ")])]},proxy:true}]),model:{value:(_vm.nonReservedPrice),callback:function ($$v) {_vm.nonReservedPrice=_vm._n($$v)},expression:"nonReservedPrice"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Cancel")])],1)],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","height":"50","depressed":"","disabled":_vm.loading},on:{"click":_vm.submit}},[(_vm.loading)?_c('beat-loader',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"color":"#2C94D2","size":"10px"}}):_vm._e(),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Create")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }