var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Manage Image")]),_c('v-spacer'),_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"items":_vm.chooseos,"item-value":"id","item-text":"text","outlined":"","hide-details":"","placeholder":"Operating System"},model:{value:(_vm.osselected),callback:function ($$v) {_vm.osselected=$$v},expression:"osselected"}})],1),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary--text",staticStyle:{"border":"2px solid #2c94d2","background-color":"white"},attrs:{"to":"/manage-os/manage-type-os","depressed":"","height":"50","width":"150"}},[_c('span',{staticClass:"fz-14"},[_vm._v("Manage Type")])]):_vm._e(),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),(!_vm.read_only)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","height":"45","width":"200"},on:{"click":function () {
              _vm.openDialogAdd = true;
            }}},[_c('span',{staticClass:"fz-14"},[_vm._v(" Create New Version ")])]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","server-items-length":_vm.pagination.count,"items-per-page":10,"rows-per-page":10,"items":_vm.osversions,"search":_vm.search,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.os",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.os_name))]}},{key:"item.bitversion",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.version_name))]}},{key:"item.versionnumber",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.version_number))]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(
                  !item.is_upload_success && !item.is_upload_failed
                )?_c('span',[_vm._v(" Not Ready Yet ")]):(item.is_upload_success)?_c('span',{staticClass:"primary--text mb-0"},[_vm._v(" Active ")]):(item.is_upload_failed)?_c('span',{staticClass:"error--text mb-0"},[_vm._v(" Failed ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"error--text pr-3 delete ml-4",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}]),on:{"click":function () {
                  if(_vm.read_only){
                    return
                  }
                  _vm.versionselected = item;
                  _vm.openDialogDelete = true;
                }}},[_vm._v("Delete")])]}},{key:"footer",fn:function(ref){
                var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"hidden":true,"props":props},on:{"input":function (val) {
                  _vm.options = Object.assign({}, _vm.options, val);
                }}})]}}])})],1)],1)],1),_c('dialogAddNewVersion',{on:{"added":function () {
        _vm.openDialogAdd=false;
        _vm.resetSelect()
      }},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}),(_vm.openDialogEdit && _vm.versionselected)?_c('dialogAddNewVersion',{attrs:{"versionedit":_vm.versionselected},on:{"updated":function () {
        _vm.fetchosversions(_vm.options);
      }},model:{value:(_vm.openDialogEdit),callback:function ($$v) {_vm.openDialogEdit=$$v},expression:"openDialogEdit"}}):_vm._e(),(_vm.openDialogDelete && _vm.versionselected)?_c('dialogDeleteVersion',{attrs:{"item":_vm.versionselected},on:{"deleted":function () {
        _vm.fetchosversions(_vm.options).then(function (data) {
          if (_vm.options.page > 1 && data.image.length < 1) {
            _vm.options.page = _vm.options.page - 1;
            _vm.fetchosversions(_vm.options);
          }
        });
      }},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }