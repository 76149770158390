var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1020"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold fz-21"},[_vm._v(" IP Address ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 mb-30px",attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.item.ins_vpc_port},scopedSlots:_vm._u([{key:"item.ip_gateway",fn:function(ref){
var item = ref.item;
return [(item.ip_gateway && item.ip_gateway.length)?_c('span',_vm._l((item.ip_gateway),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip)+" ")])}),0):_c('span')]}},{key:"item.internal",fn:function(ref){
var item = ref.item;
return [(item.internal && item.internal.length)?_c('span',_vm._l((item.internal),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span')]}},{key:"item.mac_address",fn:function(ref){
var item = ref.item;
return [(item.mac_address && item.mac_address.length)?_c('span',_vm._l((item.mac_address),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span')]}},{key:"item.external",fn:function(ref){
var item = ref.item;
return [(item.external && item.external.length)?_c('span',_vm._l((item.external),function(ip){return _c('div',{key:ip},[_vm._v(_vm._s(ip))])}),0):_c('span')]}}])})],1),_c('br'),_c('v-card-text',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","depressed":""},on:{"click":function (){_vm.dialog=false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }