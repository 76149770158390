var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"ps-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold fz-21",staticStyle:{"margin-bottom":"6px"}},[_vm._v(_vm._s(_vm.security.name))]),_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.security.description))]),_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.security && _vm.security.security_rules ? _vm.security.security_rules.length : 0)+" Rules / "+_vm._s(_vm.security && _vm.security.instance_ids ? _vm.security.instance_ids.length : 0)+" Instance")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-tabs',{attrs:{"height":"64","slider-size":"4"},model:{value:(_vm.vtabs),callback:function ($$v) {_vm.vtabs=$$v},expression:"vtabs"}},[_c('v-tab',{staticClass:"px-8"},[_vm._v("Rules")]),_c('v-tab',{staticClass:"px-8"},[_vm._v("Instance")]),(!_vm.read_only)?_c('v-tab',{staticClass:"px-8"},[_vm._v("Destroy")]):_vm._e()],1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.vtabs),callback:function ($$v) {_vm.vtabs=$$v},expression:"vtabs"}},[_c('v-tab-item',[_c('rules',{attrs:{"security":_vm.security}})],1),_c('v-tab-item',[_c('v-container',{staticClass:"pa-7"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.instance.headers,"items":_vm.securityinstances,"items-per-page":10,"hide-default-footer":"","no-data-text":"There is no instance attached to this firewall"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex instance-name"},[_c('v-tooltip',{attrs:{"top":"","color":"#333","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.is_deleting)?_c('beat-loader',{staticClass:"mr-2",attrs:{"isLoading":true,"color":'#eb5757',"size":'10px'}}):(item.error_message)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle")]):((item.state === 'loading'))?_c('Loading',{attrs:{"loading_model":'style-a'}}):((!item.error_message && item.openstack_vm_uuid) || item.state === 'NOT EXIST')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":_vm.getStatusColor(item)}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")]):(!item.error_message && !item.openstack_vm_uuid)?_c('beat-loader',{staticClass:"mr-2",attrs:{"isLoading":true,"color":'#1f60a8',"size":'10px'}}):_vm._e()],1)]}}],null,true)},[(item.is_deleting)?_c('span',[_vm._v("Deleting")]):(item.error_message)?_c('span',[_vm._v(_vm._s(item.error_message))]):(!item.error_message && item.openstack_vm_uuid)?_c('span',[_vm._v(" "+_vm._s(item.state ? _vm.capitalizeFirstLetter(item.state.replaceAll("_", " ")) : "N/A"))]):(!item.error_message && !item.openstack_vm_uuid)?_c('span',[_vm._v("Building")]):_vm._e()]),_c('div',[_c('router-link',{attrs:{"to":{ path: ("/instance/" + (item.id) + "/") }}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.instanceName))])]),_c('p',[_vm._v(" "+_vm._s(item.package.v_cpu)+"vCPU / "+_vm._s(item.package.ram)+" RAM / "+_vm._s(item.package.root_disk)+" Root Disk ")])],1)],1)]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [(item.ins_vpc_port != null)?_c('div',[_c('span',{staticClass:"primary--text",on:{"click":function () {
												_vm.openIpAddressDialog = true;
												_vm.activemenuinstance = item;
											}}},[_vm._v(" See all IP Address ")])]):_c('div',[_c('span',[_vm._v(" No IP Attached ")])])]}},{key:"item.created_at",fn:function(ref){
											var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format('DD/MM/YYYY'))+" ")]}},{key:"item.region",fn:function(ref){
											var item = ref.item;
return [_vm._v(" "+_vm._s(item.region.city)+" ")]}},{key:"item.vpc_port",fn:function(ref){
											var item = ref.item;
return [_vm._v(" "+_vm._s(item.vpc_name)+" ("+_vm._s(item.ip_address)+_vm._s(item.floating_ip ? ", " + item.floating_ip : "")+") ")]}}])})],1)],1),(!_vm.read_only)?_c('v-tab-item',[_c('v-container',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"title"},[_vm._v("Destroy")]),_c('div',{staticClass:"content pt-4"},[_vm._v(" This is irreversible. Your Firewall will be destroyed, and any Instances will be dissociated from them. ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pt-4 text-right"},[_c('v-btn',{attrs:{"outlined":"","color":"error","width":"150","height":"45"},on:{"click":function () {
											_vm.validateprivilages([
												'Security',
												'editor' ]).then(function () {
												_vm.deleteModal();
											});
											}}},[_vm._v(" Destroy ")])],1)])],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"713"},model:{value:(_vm.isDelete),callback:function ($$v) {_vm.isDelete=$$v},expression:"isDelete"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-container',{staticClass:"pa-4"},[_c('div',{staticClass:"modal-title"},[_vm._v("Destroy Firewall")]),_c('div',{staticClass:"modal-content py-5"},[_c('p',[_vm._v(" This is irreversible. Your Firewall will be destroyed, and any Instance will be dissociated from them. ")]),_c('p',[_vm._v(" Once this happens, the Instance will allow any type of inbound and outbound traffic, unless you've configured a software firewall in them. ")]),_c('p',[_vm._v("Do you want to permanently destroy this?")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"accent",attrs:{"disabled":_vm.isLoading,"depressed":"","block":""},on:{"click":function () { _vm.isDelete = false }}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.isLoading,"depressed":"","block":""},on:{"click":function () {
						_vm.validateprivilages([
							'Security',
							'editor' ]).then(function () {
							_vm.confirmDelete()
						});
						}}},[(_vm.isLoading)?_c('beat-loader',{staticClass:"mr-2",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":'white',"size":'10px'}}):_c('span',[_vm._v("Confirm")])],1)],1)],1)],1)],1)],1),(_vm.openIpAddressDialog)?_c('dialogIpAddress',{attrs:{"item":_vm.activemenuinstance},model:{value:(_vm.openIpAddressDialog),callback:function ($$v) {_vm.openIpAddressDialog=$$v},expression:"openIpAddressDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }