var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg pa-7",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"fz-21 font-weight-bold mb-0"},[_vm._v("Manage EULA")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"hide-default-footer":"","items":_vm.tblItems,"items-per-page":10,"headers":_vm.tblHeaders,"server-items-length":_vm.total,"options":_vm.options,"customsort":_vm.customSort2},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"200px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","display":"inline-block"}},[_vm._v(" "+_vm._s(item.content)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){if(_vm.read_only){
                 return
               }
               _vm.pindah(item.id, item.form_name)}}},[_c('span',{staticClass:"primary--text",style:([_vm.read_only ? {'color': '#a9a9a9 !important'} : {}])},[_vm._v("Edit")])])]}},{key:"footer",fn:function(ref){
               var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                   _vm.options = Object.assign({}, _vm.options, val);
                 }}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }