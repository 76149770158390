var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","max-width":"800"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Increase Root Disk Size ")])]),_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Select size for your root disk. Must be greater than "+_vm._s(_vm.currentRootDisk)+" GB. This change is not reversible. ")]),_c('p',[_vm._v(" Note: Selected instance will be automatically turned off before the root disk size increased. ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',[_c('span',{staticClass:"font-weight-bold mb-0"},[_vm._v("Select Volume Size")]),(
                _vm.$v.volumeSelected.$errors.length ||
                _vm.$v.customSize.$errors.length
              )?_c('span',{staticClass:"ml-2 error--text",staticStyle:{"font-size":"10px"}},[_vm._v(" *"+_vm._s(_vm.$v.volumeSelected.$errors[0] ? _vm.$v.volumeSelected.$errors[0].$message : _vm.$v.customSize.$errors[0].$message))]):_vm._e()])]),_c('v-col',{staticClass:"d-flex pt-0 flex-wrap"},[_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
              'highlight-error': _vm.$v.customSize.$errors.length,
              'hightlighted selected':
                _vm.$v.customSize.$errors.length < 1 && _vm.isCustomSizeHighlighted,
            },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""}},[_c('v-card-text',{staticClass:"pb-0 pt-4 d-flex flex-column m-0"},[(_vm.rootDiskDiscount && _vm.rootDiskDiscount > 0 && _vm.customSize)?_c('div',[_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk())+"/mo ")]),_c('p',{staticClass:"font-weight-bold blue--text mb-1"},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk(_vm.rootDiskDiscount))+"/mo ")]),_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk(undefined, 'hour'))+"/hour ")]),_c('p',{staticClass:"font-weight-bold blue--text"},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk(_vm.rootDiskDiscount, 'hour'))+"/hour ")])]):_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk())+"/mo ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.calculateCustomRootDisk(undefined, 'hour'))+"/hour ")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"px-4 pt-2",staticStyle:{"width":"170px"},attrs:{"max":"100","flat":"","label":"Enter Size in GB"},on:{"keydown":function (ev) { return (_vm.customSize = _vm.addGBText(ev, _vm.customSize)); },"click":function () {
                    _vm.volumeSelected = null;
                    _vm.isCustomSizeHighlighted = true;
                  },"blur":function () { return (_vm.isCustomSizeHighlighted = !_vm.customSize ? false : true); }},model:{value:(_vm.customSize),callback:function ($$v) {_vm.customSize=$$v},expression:"customSize"}})],1)],1),_vm._l((_vm.listInstanceRootDisk.filter(function (row) {
              return row.volume_size > parseInt(_vm.removeGBText(_vm.currentRootDisk));
            })),function(storageCard){return _c('div',{key:storageCard.id},[_c('v-card',{staticClass:"rounded-lg select-instance mb-4",class:{
                highlight: _vm.volumeSelected ? _vm.is(_vm.volumeSelected.id, storageCard.id) : false,
                'highlight-error': _vm.$v.volumeSelected.$errors.length,
              },staticStyle:{"text-align":"center","margin-right":"10px"},attrs:{"width":"170","border-color":"primary","outlined":""},on:{"click":function () {
                  _vm.volumeSelected = storageCard;
                  _vm.customSize = null;
                  _vm.isCustomSizeHighlighted = false;
                }}},[_c('v-card-text',{staticClass:"pb-0 pt-4 px-2"},[(_vm.rootDiskDiscount && _vm.rootDiskDiscount > 0)?_c('div',[_c('p',{staticClass:"body-2 mb-0",staticStyle:{"margin-bottom":"2.5rem","text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.calculateRootDisk(storageCard.price_per_month))+"/mo ")]),_c('p',{staticClass:"font-weight-bold blue--text mb-1",staticStyle:{"margin-bottom":"2.5rem"}},[_vm._v(" "+_vm._s(_vm.calculateRootDisk(storageCard.price_per_month, _vm.rootDiskDiscount))+"/mo ")]),_c('p',{staticClass:"body-2 mb-0",staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.calculateRootDisk(storageCard.price_per_hour))+"/hour ")]),_c('p',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(_vm.calculateRootDisk(storageCard.price_per_hour, _vm.rootDiskDiscount))+"/hour")])]):_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.calculateRootDisk(storageCard.price_per_month))+"/mo")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.calculateRootDisk(storageCard.price_per_hour))+"/hour")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"flavor-card"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(storageCard.volume_size)+" GB")])])],1)],1)})],2)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"accent","block":""},on:{"click":function () {
                _vm.volumeSelected = null;
                _vm.customSize = null;
                _vm.$v.$reset();
                _vm.$emit('close');
              }}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"depressed":"","height":"50","color":"secondary","block":"","disabled":_vm.isLoading},on:{"click":function () {
              _vm.user.role.toLowerCase() !== 'superadmin' ?
              _vm.displayRootdiskDialog() :
              _vm.doResizeRootDisk()
              
            }}},[(_vm.isLoading)?_c('beat-loader',{attrs:{"loading":_vm.isLoading,"color":"white","size":"10px"}}):_c('span',[_vm._v("Resize Root Disk")])],1)],1)],1)],1)],1),(_vm.rootdiskDialog)?_c('dialogNewServiceAvailability',{attrs:{"onClose":function () {_vm.rootdiskDialog = false},"serviceName":"Rootdisk","actionName":"Resize Rootdisk","onAvailable":_vm.doResizeRootDisk}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }