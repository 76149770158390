var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listRepositories,"item-key":"no","hide-default-footer":"","items-per-page":10,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.totalRepositories.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"append":"","to":{path:("/registry/" + (_vm.$route.params.project_name) + "/repositories/" + (_vm.splitRepo(item.name)))}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.update_time",fn:function(ref){
var item = ref.item;
return [(item.update_time!='0001-01-01T00:00:00.000Z')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.update_time))+" ")]):_c('div')]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
                _vm.changePage(val);
              }}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(!_vm.disable)?_c('div',[_c('span',{staticClass:"cursor-pointer red--text",on:{"click":function($event){return _vm.DeleteConfirm(item)}}},[_vm._v(" Delete ")])]):_c('div',[_c('span',{staticClass:"grey--text"},[_vm._v(" Delete ")])])]}}],null,true)})],1)],1)],1),_c('deleteDialog',{attrs:{"close":_vm.close,"data-delete":_vm.dataDelete},on:{"update:close":function($event){_vm.close=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }