var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-7"},[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Select Applications ")])]),_c('v-card-text',{staticClass:"px-7"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listApplications,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.rowsApplications,"hide-default-footer":"","show-select":"","single-select":_vm.isSinggle},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Default")])]}},{key:"item.applicationPorts",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"scroll2"},_vm._l((item.applicationPorts),function(row,key){return _c('div',{key:key,staticClass:"d-flex"},[_c('div',{staticClass:"my-0 py-0"},[_vm._v(_vm._s(row.protocol))]),_c('div',{staticClass:"my-0 py-0 px-2"},[_vm._v(" : ")]),_vm._l((row.destinationPorts),function(destination,i){return _c('div',{key:i,staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(destination)+" ")])})],2)}),0)]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.options = val ;
          }}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-start"},[_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#a5b3bf","block":"","height":"35"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1),_c('v-col',{staticClass:"d-flex flex-row justify-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","height":"35"},on:{"click":_vm.onConfirm}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }