var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-7"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" "+_vm._s(_vm.headerName != 'VPN' ? 'Detail OpenVPN' : 'Detail VPN')+" ")])]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[(_vm.headerName != 'VPN')?_c('v-tab',{staticClass:"px-8 fz-14"},[_c('span',[_vm._v("User OpenVPN")])]):(_vm.headerName == 'VPN')?_c('v-tab',{staticClass:"px-8 fz-14"},[_c('span',[_vm._v("Status OpenVPN")])]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[(_vm.headerName != 'VPN')?_c('v-tab-item',[_c('v-card-text',{staticClass:"pa-8"},[_c('v-row',{},[_c('v-col',{staticClass:"d-flex py-0"},[_c('p',{staticClass:"d-flex fz-21 font-weight-bold align-center justify-center"},[_vm._v("User OpenVPN")]),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.user,"items":_vm.dataItems.user,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.expires",fn:function(ref){
var item = ref.item;
return [(!_vm.edited)?_c('span',{class:_vm.dateNow > _vm.dateView ? 'red--text' : '#556272--text'},[_vm._v(_vm._s(_vm.dateView))]):_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Choose a Date","readonly":"","outlined":"","error-messages":_vm.$v.expires.$errors.length?_vm.$v.expires.$errors[0].$message:''},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"min":_vm.min_date,"show-current":_vm.min_date,"color":"primary"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.expires),callback:function ($$v) {_vm.expires=$$v},expression:"expires"}})],1)],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.edited)?_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
              _vm.validateprivilages(['Network', 'editor', true]).then(function () {
                _vm.edited = true
              });
            }}},[_vm._v("Edit")]):_c('div',[(_vm.isLoadingBtn)?_c('div',{staticClass:"d-flexr"},[_c('v-progress-circular',{attrs:{"size":25,"color":"primary","indeterminate":""}})],1):_c('div',[_c('span',{staticClass:"success--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.updateUser(item)}}},[_vm._v("Save")]),_c('span',{staticClass:"accent--text ml-2",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                  _vm.edited = !_vm.edited
                  _vm.expires = null
                  _vm.menu2 = false
                  _vm.$v.$reset()
                }}},[_vm._v("Cancel")])])])]}}],null,false,1522475377)})],1)],1):(_vm.headerName == 'VPN')?_c('v-tab-item',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"d-flex py-0"},[_c('p',{staticClass:"d-flex fz-21 font-weight-bold align-center justify-center"},[_vm._v("Status OpenVPN")]),_c('v-spacer')],1)],1),_c('status-openvpn',{attrs:{"user":_vm.dataItems.user}})],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent fz-14",attrs:{"block":"","height":"45","depressed":""},on:{"click":function($event){_vm.modal = false}}},[_c('span',[_vm._v("Close")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }