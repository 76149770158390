var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-container',{staticClass:"mt-6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]):_c('v-container',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-items-center mb-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"align-items-center"},[_c('v-col',{staticClass:"fz-16",attrs:{"cols":"6"}},[_vm._v("Immutability rules")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"white--text bs-bd",attrs:{"color":"#1f60a8"}},[_vm._v(_vm._s(_vm.listImmutable.length)+"/15")])],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6","disabled":_vm.disable}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary","disabled":_vm.listImmutable.length==15 || _vm.disable},on:{"click":function($event){return _vm.dialogAdd('add')}}},[_vm._v("ADD RULE")])],1)],1),(_vm.listImmutable.length > 0)?_c('v-row',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listImmutable,"item-key":"no","hide-default-footer":"","items-per-page":15},scopedSlots:_vm._u([{key:"item.rule",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":item.disabled == true ? 'red' : 'green'}},[_vm._v("  "+_vm._s(item.disabled == true ? "mdi-alert-circle-outline" : "mdi-check-circle-outline")+" ")]),_c('span',[_vm._v(" For the repositories "+_vm._s(item.scope_selectors.repository[0].decoration == "repoMatches" ? "matching" : "excluding")+" "+_vm._s(item.scope_selectors.repository[0].pattern)+", tags "+_vm._s(item.tag_selectors[0].decoration == "matches" ? "matching" : "excluding")+" "+_vm._s(item.tag_selectors[0].pattern)+" ")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" More "),_c('v-menu',{attrs:{"right":"","down":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu-down")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                            _vm.tutupDialog = true;
                            _vm.actionText(item.status, item);
                          }}},[_vm._v(" "+_vm._s(item.disabled ? "Enable" : "Disable")+" ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function () {
                          _vm.packageselected=item;
                          _vm.openDialogAdd=true;
                          _vm.typePackage='edit'
                        }}},[_vm._v(" Edit ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.disable}},[_c('v-list-item-title',{staticClass:"cursor-pointer red--text",on:{"click":function () {
                            _vm.tutupDialog = true;
                            _vm.actionText('delete', item);
                          }}},[_vm._v(" Delete ")])],1)],1)],1)],1)]}}],null,true)})],1)],1):_c('v-row',{staticClass:"text-center"},[_c('v-card-text',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Click the ADD RULE button to add a rule. ")])])],1)],1)],1),(_vm.openDialogAdd)?_c('dialogRule',{attrs:{"item":_vm.packageselected,"type":_vm.typePackage},on:{"clearItem":function($event){_vm.packageselected = $event}},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}):_vm._e(),_c('dialogDeleteEnable',{attrs:{"tutup":_vm.tutupDialog,"action-text":_vm.text,"row-data":_vm.dataEdit},on:{"update:tutup":function($event){_vm.tutupDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }