import { Module } from 'vuex'
import axios from 'axios'
import router from '@/lib/router'
import { async } from 'rxjs';




const createConfigs = (config?: any) => {
    const getToken = JSON.parse(localStorage.getItem("loginSsl") || "{}");
    return {
        headers: { Authorization: `Bearer ${getToken.token}` },
        ...config
    }
}

const baseURL = `${process.env.VUE_APP_API_BASE_URL}/ssl-services`

interface state {
    isLoading: Boolean,
    isCreated: Boolean,
    isLoadingUpdateVcl: Boolean,
    isLoadingUpdateDeployment: Boolean,
    isLoadingCheckPassword: Boolean,
    isLoadingDelete: Boolean,
    isLoadingDeploy: Boolean,
    isLoadingRenewalSSL: Boolean,
    listCDN: Array<any>,
    totalData: number,
    detailCDN: Array<any>,
    renewalData: Array<any>,
    listDns: Array<any>,
    listTag: Array<any>,
    vclSource: string
    isValidPassword: boolean,
    listSsl: Array<any>,
    observer: Array<any>,
    content: string
    cdnLimit: Array<any>,
}


const store: Module<state, any> = {
    namespaced: true,
    state: {
        isLoading: false,
        isCreated: false,
        isLoadingUpdateVcl: false,
        isLoadingUpdateDeployment: false,
        isLoadingCheckPassword: false,
        isLoadingDelete: false,
        isLoadingDeploy: false,
        isLoadingRenewalSSL:false,
        listCDN: [],
        totalData: 0,
        detailCDN: [],
        renewalData: [],
        listDns: [],
        listTag: [],
        vclSource: "",
        isValidPassword: false,
        listSsl: [],
        observer: [],
        content: '',
        cdnLimit: []
    },
    getters: {
        isLoading: state => { return state.isLoading },
        vclSource: state => { return state.vclSource },
        isCreated: state => { return state.isCreated },
        isLoadingDelete: state => { return state.isLoadingDelete },
        isLoadingDeploy: state => { return state.isLoadingDeploy },
        isLoadingRenewalSSL: state => { return state.isLoadingRenewalSSL },
        isLoadingUpdateVcl: state => { return state.isLoadingUpdateVcl },
        isLoadingUpdateDeployment: state => { return state.isLoadingUpdateDeployment },
        isLoadingCheckPassword: state => { return state.isLoadingCheckPassword },
        listCDN: state => { return state.listCDN },
        totalData: state => { return state.totalData },
        detailCDN: state => { return state.detailCDN },
        listDns: state => { return state.listDns },
        listTag: state => { return state.listTag },
        isValidPassword: state => { return state.isValidPassword },
        listSsl: state => { return state.listSsl },
        observer: state => { return state.observer },
        content: state => { return state.content },
        cdnLimit: state => { return state.cdnLimit }
    },
    mutations: {
        setIsLoading: (state: state, payload) => {
            state.isLoading = payload
        },
        setIsCreated: (state: state, payload) => {
            state.isCreated = payload
        },
        setIsLoadingUpdateVcl: (state: state, payload) => {
            state.isLoadingUpdateVcl = payload
        },
        setIsLoadingDelete: (state: state, payload) => {
            state.isLoadingDelete = payload
        },
        setIsLoadingDeploy: (state: state, payload) => {
            state.isLoadingDeploy = payload
        },
        setIsLoadingRenewalSSL: (state: state, payload) => {
            state.isLoadingRenewalSSL = payload
        },
        setListCDN: (state: state, payload) => {
            state.listCDN = payload
        },
        setTotalData: (state: state, payload) => {
            state.totalData = payload
        },
        setDetailCDN: (state: state, payload) => {
            state.detailCDN = payload
        },
        setlistDns: (state: state, payload) => {
            state.listDns = payload
        },
        setlistTag: (state: state, payload) => {
            state.listTag = payload
        },
        setvclSource: (state: state, payload) => {
            state.vclSource = payload
        },
        setIsValidPassword: (state: state, payload) => {
            state.isValidPassword = payload
        },
        setIsLoadingUpdateDeployment: (state: state, payload) => {
            state.isLoadingUpdateDeployment = payload
        },
        setIsLoadingCheckPassword: (state: state, payload) => {
            state.isLoadingCheckPassword = payload
        },
        setListSsl: (state: state, payload) => {
            state.listSsl = payload
        },
        setObserver: (state: state, payload) => {
            state.observer.push(payload)
        },
        setContent: (state: state, payload) => {
            state.content = payload
        },
        setCdnLimit: (state: state, payload) => {
            state.cdnLimit = payload
        }
    },
    actions: {
        getListCDN: async ({ commit, dispatch }, payload) => {
            const currentProj = JSON.parse(localStorage.getItem('currentProj'))
            const params = new URLSearchParams();
            let page = "1"
            let limit = "10"
            if (payload) {
                page = payload.page
                limit = payload.limit
            }
            params.append("projectId", currentProj.id)
            params.append("page", page)
            params.append("limit", limit)
            try {
                commit('setIsLoading', true)
                const response = await axios.get(`${baseURL}/v1/cdn/?${params}`, createConfigs())
                commit('setListCDN', JSON.parse(JSON.stringify(response.data.data.rows)))
                commit('setTotalData', JSON.parse(JSON.stringify(response.data.data.total_rows)))
                commit('setIsLoading', false)
            } catch (error) {
                commit('setIsLoading', false)
                dispatch('HOMEPAGE/showErrorToast', `Failed to load data`, { root: true })
            }
        },
        getDetailCDN: async ({ commit, dispatch }, payload) => {
            try {
                const response = await axios.get(`${baseURL}/v1/cdn/${payload}`, createConfigs())
                commit('setDetailCDN', JSON.parse(JSON.stringify(response.data.data)))
                const obj = JSON.parse(JSON.stringify(response.data.data))
                const content = atob(obj.vclSource)
                commit('setvclSource', `<pre><code>${content}</code></pre>`)
            } catch (error) {
                // dispatch('HOMEPAGE/showErrorToast', `Failed to load data detail`, { root: true })
            }
        },
        createCDN: async ({ commit, dispatch }, payload) => {
            try {
                commit('setIsLoading', true)
                await axios.post(`${baseURL}/v1/cdn/`, payload, createConfigs())
                if (store.getters.isLoading) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Creating the CDN', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["domain name is not active", "domain is already exist", "Cannot create a cdn with limit of 0", "Cannot create a cdn with full limit"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "domain name is not active":
                            errorMessage = "Domain name is not active"
                            break;
                        case "domain is already exist":
                            errorMessage = "Domain name already exist!"
                            break;
                        case "Cannot create a cdn with limit of 0":
                        case "Cannot create a cdn with full limit":
                            errorMessage = "You do not have enough limits to create CDN. Please get in touch with the administrator."
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoading', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    return
                }
                commit('setIsLoading', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Create CDN, Please try again", { root: true })
            }
        },
        getListTag: async ({ commit, dispatch }, payload) => {
            try {
                const response = await axios.get(`${baseURL}/v1/cdn/tag-regions`, createConfigs())
                commit('setlistTag', JSON.parse(JSON.stringify(response.data.data)))
            } catch (error) {
                // dispatch('HOMEPAGE/showErrorToast', `Failed to load data tag`, { root: true })
            }
        },
        getListDns: async ({ commit, dispatch }, payload) => {
            const params = new URLSearchParams();
            params.append("projectId", payload);
            try {
                const response = await axios.get(`${baseURL}/v1/ssl/list-dns?${params}`, createConfigs())
                commit('setlistDns', JSON.parse(JSON.stringify(response.data.data)))
            } catch (error) {
                // dispatch('HOMEPAGE/showErrorToast', `Failed to load data dns`, { root: true })
            }
        },
        deleteCDN: async ({ commit, dispatch }, payload) => {
            try {
                commit('setIsLoadingDelete', true)
                await axios.delete(`${baseURL}/v1/cdn/${payload.id}`, createConfigs())
                if (store.getters.isLoadingDelete) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Deleting the CDN', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["data not found"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "data not found":
                            errorMessage = "Data not found"
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoadingDelete', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    return
                }
                commit('setIsLoadingDelete', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Deleting CDN, Please try again", { root: true })
            }
        },
        updateVcl: async ({ commit, dispatch }, payload) => {
            const id = payload.id
            delete payload.id
            try {
                commit('setIsLoadingUpdateVcl', true)
                await axios.put(`${baseURL}/v1/cdn/vcl/${id}`, payload, createConfigs())
                if (store.getters.isLoadingUpdateVcl) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Updating Configuration', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["data not found"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "data not found":
                            errorMessage = "Data not found"
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoadingUpdateVcl', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    setTimeout(() => dispatch('goToRoot'), 2000)
                    return
                }
                commit('setIsLoadingUpdateVcl', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Updating VCL, Please try again", { root: true })
                setTimeout(() => dispatch('goToRoot'), 2000)
            }
        },
        updateDeployment: async ({ commit, dispatch }, payload) => {
            const id = payload.id
            delete payload.id
            try {
                commit('setIsLoadingUpdateDeployment', true)
                await axios.put(`${baseURL}/v1/cdn/deployment/${id}`, payload, createConfigs())
                if (store.getters.isLoadingUpdateDeployment) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Updating CDN Location', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["data not found"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "data not found":
                            errorMessage = "Data not found"
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoadingUpdateDeployment', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    return
                }
                commit('setIsLoadingUpdateDeployment', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Updating CDN Location, Please try again", { root: true })
            }
        },
        checkingPassword: async ({ commit, dispatch }, payload) => {
            try {
                commit('setIsLoadingCheckPassword', true)
                await axios.post(`${baseURL}/v1/auth/password/check`, payload, createConfigs())
                commit('setIsValidPassword', true)
                commit('setIsLoadingCheckPassword', false)
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (errorMessage === 'your password is incorrect') {
                    commit('setIsValidPassword', false)
                    dispatch('HOMEPAGE/showErrorToast', "Your password is incorrect", { root: true })
                } else {
                    dispatch('HOMEPAGE/showErrorToast', "Failed Checking Password", { root: true })
                }
                commit('setIsLoadingCheckPassword', false)
            }
        },
        reloadPage: () => {
            window.location.reload()
        },
        goToRoot: () => {
            router.push('cdn')
            router.go(0)
        },
        deployCDN: async ({ commit, dispatch }, payload) => {
            try {
                commit('setIsLoadingDeploy', true)
                await axios.post(`${baseURL}/v1/cdn/${payload.id}/deploy`, {}, createConfigs())
                if (store.getters.isLoadingDeploy) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Deploying CDN', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["data not found"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "data not found":
                            errorMessage = "Data not found"
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoadingDeploy', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    return
                }
                commit('setIsLoadingDeploy', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Deploy CDN, Please try again", { root: true })
            }
        },
        getListSSL: async ({ commit, dispatch }, payload) => {
            const currentProj = JSON.parse(localStorage.getItem('currentProj'))
            const params = new URLSearchParams();
            let page = "1"
            let limit = "10"
            if(payload && payload.isNotExpired){
                params.append("isNotExpired", payload.isNotExpired)
            }
            params.append("projectId", currentProj.id)
            params.append("page", page)
            params.append("limit", limit)
            try {
                commit('setIsLoading', true)
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/ssl-services/v1/ssl/?${params}`, createConfigs())
                commit('setListSsl', JSON.parse(JSON.stringify(response.data.data.rows)))
                commit('setIsLoading', false)
            } catch (error) {
                commit('setIsLoading', false)
                // dispatch('HOMEPAGE/showErrorToast', `Failed to load data`, { root: true })
            }
        },
        getContent: async ({ commit, dispatch }) => {
            const slug = "panduan-instalasi-cdn"
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/ssl-services/v1/cdn/content/${slug}`, createConfigs())
                commit('setContent', response.data.data.content)
            } catch (error) {
            }
        },
        getCDNLimit: async ({ commit, dispatch }, projectId) => {
            return new Promise<void>((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/ssl-services/v1/cdn/limit/${projectId}`, createConfigs()).then((res: any) => {
                    resolve(res)
                    commit('setCdnLimit', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        setCDNLimit: async ({ commit, dispatch }, payload) => {
            return new Promise<void>((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_BASE_URL}/ssl-services/v1/cdn/limit`, payload, createConfigs()).then((res: any) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        reuploadSSL: async ({ commit, dispatch }, payload) => {
            try {
                commit('setIsLoadingRenewalSSL', true)
                await axios.post(`${baseURL}/v1/cdn/ssl-reupload`, payload, createConfigs())
                if (store.getters.isLoadingRenewalSSL) {
                    dispatch('HOMEPAGE/showProcessToastWS', 'Updating CDN', { root: true })
                }
            } catch (error) {
                let errorMessage = error.response.data.responseMessage
                if (["data not found"].includes(errorMessage)) {
                    switch (errorMessage) {
                        case "data not found":
                            errorMessage = "Data not found"
                            break;
                        default:
                            break;
                    }
                    commit('setIsLoadingRenewalSSL', false)
                    dispatch('HOMEPAGE/showErrorToast', errorMessage, { root: true })
                    return
                }
                commit('setIsLoadingRenewalSSL', false)
                dispatch('HOMEPAGE/showErrorToast', "Failed to Renewal SSL, Please try again", { root: true })
            }
        },
    }
}

export default store