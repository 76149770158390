var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Manage Admin Roles")]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-5",staticStyle:{"max-width":"200px"},attrs:{"maxlength":"50","hide-details":"","outlined":"","placeholder":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewUser","depressed":"","height":"50","width":"150"},on:{"click":_vm.toCreate}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Role")])])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":10,"items":_vm.roles,"server-items-length":_vm.pagination.count},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.default)?_c('span',[_vm._v("Default")]):_c('span',[_vm._v("Custom")])]}},{key:"item.usages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usages)+" ")]}},{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.desc)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                  on.click($event)
                  _vm.itemSelectedvalue = item
                }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                    rotate: item.open,
                  },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{on:{"click":function () {_vm.toDetail(item)}}},[_c('v-list-item-title',[_vm._v("Detail")])],1),(!item.default)?_c('v-list-item',{staticStyle:{"min-height":"1px","margin-left":"-16px","margin-right":"-16px"}},[_c('v-divider',{staticStyle:{"min-width":"160px"}})],1):_vm._e(),(!item.default)?_c('v-list-item',{on:{"click":function () {_vm.dialog_delete=true}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},(_vm.roles.length)?{key:"footer",fn:function(){return [_c('custom-footer-datatable',{attrs:{"props":{pagination: _vm.pagination, options: _vm.options}}})]},proxy:true}:null],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Delete this Role ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("All users will be set as the default role after deleted. Are you sure?")])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accent","block":"","depressed":"","height":"50"},on:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","height":"50","depressed":""},on:{"click":function () {_vm.delete_role()}}},[_vm._v(" Yes ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }