var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Transfer Role Creator")])]),_c('v-card-text',[_c('v-row',[_c('v-text-field',{staticClass:"search",attrs:{"maxlength":"50","clearable":"","placeholder":"Search","outlined":""},on:{"click:clear":function () {
              _vm.searchC = '';
            }},model:{value:(_vm.searchC),callback:function ($$v) {_vm.searchC=$$v},expression:"searchC"}})],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"id":"my-table","show-select":"","single-select":true,"hide-default-footer":"","headers":_vm.headers,"hide-default-header":"","items-per-page":5,"search":_vm.searchC,"items":_vm.creators,"server-items-length":_vm.paginationC.count,"options":_vm.optionsCreator},on:{"input":function($event){return _vm.enterSelect($event)},"update:options":function($event){_vm.optionsCreator=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
              _vm.optionsCreator = Object.assign({}, _vm.optionsCreator, val);
            }}})]}}]),model:{value:(_vm.creatorselected),callback:function ($$v) {_vm.creatorselected=$$v},expression:"creatorselected"}})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.$emit('input', false);
              }}},[_vm._v("Cancel")])],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"id":"Update","depressed":"","height":"45","block":""},on:{"click":_vm.transfer}},[_vm._v("Transfer Creator")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }