var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg px-7 py-4",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Instance Usage ")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#F2994A","stroke-width":"4"}})]),_c('p',{staticClass:"pl-2 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v("CPU")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('custline',{ref:"cpuusage",attrs:{"id":"cpuusage","height":null}})],1)],1),_c('v-row',{staticClass:"mt-7"},[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#2C94D2","stroke-width":"4"}})]),_c('p',{staticClass:"pl-2 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v("RAM")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('custline',{ref:"ramusage",attrs:{"id":"ramusage","height":null,"data":_vm.data2,"options":_vm.options2}})],1)],1),_c('v-row',{staticClass:"mt-7"},[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#BB6BD9","stroke-width":"4"}})]),_c('p',{staticClass:"pl-2 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v("Network")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('custline',{ref:"networkusage",attrs:{"id":"networkusage","height":null,"data":_vm.data3,"options":_vm.options3}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg chart3",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Storage Usage ")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#27AE60","stroke-width":"4"}})]),_c('p',{staticClass:"pl-2 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v("Memory")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('custline',{ref:"storageusage",attrs:{"id":"storageusage","height":null}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg chart3",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Object Storage Usage ")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('svg',{staticClass:"donut-circle",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"6","fill":"white","stroke":"#EB5757","stroke-width":"4"}})]),_c('p',{staticClass:"pl-2 mb-0",staticStyle:{"font-weight":"600"}},[_vm._v("Memory")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border":"1px solid #ece9f1"},attrs:{"depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"black--text mb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.range.start.toLocaleDateString("id-ID"))+" - "+_vm._s(_vm.range.end.toLocaleDateString("id-ID"))+" ")]),_c('v-icon',{staticClass:"ml-2",staticStyle:{"width":"13px","height":"13px"}},[_vm._v("$vuetify.icons.calendarIcon ")])],1)]}}])},[_c('vc-date-picker',{attrs:{"is-range":""},on:{"dayclick":function($event){return _vm.dayclick($event)}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('custline',{ref:"objectstorageusage",attrs:{"id":"objectstorageusage","height":null}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }