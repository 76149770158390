var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v("Transaction History")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.currentProj.name))])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.topups,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.pagination.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")]}},{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_number ? item.invoice_number : "-")+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.payment_date == "0001-01-01T00:00:00Z" || item.status == "Initial" ? "-" : _vm.$moment(item.payment_date).format("DD/MM/YYYY"))+" ")])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [(
          (_vm.pagination && _vm.pagination.count > 10) ||
          (_vm.pagination && _vm.pagination.count === 10)
        )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.pagination = Object.assign({}, _vm.pagination, val);
          }}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }