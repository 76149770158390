var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Instance")]),_c('v-spacer'),_c('router-link',{attrs:{"to":"/dp-instance/create-instance"}},[(!_vm.read_only)?_c('v-btn',{attrs:{"depressed":"","color":"secondary","height":"45","width":"150"}},[_vm._v("Create Instance ")]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listVm,"item-key":"id","single-line":"","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalListVm,"loading":_vm.isLoadingTable,"hide-default-footer":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex pr-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.Busy)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.Status == 'POWERED_OFF' ? 'error' : item.Status == 'POWERED_ON' ? 'primary' : 'grey',"size":"30"}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")]):_c('div',{staticClass:"mt-4"},[_c('span',[_c('v-progress-circular',_vm._g(_vm._b({attrs:{"size":28,"color":"primary","indeterminate":""}},'v-progress-circular',attrs,false),on))],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Status == "POWERED_OFF" ? "Shutoff" : item.Status == "POWERED_ON" ? "Active" : item.Status))])])],1),_c('v-col',{staticClass:"pl-0"},[_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.rowList(item)}}},[_vm._v(_vm._s(item.Name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.GuestOS))]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.Cpus)+" vCPU/ "+_vm._s(_vm.formatSize(item.MemoryMB))+"/ "+_vm._s(_vm.formatSize(item.TotalStorageAllocatedMb))+" ")])])],1)]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.DateCreated ? _vm.$FormatDate(item.DateCreated) : "-")+" ")]}},{key:"item.IpAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.IpAddress || "-")+" ")]}},{key:"item.NetworkName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.NetworkName || "-")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + _vm.getId(item.HREF),"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                    on.click($event);
                    _vm.show($event, item);
                  }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                    rotate: _vm.getId(item.HREF) == _vm.activemenuinstance && _vm.popo,
                  },attrs:{"id":'popup' + _vm.getId(item.HREF),"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold",attrs:{"width":"145"}},[_c('v-list-item',{attrs:{"disabled":item.Status != 'POWERED_ON'},on:{"click":function () {
                    _vm.validateprivilages(['Dekaprime', 'editor']).then(
                      function () {
                        _vm.openWindow(item)
                      }
                    );
                  }}},[_c('v-list-item-title',[_c('span',[_vm._v("Access Console")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status != 'POWERED_OFF'},on:{"click":function () {
                    _vm.validateprivilages(['Dekaprime', 'editor']).then(
                      function () {
                        _vm.onPower(item)
                      }
                    );
                  }}},[_c('v-list-item-title',[_c('span',[_vm._v("Power On")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status != 'POWERED_ON'},on:{"click":function () {
                    _vm.validateprivilages(['Dekaprime', 'editor']).then(
                      function () {
                        _vm.offPower(item)
                      }
                    );
                  }}},[_c('v-list-item-title',[_c('span',[_vm._v("Power Off")])])],1),_c('v-list-item',{attrs:{"disabled":item.Status != 'POWERED_OFF'},on:{"click":function () {
                    _vm.validateprivilages(['Dekaprime', 'editor']).then(
                      function () {
                        _vm.onForce(item)
                      }
                    );
                  }}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Power On, Force Recustomization")])]}}],null,true)},[_c('span',[_vm._v("Power On, Force Recustomization")])])],1)],1),(_vm.role == 'Superadmin')?_c('v-divider'):_vm._e(),(_vm.role == 'Superadmin')?_c('v-list-item',{attrs:{"disabled":item.Status == 'POWERED_ON'},on:{"click":function () {
                    _vm.validateprivilages(['Dekaprime', 'editor']).then(
                      function () {
                        _vm.selectedItem = item
                        _vm.openDialogDelete = true
                      }
                    );
                  }}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" Delete ")])],1):_vm._e()],1)],1)]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                _vm.options = val;
                _vm.changePage(val);
              }}})]}}],null,true)})],1)],1),_c('dialogDekaPrime'),(_vm.openDialogDelete)?_c('DialogDelete',{attrs:{"itemSelected":_vm.selectedItem,"header":'Instance'},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }