var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"d-flex align-center justify-center",attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7 mx-auto",staticStyle:{"max-width":"1200px","width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-card-text',{staticClass:"py-0"},[(_vm.organization)?_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Edit Organization ")]):_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Add New Organization ")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Name of Organization")]),_c('v-text-field',{attrs:{"maxlength":"30","error-messages":_vm.$v.name.$errors.length ? _vm.$v.name.$errors[0].$message : '',"outlined":"","placeholder":"Enter Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Address")]),_c('v-textarea',{attrs:{"maxlength":"255","placeholder":"Enter Address","error-messages":_vm.$v.address.$errors.length ? _vm.$v.address.$errors[0].$message : '',"flat":"","outlined":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[(_vm.personalStatus)?_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Type")]),_c('v-select',{attrs:{"disabled":!!_vm.organization,"outlined":"","items":[
              { text: 'SME', value: true },
              { text: 'Enterprise', value: false } ],"error-messages":_vm.$v.is_personal.$errors.length
                ? _vm.$v.is_personal.$errors[0].$message
                : '',"placeholder":"Select Type","item-text":"text","item-value":"value"},model:{value:(_vm.is_personal),callback:function ($$v) {_vm.is_personal=$$v},expression:"is_personal"}})],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Country")]),_c('v-autocomplete',{staticStyle:{"margin-bottom":"22px"},attrs:{"outlined":"","items":_vm.countries,"placeholder":"Country","item-text":"name","item-value":"name","error-messages":_vm.$v.country.$errors.length
                ? _vm.$v.country.$errors[0].$message
                : ''},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[_c('v-card-text',{staticClass:"py-0"},[_c('p',{staticClass:"titletext"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"maxlength":"255","placeholder":"Enter Description","error-messages":_vm.$v.details.$errors.length ? _vm.$v.details.$errors[0].$message : '',"flat":"","outlined":""},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-card-text',{staticClass:"py-0"},[_c('p',{staticClass:"titletext"},[_vm._v("City / Region / Zip")])]),_c('v-card-text',{staticClass:"d-flex align-center justify-space-between py-0"},[_c('v-text-field',{staticClass:"mr-1",staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","maxlength":"30","placeholder":"City","required":"","error-messages":_vm.$v.city.$errors.length
                ? _vm.$v.city.$errors[0].$message
                : ''},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('v-text-field',{staticClass:"mr-1",staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","maxlength":"30","placeholder":"Region","required":"","error-messages":""},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],staticClass:"mr-1",staticStyle:{"margin-bottom":"22px"},attrs:{"flat":"","outlined":"","maxlength":"30","placeholder":"Zip/Post","required":"","error-messages":""},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Bussiness Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([
              /[1-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/ ]),expression:"[\n              /[1-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n            ]"}],attrs:{"outlined":"","placeholder":"Enter Bussiness Phone Number","error-messages":_vm.$v.phone_number_org.$errors.length
                ? _vm.$v.phone_number_org.$errors[0].$message
                : '',"prefix":"+62"},model:{value:(_vm.phone_number_org),callback:function ($$v) {_vm.phone_number_org=$$v},expression:"phone_number_org"}})],1)],1)],1),(!_vm.organization)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('v-divider')],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[(!_vm.organization)?_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Full Name")]),_c('v-text-field',{attrs:{"disabled":!!_vm.organization,"error-messages":_vm.$v.full_name.$errors.length ? _vm.$v.full_name.$errors[0].$message : '',"maxlength":"50","outlined":"","placeholder":"Enter Full Name"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}})],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"7"}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.organization),expression:"!organization"}]},[_c('p',{staticClass:"titletext"},[_vm._v("Personal Phone Number")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([
              /[1-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              ' ',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/ ]),expression:"[\n              /[1-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n              ' ',\n              /[0-9]/,\n              /[0-9]/,\n              /[0-9]/,\n            ]"}],attrs:{"outlined":"","placeholder":"Enter Personal Phone Number","error-messages":_vm.$v.phone_number_user.$errors.length
                ? _vm.$v.phone_number_user.$errors[0].$message
                : '',"prefix":"+62"},model:{value:(_vm.phone_number_user),callback:function ($$v) {_vm.phone_number_user=$$v},expression:"phone_number_user"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"5"}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.organization),expression:"!organization"}]},[_c('p',{staticClass:"titletext"},[_vm._v("Email")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.organization),expression:"!organization"}],attrs:{"maxlength":"50","disabled":!!_vm.organization,"error-messages":_vm.$v.email.$errors.length ? _vm.$v.email.$errors[0].$message : '',"outlined":"","placeholder":"Enter Email"},on:{"keyup":_vm.onEnter},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('v-divider')],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Sales")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.saleses,"filled":"","label":"Select Sales","error-messages":_vm.$v.sales_id.$errors.length
                ? _vm.$v.sales_id.$errors[0].$message
                : '',"placeholder":"Select Sales","item-text":"fullname","item-value":"id"},model:{value:(_vm.sales_id),callback:function ($$v) {_vm.sales_id=$$v},expression:"sales_id"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.cancel();
                _vm.dialog = false;
              }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Cancel")])])],1),_c('v-col',[(_vm.organization)?_c('v-btn',{staticClass:"secondary",attrs:{"id":"Save","depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.save();
              }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Save")])]):_c('v-btn',{staticClass:"secondary",attrs:{"id":"Create","depressed":"","height":"45","block":""},on:{"click":function () {
                _vm.submit();
              }}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }