var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("External Networks")])])],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',{},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listEksternalNetwork,"items-per-page":10,"server-items-length":_vm.TotalRowsEksternalNetwork,"loading":_vm.isLoading,"item-key":"id","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var header = ref.header;
return undefined}},{key:"item.subnets",fn:function(ref){
var item = ref.item;
return _vm._l((item.subnets),function(sub){return _c('div',{key:sub.gateway},[(_vm.selectedSubnet.networkRow.find(function (x) { return x.id == item.id; }))?_c('v-checkbox',{staticClass:" my-2 fz-12",attrs:{"multiple":"","value":sub,"label":((sub.gateway) + "/" + (sub.prefixLength)),"hide-details":""},model:{value:(_vm.value.subnets),callback:function ($$v) {_vm.$set(_vm.value, "subnets", $$v)},expression:"value.subnets"}}):_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(sub.gateway)+" ")])],1)})}},{key:"item.ip_usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(((item.usedIpCount/item.totalIpCount)*100)))+"% "+_vm._s(("(Total: " + (item.totalIpCount) + ")"))+" ")]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.changePage(val)
                  }}})]}}]),model:{value:(_vm.value.networkRow),callback:function ($$v) {_vm.$set(_vm.value, "networkRow", $$v)},expression:"value.networkRow"}}),(_vm.$v.rule.subnets.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("Please choose the subnets")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }