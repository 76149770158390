var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column px-6 pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 d-flex",attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"font-weight-bold fz-16"},[_vm._v("Tier-O Gateway")]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 scroll",attrs:{"headers":_vm.headers,"items":_vm.listEksternalNetwork,"items-per-page":10,"server-items-length":_vm.TotalRowsEksternalNetwork,"loading":_vm.isLoading,"hide-default-footer":"","item-key":"id","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0 py-0",attrs:{"hide-details":""},model:{value:(_vm.radioTable),callback:function ($$v) {_vm.radioTable=$$v},expression:"radioTable"}},[_c('v-radio',{key:_vm.listEksternalNetwork.indexOf(item),attrs:{"value":item.id},on:{"click":function () { _vm.value.tier = item }}})],1)]}},{key:"item.ip_usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(((item.usedIpCount/item.totalIpCount)*100)))+"% "+_vm._s(("(Total: " + (item.totalIpCount) + ")"))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":2}},[_c('p',{staticClass:"mb-1"},[_vm._v("Subnets")]),_vm._l((item.subnets),function(subnet,key){return _c('div',{key:key},[_c('span',{staticClass:"black--text ml-1"},[_vm._v(" "+_vm._s(subnet.gateway + "/" + subnet.prefixLength)+" ")])])})],2),_c('td',[_c('p',{staticClass:"mb-8"}),_vm._l((item.subnets),function(subnet,key){return _c('div',{key:key},[_c('span',{staticClass:"black--text "},[_vm._v(" "+_vm._s(Math.floor(((subnet.usedIpCount/subnet.totalIpCount)*100)))+"% "+_vm._s(("(Total: " + (subnet.totalIpCount) + ")"))+" ")])])})],2)]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.changePage(val)
                  }}})]}}],null,true)}),(_vm.$v.rule.tier.$errors.length)?_c('p',{staticClass:"font-italic red--text"},[_vm._v("*Please choose the gateway ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex mt-auto align-self-end"},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":("/organization-detail/" + (_vm.$route.params.organizationid) + "/project/" + (_vm.$route.params.project))}},[_c('v-btn',{staticClass:"white--text fz-12 mr-2",attrs:{"color":"#a5b3bf","width":"160px","depressed":"","height":"40"}},[_vm._v(" Cancel ")])],1),(_vm.step > 1)?_c('v-btn',{staticClass:"fz-12 mr-2",attrs:{"color":"secondary","width":"160px","depressed":"","outlined":"","height":"40"},on:{"click":function($event){return _vm.prev()}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.step <= 6)?_c('v-btn',{staticClass:"white--text fz-12",attrs:{"color":"secondary","width":"160px","depressed":"","height":"40"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }