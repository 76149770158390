var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"rounded-lg elevation-0 pa-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',{staticClass:"d-flex flex-row",attrs:{"r":""}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"50px"},attrs:{"color":_vm.nfs_detail.status == 'Created' ? 'primary' : 'red'}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.nfs_detail.status == 'On Create' ? 'Creating' : _vm.nfs_detail.status)+" ")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('h4',[_vm._v(_vm._s(_vm.nfs_detail.name))]),_c('h5',[_vm._v(_vm._s(_vm.nfs_detail.zone))]),_c('p',{staticClass:"my-0",staticStyle:{"font-size":"0.8rem"}},[_c('span',{staticClass:"primary--text"},[_vm._v(" ("+_vm._s(_vm.nfs_detail.node)+") ")]),_vm._v(" in "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.nfs_detail.path)+" ")]),_vm._v(" | "),_c('span',[_vm._v(" "+_vm._s(_vm.nfs_detail.size)+" "+_vm._s(_vm.nfs_detail.unit_size)+" ")]),_c('br'),_vm._v(" IP Private : "+_vm._s(_vm.nfs_detail.ip_private ? _vm.nfs_detail.ip_private : '-')+" ")])])],1)],1),_c('v-card-actions',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"to":"client","replace":""}},[_vm._v("Clients")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"client"}},[_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Clients ")]),_c('v-spacer'),(_vm.tab == 'client' && _vm.nfs_client_list.length > 0 && (!_vm.read_only && (_vm.nfs_detail.status != 'On Create' && _vm.nfs_detail.status != 'Error While Creating')))?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","width":"150","height":"50"},on:{"click":function () { 
                      _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                        _vm.dialog_add = !_vm.dialog_add  
                      })
                    }}},[_vm._v(" Add Client ")]):_vm._e()],1),(_vm.clientIsLoading)?_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"text-center",attrs:{"flat":""}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Loading . . . ")])])],1)],1):(_vm.nfs_client_list.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.nfs_client_list,"server-items-length":_vm.pagination.itemsLength,"items-per-page":_vm.pagination.itemsPerPage},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(item.status == 'Created')?_c('span',{staticClass:"error--text pr-3 ml-2",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                          _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                            _vm.clientVal = item
                            _vm.openDialogDelete = !_vm.openDialogDelete
                          })
                        }}},[_vm._v("Delete")]):_c('span',{staticClass:"grey--text pr-3 ml-2"},[_vm._v(" Delete")])]}},{key:"footer",fn:function(ref){
                        var props = ref.props;
return [(_vm.nfs_client_list.length)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                              _vm.options = val;
                              _vm.changePage(_vm.getNFSClient, val);
                            }}}):_vm._e()]}}])})],1)],1):_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":"","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don't have any client ")]),_c('v-row',[_c('v-col',[(!_vm.read_only && (_vm.nfs_detail.status != 'On Create' && _vm.nfs_detail.status != 'Error While Creating'))?_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","max-width":"300","height":"50","append":"","depressed":"","exact":""},on:{"click":function () { 
                                _vm.validateprivilages(['NFS', 'editor', true]).then(function () {
                                  _vm.dialog_add = !_vm.dialog_add  
                                })
                              }}},[_vm._v(" Add Client ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),(_vm.dialog_add)?_c('dialogAddClient',{attrs:{"nfs_id":_vm.nfs_detail.id},model:{value:(_vm.dialog_add),callback:function ($$v) {_vm.dialog_add=$$v},expression:"dialog_add"}}):_vm._e(),_c('dialogResize',{attrs:{"nfs_detail":_vm.nfs_detail,"nfs_id":_vm.nfs_detail.id},model:{value:(_vm.dialog_resize),callback:function ($$v) {_vm.dialog_resize=$$v},expression:"dialog_resize"}}),(_vm.openDialogDelete)?_c('dialogDeleteClient',{attrs:{"client":_vm.clientVal},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }