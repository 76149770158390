var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-7 pt-2 pb-4"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("User Activity")]),_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listActivityUser['Activity Detail User Organization'],"item-key":"id","single-line":"","server-items-length":_vm.listActivityUser['total activity'],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.fullname))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$FormatDate(item.created_at)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.description))])]}},(_vm.listActivityUser['total activity'] >= 5)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.changePage(val);
                  }}})]}}:null],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }