var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('p',{staticClass:"mb-0 font-weight-bold fz-21"},[_vm._v("Management Site")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"30px"}}),_c('v-btn',{staticClass:"secondary",attrs:{"id":"CreateNewZone","depressed":"","height":"50","width":"150"},on:{"click":function($event){_vm.openDialogAdd = true}}},[_c('span',{staticClass:"fz-14"},[_vm._v("Create New Site")])])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"table-zone",attrs:{"id":"my-table","hide-default-footer":"","headers":_vm.headers,"items":_vm.buckets,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.created_at)).format("DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                      on.click($event);
                      _vm.show($event, item);
                    }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                      rotate: item.open,
                    },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{on:{"click":function () {
                      _vm.selectedBucket = item;
                      _vm.openDialogDelete = true;
                    }}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1),_c('v-list-item',{on:{"click":function () {
                      _vm.selectedBucket = item;
                      _vm.openDialogEdit = true;
                    }}},[_c('v-list-item-title',[_vm._v("Edit")])],1)],1)],1)]}}])})],1)],1)],1),_c('dialogAdd',{attrs:{"selectedBucket":_vm.selectedBucket},on:{"create":function (payload) { return _vm.doCreate(payload); },"update":function (payload) { return _vm.doUpdate(payload); }},model:{value:(_vm.openDialogAdd),callback:function ($$v) {_vm.openDialogAdd=$$v},expression:"openDialogAdd"}}),_c('dialogDelete',{attrs:{"selectedBucket":_vm.selectedBucket},on:{"delete":function (payload) { return _vm.doDelete(payload); }},model:{value:(_vm.openDialogDelete),callback:function ($$v) {_vm.openDialogDelete=$$v},expression:"openDialogDelete"}}),_c('dialogEdit',{attrs:{"selectedBucket":_vm.selectedBucket},on:{"create":function (payload) { return _vm.doCreate(payload); },"update":function (payload) { return _vm.doUpdate(payload); }},model:{value:(_vm.openDialogEdit),callback:function ($$v) {_vm.openDialogEdit=$$v},expression:"openDialogEdit"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }