var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Detail Product")]),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"6"}},[_c('p',[_vm._v("Name")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(_vm.$route.params.name))])]),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"12","md":"6"}},[_c('p',[_vm._v("Status")]),_c('span',{staticClass:"font-weight-bold font--text"},[_vm._v(_vm._s(_vm.detailProduct.status == 1 ? "Active" : "Shutoff"))])])],1)],1),_c('br'),_c('br'),_c('v-card-text',{staticClass:"headline font-weight-bold font--text"},[_vm._v("Service")]),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{attrs:{"md":"1"}}),_c('v-col',{attrs:{"md":"2"}},[(!_vm.read_only)?_c('router-link',{attrs:{"to":("/manage-pricing/create-service/" + (_vm.$route.params.name) + "/" + (_vm.$route.params.id))}},[_c('v-btn',{staticClass:"fz-14",attrs:{"block":"","color":"secondary","dark":"","depressed":"","height":"45","width":"150"}},[_vm._v("Create Service")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listServiceProduct,"item-key":"id","single-line":"","hide-default-footer":"","server-items-length":_vm.totalListServiceProduct},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"ml-2"},[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$FormatDate(item.created_at)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"hide-details":"","true-value":1,"false-value":0,"flat":"","readonly":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.service_tag",fn:function(ref){
var item = ref.item;
return _vm._l((item.service_tag),function(row,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(_vm.filterTag(row.tag_id))+" "),(index != item.service_tag.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                                    on.click($event);
                                    _vm.show($event, item);
                                  }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                                    rotate: item.id == _vm.activemenuinstance && _vm.popo,
                                  },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold",attrs:{"width":"100"}},[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"primary--text",on:{"click":function () {
                                        _vm.dialogDetail = true;
                                        _vm.dataService = item;
                                      }}},[_vm._v("Detail")])])],1),_c('v-list-item',{attrs:{"disabled":_vm.read_only}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":("/manage-pricing/edit-service/" + (_vm.$route.params.name) + "/" + (_vm.$route.params.id) + "/" + (item.name) + "/" + (item.id))}},[_vm._v("Edit")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.read_only}},[_c('v-list-item-title',[_c('span',{staticClass:"red--text",on:{"click":function () {
                                        _vm.openDialog = true;
                                        _vm.dataService = item;
                                      }}},[_vm._v("Delete")])])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                                      var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                                _vm.changePage(val);
                              }}})]}}],null,true)})],1)],1)],1),_c('br'),_c('br'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"#a5b3bf","block":"","height":"50","to":"/manage-pricing/product"}},[_c('span',{staticClass:"fz-16"},[_vm._v("Back")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),(_vm.openDialog)?_c('DeleteService',{attrs:{"dialog-closed":_vm.openDialog,"data-service":_vm.dataService},on:{"update:dialogClosed":function($event){_vm.openDialog=$event},"update:dialog-closed":function($event){_vm.openDialog=$event}}}):_vm._e(),(_vm.dialogDetail)?_c('DetailService',{attrs:{"dialog-detail":_vm.dialogDetail,"data-service":_vm.dataService},on:{"update:dialogDetail":function($event){_vm.dialogDetail=$event},"update:dialog-detail":function($event){_vm.dialogDetail=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }