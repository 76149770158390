var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('br'),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title ml-4"},[_vm._v("Catalog")])])],1),_c('br'),_c('v-data-table',{staticClass:"elevation-0 mt-9",attrs:{"headers":_vm.headers,"items":_vm.allCatalog,"item-key":"HREF","single-line":"","hide-default-footer":"","server-items-length":_vm.totalCatalog,"loading":_vm.loadingCatalog,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":("/catalog-detail/" + (_vm.getId(item.HREF)))}},[_vm._v(" "+_vm._s(item.Name))])],1)]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Status == "RESOLVED" ? "Ready" : "Not Ready"))])]}},{key:"item.IsShared",fn:function(ref){
var item = ref.item;
return [(item.IsShared == true)?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1):_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1)]}},{key:"item.OwnerName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.OwnerName || "-"))])]}},{key:"item.CreationDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$FormatDate(item.CreationDate)))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                    _vm.changePage(val);
                  }}})]}}],null,true)})],1)],1)],1)],1),_c('dialogDekaPrime')],1)}
var staticRenderFns = []

export { render, staticRenderFns }