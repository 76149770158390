var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-7"},[_c('v-card-text',[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Edit Default "+_vm._s(_vm.volume.type.name)+" Storage ")])]),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Storage Size")]),_c('v-text-field',{attrs:{"maxlength":"3","disabled":_vm.volume && _vm.volume.is_default,"type":"number","placeholder":"0","flat":"","outlined":"","error-messages":_vm.volume_sizeValidation.$errors.length
            ? _vm.volume_sizeValidation.$errors[0].$message
            : ''},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"font--text"},[_vm._v("GB")])]},proxy:true}]),model:{value:(_vm.volume_size),callback:function ($$v) {_vm.volume_size=_vm._n($$v)},expression:"volume_size"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Price per Month")]),_c('v-text-field',{class:{ hideinput: _vm.pricepermonthfocus == false },attrs:{"maxlength":"10","id":"pricepermonth","placeholder":"0","type":"number","flat":"","outlined":"","error-messages":_vm.price_per_monthValidation.$errors.length
            ? _vm.price_per_monthValidation.$errors[0].$message
            : ''},on:{"focus":function($event){_vm.pricepermonthfocus = true},"blur":function($event){_vm.pricepermonthfocus = false}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(_vm._s(_vm.pricepermonthfocus ? "" : _vm.toThousandsWithDotFormat(_vm.price_per_month)))])]},proxy:true}]),model:{value:(_vm.price_per_month),callback:function ($$v) {_vm.price_per_month=_vm._n($$v)},expression:"price_per_month"}})],1),_c('v-card-text',[_c('p',{staticClass:"titletext"},[_vm._v("Price per Hours")]),_c('v-text-field',{class:{ hideinput: _vm.priceperhourfocus == false },attrs:{"maxlength":"10","id":"priceperhours","placeholder":"0","type":"number","flat":"","outlined":""},on:{"focus":function($event){_vm.priceperhourfocus = true},"blur":function($event){_vm.priceperhourfocus = false}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',[_c('span',{staticClass:"font--text"},[_vm._v("IDR | ")]),_vm._v(_vm._s(_vm.priceperhourfocus ? "" : _vm.toThousandsWithDotFormat(_vm.price_per_hours)))])]},proxy:true}]),model:{value:(_vm.price_per_hours),callback:function ($$v) {_vm.price_per_hours=_vm._n($$v)},expression:"price_per_hours"}},[_vm._v(" :error-messages=\"price_per_hoursValidation.$errors.length?price_per_hoursValidation.$errors[0].$message:''\" ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent",attrs:{"block":"","height":"50","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Cancel")])])],1),_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","height":"50","depressed":""},on:{"click":function () {
                _vm.EditVol(_vm.volume).then(function () {
                  _vm.$emit('edited');
                  _vm.dialog = false;
                });
              }}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Save")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }