var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('br'),_c('br'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listNetwork,"item-key":"id","single-line":"","hide-default-footer":"","loading":_vm.isLoadingNetwork,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalNetwork},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/networking/" + (item.name) + "/" + (_vm.getId(item.id)) + "/networks")}},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'REALIZED')?_c('div',[_c('v-row',[_c('div',[_c('span',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle-outline")])],1)]),_c('div',[_c('span',[_vm._v(" Normal")])])])],1):_c('div',[_c('v-row',[_c('div',[_c('span',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close-circle")])],1)]),_c('div',[_c('span',[_vm._v(" Critical")])])])],1)]}},{key:"item.orgVdc",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cloud-outline")]),_vm._v(" "+_vm._s(item.orgVdc.name))],1)]}},{key:"item.subnets",fn:function(ref){
var item = ref.item;
return _vm._l((item.subnets.values),function(row,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(row.gateway)+"/"+_vm._s(row.prefixLength))])])})}},{key:"item.networkType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.networkType == "ISOLATED" ? "Isolated" : "Routed"))])]}},{key:"item.connection",fn:function(ref){
var item = ref.item;
return [(item.connection)?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pan")]),_vm._v(" "+_vm._s(item.connection.routerRef.name))],1):_c('span',[_vm._v("-")])]}},{key:"item.totalIpCount",fn:function(ref){
var item = ref.item;
return [(item.totalIpCount == 0)?_c('div',[_vm._v("-")]):_c('div',[_c('v-progress-linear',{attrs:{"value":_vm.getPersen(item),"height":"10"}}),_c('span',[_vm._v(_vm._s(_vm.getPersen(item))+"%")])],1)]}},{key:"item.shared",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.shared == true)?_c('span',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle-outline")])],1):_c('span',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close-circle")])],1)])]}},{key:"item.routeAdvertised",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.routeAdvertised == false ? "-" : "-"))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                  _vm.options = val;
                  _vm.changePage(val);
                }}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }