var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"fz-21 font-weight-bold"},[_vm._v("Balance History")])]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.histories,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.type === 'plus' ? 'color: #27AE60' : 'color: red')},[_vm._v(" "+_vm._s(item.type === "plus" ? "+" : "-")+" "+_vm._s(_vm.toIDRWithDotFormat(item.amount))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY"))+" ")])]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-left":70},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"primary--text"},'p',attrs,false),on),[_vm._v(" "+_vm._s(item.source)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.options = Object.assign({}, _vm.options, val);
          }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }