var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":true,"headers":_vm.tblHeaders,"items":_vm.list,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "No data available")+" ")]},proxy:true},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.due_date).format("DD/MM/YYYY"))+" ")])]}},{key:"item.service_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.uppercaseFirstLetter(item.service_type))+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(_vm.toIDRWithDotFormat(item.billed))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('router-link',{staticClass:"mr-4",attrs:{"to":'/billing/invoice/postpaid/' + item.id}},[_vm._v(" Detail ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function () {
              _vm.currentPeriod = _vm.$moment(item.due_date).format('DD-MM-YYYY');
              _vm.downloadInvoice(item.id);
            }}},[_vm._v(" Download ")])],1)]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [(
          (_vm.pagination && _vm.pagination.count > 10) ||
          (_vm.pagination && _vm.pagination.count === 10)
        )?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
            _vm.pagination = Object.assign({}, _vm.pagination, val);
          }}}):_vm._e()]}}])}),_c('vue-html2pdf',{ref:"historyBillingInvoicePdf2",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"filename":("Invoice - " + _vm.currentProjectName + " - " + _vm.currentPeriod),"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","html-to-pdf-options":{
      filename: ("Invoice - " + _vm.currentProjectName + " - " + _vm.currentPeriod),
      margin: [16, 0, 16, 0],
      html2canvas: { scale: 3 },
    }}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(_vm.detail)?_c('DownloadInvoice2',{attrs:{"item":_vm.detail,"list":_vm.detailList,"payments":_vm.payments,"totalPayment":_vm.totalPayment}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }