var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"alert-list-title"},[_vm._v("Alerting")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.alerts.length && !_vm.read_only)?_c('v-btn',{staticClass:"secondary",staticStyle:{"height":"55px","width":"150px"},attrs:{"to":"/alerting/create","depressed":""}},[_vm._v("Create")]):_vm._e()],1)],1),_c('br'),(_vm.project.region != 'TKP-Banten 1' )?_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-center w-50"},[_c('div',{staticClass:"alert-title mt-4"},[_vm._v(" For Your Information. ")]),_c('div',{staticClass:"alert-content mt-4"},[_vm._v(" Sorry, currently the alert feature has not available at TBS-Jakarta 1. ")]),_c('br')])])],1):(_vm.alerts.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.alerts,"items-per-page":10,"server-items-length":_vm.pagination.count,"options":_vm.options,"hide-default-footer":"","hide-default-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-data-table-header',_vm._g(_vm._b({attrs:{"sort-icon":"mdi-menu-down"}},'v-data-table-header',props,false),on))]}},{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('p',{staticClass:"text-center"},[_vm._v("Loading..")]):_c('p',{staticClass:"text-center"},[_vm._v("No data available")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text"},[_vm._v(_vm._s(item.name))])]}},{key:"item.metric",fn:function(ref){
var item = ref.item;
return [(item.metric == 'cpu')?_c('span',[_vm._v("CPU")]):(item.metric == 'memory.usage')?_c('span',[_vm._v("RAM")]):(item.metric == 'network.ingoing.bytes')?_c('span',[_vm._v("Network (In)")]):(item.metric == 'network.outgoing.bytes')?_c('span',[_vm._v("Network (Out)")]):_c('span',[_vm._v("-")])]}},{key:"item.comparison_operator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comparison_operator == "gt" ? "Is Above" : "Is Below")+" ")]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [(['cpu', 'memory.usage'].includes(item.metric))?_c('span',[_vm._v(_vm._s(item.threshold)+"%")]):(
                    [
                      'network.ingoing.bytes',
                      'network.outgoing.bytes' ].includes(item.metric)
                  )?_c('span',[_vm._v(_vm._s((item.threshold / 1000000).toString().replace(".", ","))+" MB")]):_c('span',[_vm._v(_vm._s(item.threshold))])]}},{key:"item.minutes",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.evaluation_period)+" ")]}},{key:"item.severity",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.severity))])]}},{key:"item.apply",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{attrs:{"to":("/project/" + (item.instance.project.name) + "/" + (item.instance.id) + "/agent")}},[_vm._v(_vm._s(item.instance.name))])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(!_vm.read_only)?_c('router-link',{attrs:{"to":{ path: '/alerting/edit', query: { id: item.id } }}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])]):_vm._e(),(!_vm.read_only)?_c('span',{staticClass:"pl-3 error--text pr-3",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                      _vm.validateprivilages(['Alerting', 'editor']).then(function () {
                        _vm.selectedAlert = item;
                        _vm.openDeleteModal = true;
                      });
                    }}},[_vm._v("Delete")]):_vm._e()]}},{key:"footer",fn:function(ref){
                    var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                      _vm.options = Object.assign({}, _vm.options, val);
                    }}})]}}])}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-center w-50"},[_c('div',{staticClass:"alert-title mt-4"},[_vm._v(" Looks like you don't have any alert ")]),_c('div',{staticClass:"alert-content mt-4"},[_vm._v(" Alert policies watch your Instance and alert you if there are any issues. ")])])]),(!_vm.read_only)?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/alerting/create"}},[_c('v-btn',{staticClass:"secondary",staticStyle:{"height":"55px","font-size":"14px"},attrs:{"block":"","depressed":""}},[_vm._v("Create Alert")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('deleteAlertModal',{on:{"confirm":_vm.doDelete,"close":function () { return (_vm.openDeleteModal = false); }},model:{value:(_vm.openDeleteModal),callback:function ($$v) {_vm.openDeleteModal=$$v},expression:"openDeleteModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }