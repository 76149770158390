var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[(_vm.isLoading)?_c('v-container',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]):_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title mb-7"},[_vm._v(" "+_vm._s(_vm.formatString(_vm.$route.params.artifacts))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title cap"},[_vm._v("Tags")])])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{staticClass:"fg"},[_c('v-btn',{attrs:{"outlined":"","color":"#1f60a8","width":"125","disabled":_vm.disable},on:{"click":function () {
                    _vm.closed = true;
                  }}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" ADD TAG")],1)],1)],1)],1),_c('v-card',{staticClass:"rounded-lg mt-4 mb-4",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableArtifacts,"item-key":"id","hide-default-footer":"","loading":_vm.loadingTags,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.totaltableArtifacts.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.signed",fn:function(ref){
                  var item = ref.item;
return [(item.signed == false)?_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")])],1):_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")])],1)]}},{key:"item.push_time",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.push_time)))])]}},{key:"item.pull_time",fn:function(ref){
                  var item = ref.item;
return [(item.pull_time!='0001-01-01T00:00:00.000Z')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.pull_time))+" ")]):_c('div')]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [(item.immutable)?_c('div',[_vm._v(" "+_vm._s(item.name)+" "),_c('v-chip',{staticStyle:{"float":"right"},attrs:{"x-small":"","color":"primary"}},[_vm._v("Immutable")])],1):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.pull",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('v-btn',{ref:"pull",staticClass:"bs-tr",attrs:{"title":("docker pull " + (_vm.infoSystem.external_url) + "/" + (_vm.$route.params.project_name) + "/" + (_vm.$route.params.project) + ":" + (item.name))},on:{"click":function($event){return _vm.copy(_vm.infoSystem.external_url,item.name)}}},[_c('v-icon',{staticClass:"text-center"},[_vm._v("mdi-content-copy")])],1)],1)]}},{key:"footer",fn:function(ref){
                  var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                _vm.changePage(val);
                  }}})]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(!item.immutable||!_vm.disable)?_c('div',{staticClass:"ml-4 error--text",staticStyle:{"cursor":"pointer"},on:{"click":function () {
                      _vm.onDelete(item);
                    }}},[_vm._v(" Delete ")]):_c('div')]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title mt-6"},[_vm._v("Overview")])])],1),_c('artifactsOverview',{attrs:{"data-overview":_vm.detailRepositories}}),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"firewall-title mt-6"},[_vm._v("Additions")])])],1),_c('artifactsAdditions')],1)],1)],1),_c('addTag',{attrs:{"close":_vm.closed},on:{"update:close":function($event){_vm.closed=$event}}}),_c('dialogConfirmDelete',{attrs:{"close":_vm.Close,"action":_vm.action,"data-delete":_vm.dataDelete},on:{"update:close":function($event){_vm.Close=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }