var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-4",attrs:{"fluid":""}},[_c('v-row'),_c('br'),(_vm.listSG.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.listSG,"items-per-page":10,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.security_group_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/harbor/detail/" + (_vm.$route.params.harborid) + "/securitygroup/" + (item.security_group_id) + "/detail")}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.security_group_name))])])]}},{key:"item.rules",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalRulesSG)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                      path: ("/harbor/detail/" + (_vm.$route.params.harborid) + "/securitygroup/" + (item.security_group_id) + "/edit")
                    }}},[_c('span',{staticClass:"primary--text edit"},[_vm._v("Edit")])])]}}],null,false,4104042806)}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Security Group ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Manage access to the ports of your Harbor with security groups ")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"secondary",attrs:{"width":"100%","append":"","max-width":"300","height":"50","depressed":"","exact":"","to":("/harbor/detail/" + (_vm.$route.params.harborid) + "/securitygroup/create")}},[_vm._v(" Create Security Group ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }