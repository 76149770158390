var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg pa-4 mt-3",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v("History Debit")]),_c('v-spacer'),_c('div',{staticStyle:{"height":"auto","width":"20px"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.tblHeaders,"items":_vm.tblItems,"items-per-page":10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.date).format("DD/MM/YYYY"))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.amount)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.toIDRWithDotFormat(item.total)))])]}},{key:"item.running_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.running_date).format("DD/MM/YYYY"))+" ")]}},{key:"item.running_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.uppercaseFirstLetter(item.running_by))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sliceInv(item.description))+" ")]}},(_vm.tblItems.length)?{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }