import { render, staticRenderFns } from "./firewall.vue?vue&type=template&id=32a257a6&scoped=true"
import script from "./firewall.vue?vue&type=script&lang=ts"
export * from "./firewall.vue?vue&type=script&lang=ts"
import style0 from "./firewall.vue?vue&type=style&index=0&id=32a257a6&prod&lang=scss&scoped=true"
import style1 from "./firewall.vue?vue&type=style&index=1&id=32a257a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a257a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VContainer,VRow,VSelect,VSimpleTable,VSpacer,VTextField,VTextarea})
