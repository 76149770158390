var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"title"},[_vm._v("Inbound Rule")]),_c('v-spacer'),(!_vm.read_only)?_c('router-link',{attrs:{"to":{
          path: ("/harbor/detail/" + (_vm.$route.params.harborid) + "/securitygroup/" + (_vm.$route.params.sg_id) + "/edit"),
        }}},[_c('v-btn',{staticClass:"success",staticStyle:{"height":"45px","width":"150px"},attrs:{"depressed":""}},[_vm._v(" Edit Rule ")])],1):_vm._e()],1)],1),_c('div',{staticStyle:{"width":"auto","height":"28px"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.rules.header,"items":_vm.inboundRules,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.range",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"display":"inline-block","width":"75px","margin-right":"1rem"}},[_c('v-badge',{attrs:{"content":("Min: " + (item.port_range_min ? item.port_range_min : 'Any')),"color":"blue"}})],1),_c('div',{staticStyle:{"display":"inline-block","width":"75px","margin-right":"1rem"}},[_c('v-badge',{attrs:{"content":("Max: " + (item.port_range_max ? item.port_range_max : 'Any')),"color":"red"}})],1)]}},{key:"item.protocol",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.protocol ? item.protocol : 'Any')+" ")])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(item.type == 'tcp')?_c('span',[_vm._v("Custom TCP Rule")]):(item.type == 'udp')?_c('span',[_vm._v("Custom UDP Rule")]):(item.type == 'icmp')?_c('span',[_vm._v("All ICMP Rule")]):_c('span',[_vm._v(_vm._s(item.type ? item.type : 'Any'))])]}},{key:"item.remote_ip_prefix",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sources ? item.sources : 'Any')+" ")])]}},{key:"footer",fn:function(ref){
        var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}])}),_c('div',{staticStyle:{"width":"auto","height":"28px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }