var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" Custom Image ")])]),_c('v-col',{staticClass:"flex-row text-right"},[_c('v-btn',{staticClass:"secondary--text",staticStyle:{"border":"3px solid","background-color":"white"},attrs:{"depressed":"","width":"150","height":"50"},on:{"click":function () {
                           _vm.dialogImportImageURL = true;
                         }}},[_vm._v("Import via URL")]),_c('div',{staticStyle:{"height":"auto","display":"inline-block","width":"30px"}}),_c('v-btn',{attrs:{"depressed":"","width":"150","height":"50","color":"secondary"},on:{"click":function () {
                             _vm.dialoguploadimage = true;
                           }}},[_vm._v("Upload Image")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","headers":_vm.headercustomimage,"items":_vm.customimage,"items-per-page":10},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                           var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dotblue mt-2"}),_c('div',[_c('router-link',{attrs:{"to":'' + item.id,"append":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.image.text))])]),_c('p',[_vm._v(_vm._s(item.image.spec))])],1)])]}},{key:"item.size",fn:function(ref){
                           var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.size.vol))])]}},{key:"item.action",fn:function(ref){return [_c('label',{staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"primary--text"},[_vm._v("Edit")])]),_c('span',{staticStyle:{"height":"auto","width":"20px","display":"inline-block"}}),_c('label',{staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"error--text"},[_vm._v("Delete")])])]}},(_vm.customimage.length > 5)?{key:"footer",fn:function(ref){
                           var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props}})]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }