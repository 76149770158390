var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7",attrs:{"fluid":""}},[_c('br'),(_vm.security_groups.length)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.table.headers,"items":_vm.security_groups,"items-per-page":10,"server-items-length":_vm.paginate.itemsLength,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.total_instances",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.total_instances > 1 ? item.total_instances > 1 ? ((item.total_instances) + " Instances") : ((item.total_instances) + " Instance") : "-")+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + item.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                        on.click($event);
                      }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                        rotate: item.open,
                      },attrs:{"id":'popup' + item.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-list',[_c('v-list-item',{on:{"click":function () {_vm.selectSecurityGroup(item)}}},[_c('v-list-item-title',[_vm._v("Detail")])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                      var props = ref.props;
return [(_vm.security_groups.length)?_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                    _vm.options = val;
                    _vm.changePage(val);
                  }}}):_vm._e()]}}],null,false,2546831920)}):_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":"","outlined":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"d-flex rounded-lg flex-grow-1 flex-column text-center ma-auto",attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"padding-top":"72px","padding-bottom":"78px"}},[_c('p',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px","margin-bottom":"10px","text-transform":"capitalize"}},[_vm._v(" Looks like you don’t have any Security Group ")]),_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"12px","color":"#a5b3bf","margin-bottom":"20px"}},[_vm._v(" Manage access to the ports of your Instance with security groups ")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }